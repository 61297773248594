import {F4FormElementDecorator} from '@aktek/f4form';
import {TreeView} from '@aktek/f4kit';
import {TreeViewProps} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/TreeView/Types/TreeViewProps';

import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';

type TTreeViewSelector = TreeViewProps & {
    parentId?: string
    onChange?: (value) => void,
}

export const TreeViewSelector = (props: TTreeViewSelector) => {
    const {data, parentId, onChange, scrollTimeOut = 1500, areAllNodesExpanded, ...rest} = props;
    const hasData = data && data.length > 0;

    const defaultTreeValue = parentId ? {[parentId]: true} : {[FOLDERS_AND_DATA]: true};
    const defaultTreeValueId = GetFirstKey(defaultTreeValue);
    const isFoldersAndDataSelected = defaultTreeValueId === FOLDERS_AND_DATA;

    return <>
        {hasData && <TreeView
            data={data}
            defaultValue={defaultTreeValue}
            nodeMoveTargetFilter={(e) => e.type == 'folder'}
            onChange={onChange}
            scrollTimeOut={scrollTimeOut}
            scrollToDefaultValue={!isFoldersAndDataSelected}
            areAllNodesExpanded={areAllNodesExpanded ? areAllNodesExpanded : !isFoldersAndDataSelected}
            checkboxFilter={(node) => node.type == 'folder'}
            checkMode="single"
            labelProps={
                {
                    className: 'block w-full break-words break-all hyphens-auto leading-tight cursor-pointer',
                }
            }
            {...rest}
        />}
    </>;
};

const F4TreeViewSelector = F4FormElementDecorator<TTreeViewSelector>(TreeViewSelector);

export default F4TreeViewSelector;

