import {ToggleSwitch} from '@aktek/f4kit';
import {useEffect, useState} from 'react';

import IOSelect from '@/iohub/IOSelect';

const types = ['user', 'group', 'tags', 'sbo', 'field', 'ref'];

export default function PlaygroundIOSelect() {
    const states = {};

    const [isMulti, setIsMulti] = useState(false);

    useEffect(()=>{
        clear();
    }, [isMulti]);

    types.forEach((type) => {
        states[type] = useState(null);
    });

    const clear = () => {
        types.forEach((type) => {
            states[type][1](null);
        });
    };

    return <div>
        <ToggleSwitch label="Multi" value={isMulti} onChange={setIsMulti}/>

        <div>User</div>
        <IOSelect type="user" value={states.user[0]}
            isMulti={isMulti}
            onChange={states.user[1]}/>

        <div>Group</div>
        <IOSelect type="group" value={states.group[0]}
            isMulti={isMulti}
            onChange={states.group[1]} />
        <div>Tags</div>

        <IOSelect type="tags" value={states.tags[0]}
            isMulti={isMulti}
            onChange={states.tags[1]}/>
        <div>SBO</div>

        <IOSelect type="sbo" value={states.sbo[0]}
            isMulti={isMulti}
            onChange={states.sbo[1]}/>
        <div>Field</div>

        <IOSelect type="field" sboId="633afeebdc122e944fb20abd"
            isMulti={isMulti}
            value={states.field[0]}
            onChange={states.field[1]}/>
        <div>Ref</div>

        <IOSelect type="ref" sboId="633afeebdc122e944fb20abd"
            displayFieldId="rGKna"
            isMulti={isMulti}
            value={states.ref[0]}
            onChange={states.ref[1]}/>

    </div>;
}
