import {Modal, TModalProps, useModalRef} from '@aktek/f4kit';
import {XSmartFilter} from '@aktek/types';
import cn from 'classnames';
import {forwardRef, useImperativeHandle} from 'react';

import SmartFilter from './Builder/SmartFilter.Builder';
import useSmartFilter from './hooks/useSmartFilter';
import SmartFilterPreview from './Preview/SmartFilter.Preview';
import SmartFilterActions from './SmartFilter.Actions';

const DEFAULT_FILTER = {
    type: 'group',
    operator: '$and',
    rules: {},
};
export type TPasswordLinkModalProps = {
    sboId: string;
    modalProps?: Partial<TModalProps>;
    className?: string;
    URL?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    openOnMount?: boolean;
    onChange?: (filter: XSmartFilter) => void
    filter?: XSmartFilter
}

export type TSmartFilterModalRef = {
    open: () => void;
};

function SmartFilterModal(props: TPasswordLinkModalProps, ref) {
    const {className, openOnMount, onConfirm, onCancel, modalProps={size: 'lg'}} = props;
    const modalRef = useModalRef();

    useImperativeHandle(ref, () => ({
        open: () => modalRef.current?.open(),
    }));

    const {
        smartFilter,
        setSmartFilter,
        handleDelete,
        handleCancel,
        handleSave,
        handleNewFilter,
    } = useSmartFilter(props.filter || DEFAULT_FILTER, props.onChange, props.openOnMount, modalRef);

    return <Modal
        defaultIsOpened={openOnMount}
        ref={modalRef}
        hasXIcon={true}
        headerClassName="pb-2"
        className={cn('overflow-hidden !rounded-3xl p-1', className)}
        title={'Smart Filter'}
        {...modalProps}
        onClose={onCancel}
    >
        <div className="flex flex-col">
            <SmartFilterPreview sboId={props.sboId} smartFilter={smartFilter}/>

            <SmartFilter
                value={smartFilter}
                sboId={props.sboId}

                onChange={(e)=>{
                    setSmartFilter(e);
                }}

            />

            <SmartFilterActions
                onDelete={handleDelete}
                onCancel={handleCancel}
                onSave={handleSave}
                onNewFilter={handleNewFilter}
            />
        </div>
    </Modal>;
}

export default forwardRef<TSmartFilterModalRef, TPasswordLinkModalProps>(SmartFilterModal);
