
import F4TableSpreadsheet from '@/components/F4TableSpreadsheet/components_temp/F4TableSpreadsheet';
import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import SmartFilterModal from '@/components/SmartFilter/SmartFilter.Modal';
import {strings} from '@/localization/i18n';

import useF4CurrentTab from '../Home/hooks/useF4CurrentTab';
import {DATA_TABLE_EMPTY_PROPS} from './constants/DataTable.EmptyProps.c';
import {MODES, useDataTable} from './hooks/useDataTable';

type TDataTableViewTabState = {
    sboId: string;
};

const DataTableView = () => {
    const currentTab = useF4CurrentTab<TDataTableViewTabState>();
    const {sboId} = currentTab.getTabState();

    const createTabConfig = {
        tabKey: 'recordEditor',
        title: `${strings('add_to')} ${currentTab.getConfig()?.title}`,
        props: {sboId},

    };

    const {
        getRows,
        getColumns,
        getRowsCount,
        filter,
        // setFilter,
        onRibbonAction,
        ribbonActions,
        smartFilterModalRef,
        mode,
        f4TableSpreadSheetRef,
        tableRef,
    } = useDataTable(sboId);

    return (
        <div className="bg-neutral-50 h-full p-4">
            <F4ViewableComponent
                tableRef={tableRef}
                onRibbonAction={onRibbonAction}
                defaultSortedColumn="designation"
                name={strings('data_table')}
                getColumns={getColumns}
                createBehavior="tab"
                createTab={createTabConfig}
                emptyProps={DATA_TABLE_EMPTY_PROPS}
                getRowsCount={getRowsCount}
                hasRefreshButton={false}
                customRibbonActions={ribbonActions}
                getAllData={getRows}
                customAddButtonTooltip={strings('record')}
            >
                {mode != MODES.TableView && (
                    <F4TableSpreadsheet
                        ref={f4TableSpreadSheetRef}
                        key={sboId +'-'+ mode}
                        sboId={sboId}
                        mode={mode}
                        filter={filter}
                    />
                )}
            </F4ViewableComponent>
            <SmartFilterModal
                ref={smartFilterModalRef}
                sboId={sboId}
                filter={filter}
                // onChange={setFilter}
            />
        </div>
    );
};

export default DataTableView;
