// import DateTimePickerPopup from '@/components/DatePickerPopup/DateTimePickerPopup';
import {DateTimePickerPopup} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const DateTimeCellEdit = ({value, onChange}) => {
    console.log('....Date.Cell.Edit.tsx -> Line 7', {value});

    return <div className="flex items-center gap-1">

        <DateTimePickerPopup defaultOpen
            mode="date"
            onChange={(date) => {
                console.log('....Datetime.Cell.Edit.tsx__12__: ', date, '\n_________');
                onChange(date);
            }}
            dateProps={{
                placeholder: strings('select_date'),
                value: value,
                minDate: 0,
                maxDate: 0,
                dateFormat: 'DD/MM/YYYY',
            }}
        />

    </div>;
};

export default DateTimeCellEdit;
