import {XSmartFilter} from '@aktek/types';
import {useCallback, useEffect, useState} from 'react';

function useSmartFilter(initialFilter, onChange, openOnMount, modalRef) {
    const [smartFilter, setSmartFilter] = useState<XSmartFilter>(initialFilter);

    useEffect(() => {
        if (openOnMount) {
            modalRef.current?.open();
        }
    }, [openOnMount, modalRef]);

    const handleDelete = useCallback(() => {
    // Implement delete logic here
    }, []);

    const handleCancel = useCallback(() => {
        setSmartFilter(initialFilter);
        modalRef.current?.close();
    }, [modalRef]);

    const handleSave = useCallback(async () => {
        // @TODO: save filter here
        onChange(smartFilter);
        modalRef.current?.close();
    }, [smartFilter, onChange, modalRef]);

    const handleNewFilter = useCallback(async () => {
        // @TODO: create new filter here
    }, []);

    return {
        smartFilter,
        setSmartFilter,
        handleDelete,
        handleCancel,
        handleSave,
        handleNewFilter,
    };
}

export default useSmartFilter;
