const generateGetDeniedColumnProperty = (editableFieldsIDsSet, fieldsMap, field) => {
    const getDenied = (row, column, isNewRow) => {
        if (isNewRow) {
            return {isDenied: !fieldsMap?.[field.id]};
        }

        return {isDenied: !editableFieldsIDsSet.has(field.id)};
    };

    return getDenied;
};

export default generateGetDeniedColumnProperty;
