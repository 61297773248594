
import {F4FormElementDecorator} from '@aktek/f4form';
import type {TF4FormElementProps} from 'node_modules/@aktek/f4form/dist/Core/types/F4FormElementProps.t';

import IOSelect, {TIOSelectProps} from '@/iohub/IOSelect';
import {TIOHubProps} from '@/iohub/types/IOHub.t';

type TF4IOSelectProps = TF4FormElementProps<TIOHubProps> & TIOSelectProps

const F4IOSelect = F4FormElementDecorator<TF4IOSelectProps>(IOSelect);

export default F4IOSelect;
