import cn from 'classnames';

const dropdownClassname = cn(
    '!bg-neutral-50',
    'ring-0',
    'ring-neutral-50',
    'group-hover:!bg-neutral-100',
    'focus:!bg-neutral-50',
    'focus:!border-1',
    'focus:!border-neutral-200',
    'focus:!shadow-[rgba(16,24,40,0.05)_0px_0px_6px_4px]',
    'focus:!shadow-neutral-200',
);

export const GetTagsClasses = () => ({
    dropdownClassname,
});
