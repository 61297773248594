import {Cell, Row} from '@tanstack/react-table';

import RefCellView from '@/components/Fields/Ref/Ref.Cell.View';

export const CellSpecialString = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = row.getValue(cell.column.id);
            const id = row.original[cell.column.id + '_id'];
            const sboId = columnDef?.sboId;

            if (columnDef?.sboId) {// it means it is a ref
                return <RefCellView
                    value={id}
                    sboId={columnDef.sboId}
                    label={value + ''}

                />;
            }

            if (columnDef?.dynamicField == 'Group') {// it means it is a ref
                // console.log('....Cell.SpecialString.tsx -> Line 21', columnDef, value);

                return null;
            }

            if (columnDef?.dynamicField == 'Group') {// it means it is a ref
                // console.log('....Cell.SpecialString.tsx -> Line 28', columnDef, value);

                return null;
            }

            return <div>--{JSON.stringify({value, _value: id})}--</div>;
        },
    };
};
