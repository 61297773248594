import {FitHeight, LoadingIndicator, TabsFlexLayout, TabsManager, toast} from '@aktek/f4kit';
import {TTabData} from 'node_modules/@aktek/f4kit/dist/components/TierOne/TabsFlexLayout/Types/Types';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import AskForConfirmation from '@/asks/AskForConfirmation';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {GetItemById} from '@/helpers/GetItemById.fn';
import {strings} from '@/localization/i18n';
import {useNavigateToRoot} from '@/routing/navigations';
import {TIOTabConfig} from '@/types/CommonTypes';

import AppHeader from './AppHeader';
import {TabFactory} from './constants/TabsFactory.fn';
import {DefaultSchema} from './constants/TabsLayout.DefaultSchema.c';
import useCompanySwitch from './hooks/useCompanySwitch';

export default function Home() {
    const {userCompanies, currentCompany} = useUserAuth();
    const {fetchTheme} = useGlobalTheme();
    const {setLinkToFolder} = useGlobalFilesAndFolders();

    // Navigation
    const navigateToRoot = useNavigateToRoot();

    // params
    const {shortcut, folder, companyId} = useParams();

    // company switch hook
    const {loading, handleSwitchCompany} = useCompanySwitch(folder);

    // close tab
    useEffect(()=>{
        TabsManager.registerActionInterceptor('closeTab', async (node) => {
            const config = node.getConfig() as TTabData<TIOTabConfig>;
            const {hasUnsavedChanges, title} = config;

            if (hasUnsavedChanges) {
                if (await AskForConfirmation(strings('unsaved_changes_on_tab', title),
                    strings('unsaved_changes_on_tab_description', title),
                    {
                        confirmButtonProps: {variant: 'error', label: strings('discard')},
                        cancelButtonProps: {label: strings('stay_here')},
                    })) {
                    return false;
                }

                return true;
            }

            return false;
        });
    }, []);

    useEffect(() => {
        if (companyId && companyId !== currentCompany._id) {
            const foundCompany = GetItemById(userCompanies, companyId);

            if (!foundCompany) {
                navigateToRoot(true);
                toast.error(strings('missing_required_permissions'));

                return;
            }

            handleSwitchCompany(companyId, folder);

            return;
        }

        if (shortcut) TabsManager.openTabFromShortcut(shortcut);

        if (folder) setLinkToFolder(folder);

        if (folder || shortcut) navigateToRoot(true);
    }, [shortcut, folder]);

    useEffect(() => {
        if (!TabsManager.getFirstMatchingTab('home')) {
            TabsManager.focusOrOpenNewTab('home', strings('home'), {}, {isPinned: true});
        }

        fetchTheme();
    }, []);

    if (loading) {
        return <div className=" absolute flex items-center w-full h-full p-4 justify-center elevation-5 !shadow-none"
            style={{backgroundColor: 'rgba(0, 0, 0, 0.15)'}}>
            <LoadingIndicator size="xl" />
        </div>;
    }

    return (
        <FitHeight className="w-full h-full">
            <div className="px-2s">
                <AppHeader />
            </div>
            <div className="relative overflow-hidden h-full">
                <TabsFlexLayout
                    tabFactory={TabFactory}
                    persistSchema={true}
                    localStorageKey="ioTabs"
                    defaultSchema={DefaultSchema}
                />
            </div>
        </FitHeight>
    );
}

