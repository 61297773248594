import {Icon} from '@aktek/f4kit';
import {faGripDotsVertical} from '@fortawesome/pro-regular-svg-icons';

import AskForMenu from '@/asks/AskForMenu';

import GetFieldContextMenuEntries from '../helpers/GetFieldContextMenuEntries.fn';
import {TSBOEditorFieldProps} from '../types/SBOEditor.t';
import SBORenderField from './Fields/SBO.RenderField';

export default function SBOEditorField(props:TSBOEditorFieldProps) {
    const {field, sectionId, sboActions} = props;

    return (
        <div className="group/field" onContextMenu={(event)=>{
            AskForMenu(event, GetFieldContextMenuEntries(sectionId, field, sboActions));
        }}>
            <div className="flex w-full">
                <Icon icon={faGripDotsVertical} color="neutral-600"
                    className="invisible group-hover/field:!visible w-4 h-4 mt-6 cursor-pointer"
                    onClick={(event)=>{
                        AskForMenu(event, GetFieldContextMenuEntries(sectionId, field, sboActions));
                    }}
                />
                <SBORenderField {...props}/>
            </div>
        </div> );
}
