import {Label} from '@aktek/f4kit';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';

import {TSBOEditorFieldProps} from '../../types/SBOEditor.t';
import SBOFieldCommon from './SBO.Field.Common';

const NUMBER_TYPES = {
    regular: {label: 'Regular', value: 'regular'},
    currency: {label: 'Currency', value: 'currency'},
    percentage: {label: 'Percentage', value: 'percentage'},
};

const CURRENCY_TYPES = {
    aud: {label: 'AUD', value: 'AUD$'},
    euro: {label: 'EURO', value: '€'},
    cad: {label: 'CAD', value: 'CAD$'},
    cop: {label: 'COP', value: 'COP$'},
    gbp: {label: 'GBP', value: '£'},
    usd: {label: 'USD', value: '$'},
    yen: {label: 'YEN', value: '¥'},
    chf: {label: 'CHF', value: '₣'},
    hnl: {label: 'HNL', value: 'L'},
    brl: {label: 'BRL', value: 'R$'},
};

export default function SBOFieldIntegerDecimal(props: TSBOEditorFieldProps) {
    const {field} = props;
    // const {field, tableFields, sboActions, children} = props;
    // const {toggleFieldTableVisibility} = sboActions;

    return (
        <SBOFieldCommon {...props}>
            <div className="flex flex-col w-full bg-neutral-100 rounded-xl items-center gap-1" >

                {/* ********** Minimum ********** */}
                <div className="flex flex-col items-center justify-between gap-2 w-full">
                    <div className="flex items-center gap-2 w-full group/description ">
                        <Label text="Minimum" className="!w-60" color="neutral-400"/>
                        <F4Textbox isGhost type="number" allowDecimal
                            inputClassName="!text-base font-medium" name={`fieldsMap.${field.id}.minimum`}
                            className="text-neutral-600 group-hover/description:!bg-neutral-50 rounded-lg"/>
                    </div>

                    {/* ********** Maximum ********** */}
                    <div className="flex items-center gap-2 w-full group/description ">
                        <Label text="Maximum" className="!w-60" color="neutral-400"/>
                        <F4Textbox isGhost type="number" allowDecimal
                            inputClassName="!text-base font-medium" name={`fieldsMap.${field.id}.maximum`}
                            className="text-neutral-600 group-hover/description:!bg-neutral-50 rounded-lg"/>
                    </div>

                    {/* ********** Number Type ********** */}
                    <div className="flex items-center gap-2 w-full group/numberType ">
                        <Label text="Type" className="!w-60" color="neutral-400"/>
                        <F4Dropdown
                            colSpan={12}
                            name={`fieldsMap.${field.id}.numberType`}
                            options={NUMBER_TYPES}
                            defaultValue={NUMBER_TYPES.regular.value}
                            required
                            className="mt-1"
                            hideArrow
                            placeholder=""
                            dropdownClassname="border-0 !bg-neutral-100 ring-0 ring-neutral-50 group-hover/numberType:!bg-neutral-50 "
                        />
                    </div>

                    {/* ********** Currency Type ********** */}
                    {field.numberType === 'currency'
                    && <div className="flex items-center gap-2 w-full group/currencyType ">
                        <Label text="Currency" className="!w-60" color="neutral-400"/>
                        <F4Dropdown
                            // show={field.numberType === 'currency'}
                            colSpan={12}
                            name={`fieldsMap.${field.id}.currencyType`}
                            options={CURRENCY_TYPES}
                            required
                            className="mt-1"
                            hideArrow
                            placeholder=""
                            dropdownClassname="border-0 !bg-neutral-100 ring-0 ring-neutral-50 group-hover/currencyType:!bg-neutral-50 "
                        />
                    </div>}
                    <F4ToggleSwitch
                        name={`fieldsMap.${field.id}.showThousandsSeparator`}
                        description="Display thousands separator"
                        defaultValue={true}
                        size="sm"
                    />
                </div>
            </div>
        </SBOFieldCommon>
    );
}

