import {faCopy, faMapLocationDot} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

export const getGpsMenuOptions = (onCopy: () => void, onView: () => void) => [
    {
        key: 'copy',
        label: strings('copy_coordinates'),
        icon: faCopy,
        onClick: onCopy,
    },
    {
        key: 'view',
        label: strings('view_in_maps'),
        icon: faMapLocationDot,
        onClick: onView,
    },
];
