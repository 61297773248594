import IOSelect from '@/iohub/IOSelect';

const ConnectedRefCellEdit = ({value, onChange, sboId, fieldId, affecter}) => {
    const isMulti = affecter?.type == 'refList';
    let _value = value;

    if (isMulti) {
        _value = value?.map?.((refValue) => refValue?._id) || [];
    }

    return <IOSelect autoOpenOnMount
        isMulti={isMulti}
        value={_value}
        sboId={sboId}
        displayFieldId={fieldId}
        type="ref"
        onChange={(value) => {
            if (isMulti) {
                const updatedValues = value?.map?.((_id) => ({_id}));
                onChange(updatedValues);

                return;
            }

            onChange(value);
        }}
    />;
};

export default ConnectedRefCellEdit;
