import cn from 'classnames';

const footerContainer = cn(
    'flex flex-wrap justify-end items-center',
    'w-full min-w-52 gap-1',
    'sticky -bottom-0.5 mt-2 p-4',
    'bg-neutral-50 elevation-3 !shadow-none',
);

const headerContainer = cn(
    'flex flex-row justify-between items-center',
    'border-b border-neutral-200 pb-6 sticky',
    '!shadow-none elevation-5 -top-0',
    'pt-3 bg-neutral-50',
);

const field = (layout) => cn('gap-4 w-full',
    layout && 'lg:grid flex flex-col lg:grid-cols-2 ',
    !layout && 'flex flex-col');

export const GetSingleRecordClasses = (layout?:boolean) => ({
    footerContainer,
    headerContainer,
    field: field(layout),
});
