
import {strings} from '@/localization/i18n';

const ValidateIntegerNumberValue = (value: number, maximum?: number, minimum?: number) => {
    if (maximum && Number(value) > Number(maximum)) {
        return {isValid: false, messages: [`${strings('operator_max')} ${maximum}`]};
    }

    if (minimum && Number(value) < Number(minimum)) {
        return {isValid: false, messages: [`${strings('operator_min')} ${minimum}`]};
    }

    return {isValid: true};
};

export default ValidateIntegerNumberValue;
