import React from 'react';

// Constants
export const MAX_STACK_SIZE = 15;
export const STORAGE_KEY = 'recentlyUsedSBO';

// Function to add a tab object to the recently used boundedArray
export const AddToRecentlyUsedSBOs = (sbo) => {
    let boundedArray = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];

    // Check if the tab object already exists in the stack
    boundedArray = boundedArray.filter((item) => item.key !== sbo.key);

    // Add the new tab object to the beginning of the boundedArray with a timestamp
    const tabWithTimestamp = {...sbo, timestamp: Date.now()};
    boundedArray.unshift(tabWithTimestamp);

    // Remove the oldest entry if the boundedArray exceeds the maximum size
    if (boundedArray.length > MAX_STACK_SIZE) {
        boundedArray.pop();
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(boundedArray));
};

// Function to get and sort the recently used tabs
export const GetRecentlyUsedSBOs = () => {
    const storageValue = localStorage.getItem(STORAGE_KEY);

    return React.useMemo(() => {
        const boundedArray = JSON.parse(storageValue) || [];

        boundedArray.sort((a, b) => b.timestamp - a.timestamp);

        return boundedArray;
    }, [storageValue]);
};

