import {F4FormContainer, F4FormElementDecorator, F4FormStep, useF4FormContext} from '@aktek/f4form';
import {TF4DecoratedElementProps} from 'node_modules/@aktek/f4form/dist/Core/types/F4DecoratedElementProps.t';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {TMode} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import {strings} from '@/localization/i18n';

import OnLicenseSelect from '../helpers/Agency.OnLicenseSelect.fn';
import useAgency from '../hooks/useAgency';
import AgencyCustomizations from './Agency.Customizations';

type TLicenseInfo = TF4DecoratedElementProps & {
    mode: TMode;
}

export const LicenseInfo = ({mode}: TLicenseInfo) => {
    const {getLicenses, dropdownOptions} = useAgency();

    const isViewMode = mode == 'view';
    const {data: companyInfo, setValue} = useF4FormContext();

    const [hasCustomizations, setHasCustomizations] = useState(false);

    useEffect(() => {
        // NOTE: fix this after we implement the dropdown (that works with API calls)
        getLicenses(null, {id: 'plan', order: 1}, 0, 100);
    }, []);

    useEffect(() => {
        if (!!companyInfo?.['license']?.licensePlan) {
            return setHasCustomizations(true);
        }

        setHasCustomizations(false);
    }, [companyInfo, hasCustomizations]);

    const onLicenseSelect = useCallback(
        (license) => {
            OnLicenseSelect(
                license,
                setHasCustomizations,
                setValue,
                companyInfo,
            );
        },
        [setHasCustomizations, companyInfo],
    );

    const hasAmpWlc = useMemo(() => {
        const hasAmpWlcLimit = companyInfo?.['limits']?.has_amp_wlc;
        const hasAmpWlcCustomization = companyInfo?.['license']?.customizations?.limits?.has_amp_wlc;

        return hasAmpWlcLimit || hasAmpWlcCustomization;
    }, [companyInfo]);

    return (

        <F4FormStep step="license" autoNest>

            <div className="overflow-y-auto flex flex-col gap-4 pb-4">

                <F4FormContainer rowSpan={12} className="w-full flex flex-col">

                    <F4Dropdown
                        options={dropdownOptions || {}}
                        placeholder={isViewMode ? strings('not_specified') : undefined}
                        isDisabled={isViewMode}
                        hideArrow={isViewMode}
                        isClearable={!isViewMode}
                        show={dropdownOptions}
                        label={strings('license_plan')}
                        onChange={onLicenseSelect}
                        name="licensePlan" />

                    <AgencyCustomizations
                        hasAmpWlc={hasAmpWlc}
                        hasCustomizations={hasCustomizations}
                        isViewMode={isViewMode} />

                </F4FormContainer>

            </div>
        </F4FormStep>
    );
};

const F4LicenseInfo = F4FormElementDecorator<TLicenseInfo>(LicenseInfo);
export default F4LicenseInfo;
