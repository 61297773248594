import {ContentDivider, Icon, Label} from '@aktek/f4kit';
import {faChevronDown, faChevronUp, faExclamationCircle} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';

// import F4ImageColorSelector from '@/components/F4Elements/F4ExplorerNodeDesign';
import F4IOSelect from '@/components/F4Elements/F4IOSelect';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {strings} from '@/localization/i18n';

type TSBOEditorMainProps ={
    sboId:string
}
const inputClassName='!bg-neutral-50  group-hover:!bg-neutral-100 focus:!bg-neutral-50 focus:!border-1 focus:!border-neutral-200 focus:!shadow-[rgba(16,24,40,0.05)_0px_0px_6px_4px] focus:!shadow-neutral-200 font-semiBold !text-neutral-900 placeholder-neutral-300 font-medium';

// Find the closest scrollable parent
const findScrollableParent = (element: HTMLElement | null): HTMLElement | null => {
    if (!element) return null;

    const style = window.getComputedStyle(element);
    const overflowY = style.getPropertyValue('overflow-y');

    if (overflowY === 'auto' || overflowY === 'scroll') {
        return element;
    }

    return findScrollableParent(element.parentElement);
};

export default function SBOEditorMain(props: TSBOEditorMainProps) {
    const {sboId} = props;
    const mainContainerRef = useRef<HTMLDivElement>(null);
    const [isMainSectionExpanded, setIsMainSectionExpanded] = useState(true);

    useEffect(() => {
        const scrollableParent = findScrollableParent(mainContainerRef.current);

        if (scrollableParent) {
            const isScrolledDown = scrollableParent.scrollTop > 50;

            if (isScrolledDown) {
                mainContainerRef.current?.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [sboId]);

    return (
        <div className="select-none" ref={mainContainerRef}>
            {/* <div className="flex gap-6 items-center"> */}
            {/* <div>
                    <F4ImageColorSelector name="assets" defaultIcon={faDatabase}
                     background="primary-500" className=" flex Align-middle items-center p-4 rounded-lg bg-white" />
                </div> */}

            <F4Textbox name="name" autoFocus required autoComplete="off" isGhost inputClassName="!bg-neutral-50 !pl-0 rounded-none h-full !text-neutral-900 placeholder-neutral-300 font-bold !text-4xl" placeholder="Untitled" />
            {/* </div> */}

            {/* ContentDivider */}
            <div >
                <ContentDivider
                    backgroundColor="neutral-50"
                    rightChild={
                        <div className="flex items-center !w-24 !cursor-pointer select-none" onClick={()=> setIsMainSectionExpanded(!isMainSectionExpanded)}>
                            <Icon icon={isMainSectionExpanded ? faChevronUp : faChevronDown} className="mr-2 font-semibold" color="neutral-600"/>
                            <Label text={isMainSectionExpanded ? strings('collapse'): strings('expand')} className="text-neutral-600 text-sm font-semibold !cursor-pointer" />
                        </div>}
                />
            </div>

            {isMainSectionExpanded
                && <div className="flex flex-col gap-4 pb-3">
                    {/* Description */}
                    <div className="flex items-center gap-4 group">
                        <Label className="w-60" text={strings('description')} color="neutral-400" fontWeight="medium" size="md"/>
                        <F4Textbox autoComplete="off" name="description" isGhost inputClassName={inputClassName}/>
                    </div>

                    {/* Data structure tags */}
                    <div className="flex items-center gap-4 group">
                        <Label className="w-60" text={strings('data_structure_tags')} color="neutral-400" fontWeight="medium" size="md"/>
                        <F4IOSelect
                            isMulti
                            type="tags"
                            name="tags.tags"
                            placeholder=""
                            hideArrow
                            dropdownClassname="border-0 !bg-neutral-50 ring-0 ring-neutral-50 group-hover:!bg-neutral-100 focus:!bg-neutral-50 focus:!border-1 focus:!border-neutral-200 focus:!shadow-[rgba(16,24,40,0.05)_0px_0px_6px_4px] focus:!shadow-neutral-200"
                        />
                    </div>

                    {/* designation */}
                    <div className="flex items-center gap-4 group">
                        <span className="flex w-60">
                            <Label className="mr-1.5" text={strings('designation')} color="neutral-400" fontWeight="medium" size="md" />
                            <Icon icon={faExclamationCircle} className="w-4 h-4 hover:cursor-pointer hover:text-neutral-600" color="neutral-400" tooltipProps={{showDelay: 1000}} tooltip="This is the prefix to show before each record number. It cannot be changed once it is set"/>
                        </span>
                        <F4Textbox isDisabled={!!sboId} autoComplete="off" name="designation" isGhost inputClassName={classNames(inputClassName, (sboId ? 'cursor-not-allowed group-hover:!bg-neutral-50' : 'group-hover:!bg-neutral-100'))} />
                    </div>

                    {/* activate features */}
                    <div className="flex items-center gap-4 group">
                        <Label className="w-60" text={strings('activate_features')} color="neutral-400" fontWeight="medium" size="md"/>
                        <F4Textbox isGhost inputClassName={inputClassName} placeholder="Here later in FIR-183 we will have the tags and their modal"/>
                    </div>

                    {/* toggles */}
                    <div className="flex">
                        <div className="w-64">
                            <F4ToggleSwitch name="hasRecordTags" className="w-56" size="sm" descriptionClassName="!text-neutral-700" labelClassName="!text-neutral-700" description={strings('allow_tagging_of_records')} defaultValue={true} />
                        </div>
                        <div className="flex w-64">
                            <F4ToggleSwitch name="shouldStoreHistory" size="sm" descriptionClassName="!text-neutral-700" description={strings('store_record_change_history')}/>
                            <Icon icon={faExclamationCircle} className="w-4 h-4 hover:cursor-pointer hover:text-neutral-600" color="neutral-400" tooltipProps={{showDelay: 1000}} tooltip="Activate this option only if absolutely necessary, as it may affect performance and increase the time required to load records."/>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
