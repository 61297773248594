import {Button, Label, Modal, TModalProps, TModalRef} from '@aktek/f4kit';
import {forwardRef, useCallback, useState} from 'react';

import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import useFilteredItems from '@/app/Explorer/hooks/useFilteredItems';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import {strings} from '@/localization/i18n';
import {TFolder} from '@/types/Folder.t';

import {TreeViewSelector} from '../F4Elements/F4TreeViewSelector';
import SearchBox from '../SearchBox/SearchBox';

type TSBOMoveModal = TModalProps & {
    folder?: TFolder
    onSave?: (folder) => void
}

const SBOMoveModal = (props: TSBOMoveModal, ref: TModalRef) => {
    const {folder, onSave, ...rest}= props;

    const defaultTreeValue = folder?.parentId ? {[folder?.parentId]: true} : {[FOLDERS_AND_DATA]: true};

    const [selectedFolder, setSelectedFolder] = useState(defaultTreeValue);
    const [searchTerm, setSearchTerm] = useState('');

    const {folders} = useGlobalFilesAndFolders();

    const data = useFilteredItems(folders, searchTerm, true, 'folder', folder?.id, folder?.parentId, false);

    const handleCloseModal = ref?.['current']?.close;

    const getParentId = useCallback((folder) =>{
        if (GetFirstKey(folder) == FOLDERS_AND_DATA) return null;

        return GetFirstKey(folder);
    }, []);

    const handleSaveClick = () => {
        const finalValue = {
            id: folder?.id,
            parentId: getParentId(selectedFolder as object),
        };
        onSave?.(finalValue);
        handleCloseModal?.();
    };

    return (
        <Modal
            ref={ref}
            title={strings('select_directory')}
            className="overflow-hidden !rounded-3xl h-full"
            headerClassName="border-b border-b-neutral-100 pr-2 pl-6 pb-1 pt-2 text-xl"
            {...rest}

        >
            <div className="overflow-auto p-1 flex flex-col  h-full w-full">
                <div className="flex-1 overflow-y-auto p-5 max-h-[calc(100%-4.5rem)]">
                    <div className="-mt-4">
                        <SearchBox onSearch={setSearchTerm}
                            className="!bg-neutral-100 rounded-lg sticky elevation-5 !shadow-none -top-5 mt-6 mb-4"
                            textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                        />
                        <TreeViewSelector
                            data={data}
                            size="xs"
                            onChange={setSelectedFolder}
                            parentId={folder?.parentId}
                            value={selectedFolder}
                        />

                        {!data?.length && <Label
                            size="sm"
                            text={strings('no_results_found')}
                            className="mt-5 ml-3 overflow-hidden"
                            color="neutral-600"/>}

                    </div>
                </div>

                <div className="border-t border-t-neutral-200 p-3 flex justify-end gap-3">
                    <Button
                        isGhost
                        size="sm"
                        label={strings('cancel')}
                        onClick={handleCloseModal}
                    />

                    <Button
                        onClick={handleSaveClick}
                        size="sm"
                        variant="primary"
                        label={strings('save')}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default forwardRef(SBOMoveModal);
