import type {TDropdownValue, TVariant} from '@aktek/f4kit';
import {UltraSelector} from '@aktek/f4kit';
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';

import usePropsArray from '@/hooks/usePropsArray';

import {IOCacheContext} from './IOCache/IOCacheContext';
import {TIOCacheContext} from './IOCache/IOCacheContext';
import F3CacheFn from './IOCache/IOCacheManager';
import IOHub from './IOHub';
import GetOptions from './IOSelect.GetOptions';
import GetTooltip from './IOSelect.GetTooltip';
import type {TIOHubProps} from './types/IOHub.t';
/**
 *
 * @category F3Caching
 *
 *
 *  @description Provides a logical component that provides dropdown selection of F3 Cached Data.
 *  @example  <IOSelect type="ComprehensiveFields" sboId={sboId} value={fieldId2} fieldType= "Color" onChange = {(e)=> {
                setfieldId2(e.value);
               }}/>
 *
 *  @param {(SBO|Field|ComprehensiveFields|UniqueValues|Ref|Section|FlowStatus)} type - Type of the media, could be one of: SBO|Field|ComprehensiveFields|UniqueValues|Ref
 *  @param {string} sboId
 *  @param {string} fieldId
 *  @param {string} displayFieldId
 *  @param {(Literal|Color|Numeric|String|GPS)} fieldType
     - Filtered type of the fields if type=(Field or ComprehensiveFields).
        Could be one of: Literal (string, integer, float, closedlist)|Color|Numeric (integer, float)|String|GPS
 *  @return {React.Component}
 */
type valueType = TDropdownValue | TDropdownValue[];

type IOSelectPropsCommon = {
    value?: valueType;
    options?: unknown;
    isMulti?: boolean;
    isDisabled?: boolean;
    allowSubstringSearch?: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onChange?: (value: valueType) => void;
    onDropdownClose?: () => void;
    getDataDictionary?: (type: string) => object

    className?: string;
    borderColor?: TVariant;
    dropdownClassname?: string;
    placeholder?: string;
    portal?: boolean;
    hideArrow?: boolean;
    autoOpenOnMount?: boolean;
    returnValueLabelDictionary?: boolean;
};

export type TIOSelectProps = IOSelectPropsCommon & TIOHubProps

type IOSelectRef = {
    refresh: () => void
}

// eslint-disable-next-line react/display-name
function IOSelect(props: TIOSelectProps) {
    const {value, returnValueLabelDictionary, allowSubstringSearch,
        isMulti, isDisabled, onChange, type,
        autoOpenOnMount, hideArrow, borderColor, dropdownClassname,
        onKeyDown, onDropdownClose, className, placeholder} = props;

    const ioCacheContext = React.useContext<TIOCacheContext>(IOCacheContext);
    const [search, setSearch] = useState(null);

    const optionsKey = 'optionskey'; // type + sboId + fieldId + parentSboId + displayFieldId + JSON.stringify(parentValue);
    const hubRule = IOHub.GetHubRule(type);

    if (!hubRule) {
        console.warn('Type is unrecognized');

        return null;
    }

    const hubProps = {...props, search};

    const key = IOHub.GetKey(hubProps);

    const options = ioCacheContext.getObject(key);

    const refreshKeys = useMemo(() => {
        return hubRule.refreshKeys;
    }, [hubProps]);

    // @ts-ignore
    const renderOption = hubRule.renderOption?.(props);

    const propsArray = usePropsArray(props, refreshKeys);

    const refresh = () => {
        F3CacheFn.getData(ioCacheContext, props);
    };

    // useImperativeHandle(ref, () => {
    //     return {
    //         refresh,
    //     };
    // });

    useEffect(() => {
        if (!refreshKeys?.length) {
            refresh();
        }
    }, []);

    useEffect(() => {
        if (refreshKeys?.length > 0) {
            refresh();
        }
    }, propsArray);

    // useNonInitialEffect(() => {
    //     throw new Error('Cannot change type ' + type);
    // }, [type]);

    // Create a function to handle key presses within the IOSelect component
    const handleKeyPress = (e) => {
        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    // Attach the handleKeyPress function to the window's keydown event
    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [onKeyDown]);

    const tooltip = GetTooltip(ioCacheContext.getObjectLastUpdateTime(key));

    const isLoading = ioCacheContext.getLoadingState(key);
    // const hasAPIError = ioCacheContext.getErrorState(key);

    const limitCount = hubRule.limitCount;
    const hasAsyncSearch = limitCount && limitCount > 0;

    const onSearch = hasAsyncSearch ? async (search: string) => {
        setSearch(search);
        F3CacheFn.getData(ioCacheContext, {...props, search});

        return true;
    } : null;

    const getMissingValueFunction = () => {
        return async (missingValues: TDropdownValue[]) => {
            return F3CacheFn.getMissingValues(hubProps, missingValues);
        };
    };

    const constraintText = F3CacheFn.getConstraintText(props);

    if (constraintText) {
        return constraintText;
    }

    return <UltraSelector
        allowFilterSubstringSearch={false}
        placeholder={placeholder}
        className={className}
        autoOpenOnMount={autoOpenOnMount}
        onDropdownClose={onDropdownClose}
        returnValueLabelDictionary={returnValueLabelDictionary}
        getMissingValuesFn={getMissingValueFunction()}
        size="md"
        isMulti={isMulti}
        onChange={onChange}
        onSearch={onSearch}
        dropdownHintLabel={limitCount && `${limitCount} Records Limit`}
        optionsKey={optionsKey}
        onRefresh={refresh}
        isDisabled={isDisabled} // this
        // isInvalid={hasAPIError} // this
        isClearable={true} // this
        isLoading={isLoading}
        refreshIconTooltip={tooltip}
        showLoaderOverlay
        deleteUnresolvedEntries
        closeOnSingleSelect
        hasRefreshButton={true}
        value={value}
        portal={false}
        options={GetOptions({options: options?.object?.dictionary, ...props})}
        renderOption={renderOption}
        // onKeyDown={onKeyDown}
        hideArrow={hideArrow}
        borderColor={borderColor}
        dropdownClassname={dropdownClassname}
    />;
}

;

export default IOSelect;
// export default forwardRef(IOSelect);
