import {Modal, TModalRef, toast, useModalRef} from '@aktek/f4kit';
import {XSDKResponsePromise} from '@aktek/types';
import {faClose} from '@fortawesome/pro-regular-svg-icons';
import _get from 'lodash/get';
import {forwardRef} from 'react';

import {TResponse} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import Table from '@/components/Table/Table';
import {TColumn} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';

export type THistoryModalProps = {
    id: string,

    getDataFunction?:(userId: string, search: string, sort: object, skip: number, limit: number)=>XSDKResponsePromise<unknown>,
    columns:TColumn[],
}

const HistoryModal= (props: THistoryModalProps, ref:TModalRef)=> {
    const {id, getDataFunction, columns} = props;
    const modalRef = useModalRef(ref);

    const DEFAULT_SORTING_STATE = [{id: 'unixDate', desc: true}];
    const DEFAULT_PAGE_SIZE = 20;

    return (
        <Modal
            defaultIsOpened
            ref={ref}
            size="xl"
            className=" !w-2/3 !rounded-3xl !h-4/5"
            headerClassName="!py-0 !px-2 rounded-xl hidden">
            <Table
                emptyProps={{
                    className: 'mb-[20vh]',
                }}
                className=" !rounded-3xl h-full"
                baseTableClasses={{
                    tbodyCellTD: ' py-6 px-6  text-neutral-600  text-sm text-left',
                }}
                name={strings('action')}
                defaults={{pageSize: DEFAULT_PAGE_SIZE, sortingState: DEFAULT_SORTING_STATE}}
                getColumns={async ()=> columns}
                getRows={
                    async (paginationState, sortingState, countState, searchState) => {
                        const {pageSize, currentPage} = paginationState;
                        const search = searchState.query.trim();
                        const sort = {id: sortingState[0]?.id, order: sortingState[0]?.desc ? '-1' : '1'};
                        const offset = pageSize * (currentPage -1);

                        const res = await getDataFunction(id, search, sort, offset, pageSize);

                        const data = res.getData() as TResponse;

                        if (!data) { // @TODO: double check this
                            toast.error(strings('error_fetching_data'));

                            return [];
                        }

                        const items = (Array.isArray(data) ? data : data?.data) || [];
                        const count = _get(data, ['metadata', 0, 'count']);

                        if (count !== undefined) countState.setCount(count);

                        return items;
                    }}
                ribbonProps={{hasSearch: false, title: strings('user_history')}}
                ribbonActions={
                    [
                        {
                            key: 'close', icon: faClose, label: strings('close'), tooltip: strings('close'), placement: 'right',
                            buttonProps: {isGhost: true, variant: 'primary', className: '-mr-6', onClick: () => modalRef.current?.close()},
                        },
                    ]
                }

            />
        </Modal>
    );
};

export default forwardRef(HistoryModal);
