import {SBOService} from '@aktek/morph-frontend-sdk';

type TGetColumns = {
    sboId: string
}

type TColumn = {
    key: 'string';
    header: 'string';
    type: 'string';
    isSortable: boolean;
}

type TGetDataTableColumns = ({sboId}:{sboId: string}) => Promise<TColumn[]>

export const GetDataTableColumns: TGetDataTableColumns = async ({sboId}: TGetColumns) => {
    const res = await SBOService.getSBOHeaders(sboId);
    const data = res.getData();
    if (!data) return [];

    const items: TColumn[] = data?.headers || [];

    return items.map((item) => {
        const {_id, title, type, ...rest} = item;
        const column: TColumn = {
            key: _id,
            header: title,
            type: type,
            isSortable: true,
            ...rest,
        };

        return column;
    });
};
