import {FilesSwiper} from '@aktek/f4kit';

import {getResourceFileFromMediaFile} from '@/helpers/File';
import {TMediaFile} from '@/types/Media';

type MediaGenericCellViewProps = {
    files: TMediaFile[];
}

const MediaGenericCellView = ({files}: MediaGenericCellViewProps) => {
    return <div className="w-64 h-16">
        <FilesSwiper
            defaultFileCategory="unknown"
            itemSideLength={100}
            objectFit="contain"
            files={files?.map(getResourceFileFromMediaFile)}
        />
    </div>;
};

export default MediaGenericCellView;
