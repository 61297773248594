import {F4FormElementDecorator, F4FormStep} from '@aktek/f4form';
import UserRolesService from '@aktek/helios-frontend-sdk/out/UserRolesService';
import {useEffect, useState} from 'react';

import F4RoleSelector from '@/components/F4Elements/F4RoleSelector/F4RoleSelector';

export function UserRoles({viewState}) {
    const [initialState, setInitialState] = useState([]);

    const fetchSelectedRoles = async () => {
        const rolesRes = await UserRolesService.getUserRoles(viewState?._id as string);
        if (!rolesRes.isSuccessful()) return;

        if (!initialState.length) {
            setInitialState(rolesRes.getData());
        }

        return rolesRes;
    };

    useEffect(() => {
        viewState?._id
        && fetchSelectedRoles();
    }, [viewState?._id]);

    return (
        <F4FormStep step="userRoles">
            <F4RoleSelector name="roles"
                initialState={initialState}
                fetchSelectedRoles={viewState?.mode === 'edit' ? fetchSelectedRoles : undefined}
            />
        </F4FormStep>
    );
}

const F4UserRoles = F4FormElementDecorator(UserRoles);
export default F4UserRoles;
