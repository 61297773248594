import {faUser} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

export const openDetails = (id, name) => {
    console.log('....Actions.ts -> Line 7 view details', id);
    // return TabsManager.focusOrOpenNewTab(
    //     'dataTable',
    //     sboName,
    //     {label: sboName, sboId},
    //     {tabKey: 'dataTable-' + sboId},
    // );
};

export const getOpenEntry = (id, name) => {
    return ({
        key: 'view',
        label: [strings('view_user_details'), name].join(': '),
        icon: faUser,
        onClick: () => openDetails(id, name),
    });
};
