import {RefFieldButton} from '@/components/RefFieldButton/RefFieldButton';

const RefListAnnexCellView = ({value, sboId, fieldId}) => {
    if (!sboId || fieldId) return null;

    return <div className="flex gap-1">
        {value?.map?.((refValue, index) => <RefFieldButton
            key={refValue?._id + '-' + index}
            sboId={sboId}
            fieldId={fieldId}
            value={refValue?._id}
        />)}
    </div>;
};

export default RefListAnnexCellView;
