import {TF4FormContextValue} from '@aktek/f4form';
import {toast} from '@aktek/f4kit';
import {MorphService, SBOService} from '@aktek/morph-frontend-sdk';
import {XSBO} from '@aktek/types';
import {useEffect, useState} from 'react';

import useF4CurrentTab from '@/app/Home/hooks/useF4CurrentTab';
import AskForConfirmation from '@/asks/AskForConfirmation';
import {strings} from '@/localization/i18n';

const useRecordEditor = (sboId: string, onSave: () => void) => {
    const [loading, setLoading] = useState(false);
    const [sbo, setSBO] = useState<XSBO>({} as XSBO);
    const currentTab = useF4CurrentTab();

    useEffect(() => {
        getCreationSBOById(sboId);
    }, []);

    const getCreationSBOById = async (id: string) => {
        setLoading(true);
        const res = await SBOService.getCreationSBOById(id);

        if (!res.isSuccessful()) {
            setLoading(false);
            currentTab.close();

            return;
        }

        setSBO(res.getData() as XSBO);
        setLoading(false);
    };

    const handleSave = async (f4FormContext:TF4FormContextValue, closeTab?:boolean) => {
        if (!f4FormContext.validate().isFormValid) return;
        setLoading(true);

        const finalResults = {
            ...f4FormContext.data,
            designation: sbo?.designation || null,
            sboId,
        };
        const res = await MorphService.storeNewObject(finalResults);

        if (res.isSuccessful()) {
            toast.success(strings('new_record_added_successfully'));
            currentTab.updateConfig({hasUnsavedChanges: false});
            // currentTab.updateNodeConfig({hasUnsavedChanges: false});
            closeTab && currentTab.close();
            setLoading(false);
            onSave?.();

            return true;
        }

        setLoading(false);

        return false;
    };

    const handleSaveAndFillAnother = async (f4FormContext:TF4FormContextValue) => {
        if (await handleSave(f4FormContext)) {
            f4FormContext.reset();
        }
    };

    const handleCancel = async (isEdited: boolean) => {
        if (!isEdited) return currentTab.close();

        const shouldClose = await AskForConfirmation(
            strings('discard_confirmation'),
            strings('discard_confirmation_desc'),
            {
                confirmButtonProps: {variant: 'error', label: strings('discard')},
                cancelButtonProps: {label: strings('stay_here')},
            },
        );

        if (shouldClose) currentTab.close();
    };

    return {loading, sbo, handleCancel, handleSave, handleSaveAndFillAnother};
};

export default useRecordEditor;
