import {LicensePlanService} from '@aktek/helios-frontend-sdk';

const OnLicenseSelect = async (license, setHasCustomizations, onSetValue, companyInfo) => {
    if (!license) {
        setHasCustomizations(false);

        return;
    }

    setHasCustomizations(false);

    // Empty the values before setting the new ones
    onSetValue('license.customizations', {
        ...companyInfo?.license?.customizations,
        features: {},
        limits: {},
    });

    const res = await LicensePlanService.getLicensePlan(license);
    const success = res.isSuccessful();

    if (!success) return;
    const data = res.getData();

    data && onSetValue('license.customizations', {
        ...companyInfo?.license?.customizations,
        features: data?.['features'] || {},
        limits: data?.['limits'] || {},
    });

    setHasCustomizations(true);
};

export default OnLicenseSelect;
