import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

import {TField} from '../../types/SBOEditor.t';
import GenerateId from '../GenerateID.fn';

export default function GetAddFieldUpdateObject(field: Partial<TField>, sectionId: string) {
    const id = GenerateId();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {label, color, icon, ...fieldWithoutUnnecessaryProps} = field;
    const newField = {parentSection: sectionId, ...fieldWithoutUnnecessaryProps, id};
    newField.name = field.id ? `${field.name || ''}` : '';

    const updateObject: TUpdateObject<XSBO> = {
        [`fieldsMap.${id}`]: newField,
        [`sectionsMap.${sectionId}.fields`]: {[UpdateActions.APPEND]: id},
        'tableFields': {[UpdateActions.APPEND]: id},
    };

    return {updateObject, fieldId: id};
}

