
import {Button, Label, OverflowContainer, Popover, SearchBar, TButtonProps} from '@aktek/f4kit';
import {useMemo} from 'react';

import AskForMenu from '@/asks/AskForMenu';

import {RibbonKnownActions} from '../Constants/RibbonKnownActions.c';
import {GetActionsClasses} from '../Styles/GetClasses';
import {TRibbonActionProps, TTableRibbonAction} from '../Types/Ribbon.t';
import ActionButton from './ActionButton';

const defaultButtonProps: Partial<TButtonProps> = {
    isOutlined: false,
    isGhost: false,
    variant: 'white',
};

const TableRibbon = ({state, ribbonProps={hasSearch: true}, title, actions = [], onActionClick, searchState, searchBarProps}: TRibbonActionProps) => {
    const classes = GetActionsClasses({});

    const items = useMemo(() => {
        const leftActions: TTableRibbonAction[] = [];
        const rightActions: TTableRibbonAction[] = [];
        actions?.forEach((action) => {
            if (!action) return;
            const finalAction: TTableRibbonAction = typeof action == 'string' ? RibbonKnownActions[action] : action;

            if (!finalAction) return;

            if (finalAction.isVisible === false) return;

            if (finalAction.placement == 'right') {
                rightActions.push(finalAction);
            } else {
                leftActions.push(finalAction);
            }
        });
        const Search = ribbonProps?.hasSearch ? <SearchBar key="search" className={`max-w-48 ${searchBarProps?.className || ''}`} onSearch={searchState.setQuery} debounceTime={400} {...searchBarProps}/> : null;

        return [
            <Label text={ribbonProps.title} key={title} size="xl" color="neutral-900" fontWeight="semibold" />,
            ...leftActions.map((action, index) => {
                const onClickCb = (e: React.MouseEvent) => {
                    const {contextMenuEntries} = action;

                    if (contextMenuEntries) {
                        contextMenuEntries.map((entry)=> {
                            entry.onClick = (e) => {
                                onActionClick(entry.key, e, state);
                            };
                        });
                        AskForMenu(e, contextMenuEntries);
                    } else {
                        onActionClick?.(action.key, e, state);
                    }
                };

                return <ActionButton
                    defaultButtonProps={defaultButtonProps}
                    onClick={onClickCb}
                    key={action.key + '-' + index}
                    action={action} />;
            }),
            <span id="right-split-dix" className={classes.middleSpan} key="right-split-div" />,

            Search,

            ...rightActions.map((action, index) => {
                const onClickCb = (e: React.MouseEvent) => {
                    const {contextMenuEntries} = action;

                    if (contextMenuEntries) {
                        contextMenuEntries.map((entry)=> {
                            entry.onClick = (e) => {
                                onActionClick(entry.key, e, state);
                            };
                        });
                        AskForMenu(e, contextMenuEntries);
                    } else {
                        onActionClick?.(action.key, e, state);
                    }
                };

                return <ActionButton
                    defaultButtonProps={defaultButtonProps}
                    key={action.key + index + 'right'}
                    onClick={onClickCb}
                    action={action} />;
            }),
        ];
    }, [actions]);

    return (
        <OverflowContainer
            className={classes.overflow}
            isProtectedFn={() => false}
            items={items}
            renderOverflow={(elements) => (
                <Popover className={classes.popover} content={
                    <div className={classes.overflowContentContainer}>
                        {elements}
                    </div>}>
                    <Button className={classes.overflowContentContainer} label="..." />
                </Popover>
            )}
        />
    );
};

export default TableRibbon;
