import {Icon, Label, NSwitch, Tooltip} from '@aktek/f4kit';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import {LAYOUT_OPTIONS} from '../constants/Record.LayoutOptions.c';
import {GetSingleRecordClasses} from '../styles/Record.Editor.styles';

const RecordHeader = ({layout, onLayoutChange, title, description}) => {
    const classes = GetSingleRecordClasses();

    return (
        <div className={classes.headerContainer}>
            <div className="flex flex-row gap-3 items-center justify-start">
                <Label
                    text={title}
                    size="4xl"
                    color="neutral-900"
                    fontWeight="bold"
                />

                {description && (
                    <Icon
                        tooltip={description}
                        tooltipProps={{showDelay: 500}}
                        icon={faInfoCircle}
                        color="neutral-600"
                        className="cursor-pointer w-5 h-5"
                    />
                )}

                <Label
                    text={strings('new')}
                    size="xs"
                    color="neutral-700"
                    fontWeight="medium"
                    className="px-3 py-1 rounded-2xl bg-neutral-100"
                />
            </div>
            <Tooltip
                content={
                    <Label
                        text={layout ? strings('display_1_column') : strings('display_2_columns')}
                        size="xs" />
                }
                placement="top"
                showDelay={500}
            >
                <NSwitch
                    options={LAYOUT_OPTIONS}
                    value={layout}
                    onChange={onLayoutChange}
                    className="!border-neutral-100 !bg-neutral-50 !border !rounded-lg !p-0" />
            </Tooltip>

        </div>
    );
};

export default RecordHeader;
