import Automatic from './Automatic';

const AutomaticCellView = ({value, dynamicField}) => {
    const isTime = dynamicField == 'Time';

    return <Automatic value={value} isTime={isTime}/>;
};

export default AutomaticCellView;

