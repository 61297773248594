// SmartFilterActions.js
import {Button} from '@aktek/f4kit';
import {MouseEventHandler} from 'react';

import {strings} from '@/localization/i18n';

type SmartFilterActionsProps = {
    onDelete: MouseEventHandler<HTMLButtonElement>;
    onCancel: MouseEventHandler<HTMLButtonElement>;
    onSave: MouseEventHandler<HTMLButtonElement>;
    onNewFilter: MouseEventHandler<HTMLButtonElement>;
}

const SmartFilterActions: React.FC<SmartFilterActionsProps> = ({
    onDelete,
    onCancel,
    onSave,
    onNewFilter,
}) => {
    return <div className="flex justify-between p-4">

        <Button
            variant="error"
            label={strings('delete')}
            size="md"
            onClick={onDelete}
        />
        <div className="flex gap-1">
            <Button isGhost
                label={strings('cancel')}
                size="md"
                onClick={onCancel}
            />
            <Button isOutlined
                label={strings('override_this_filter')}
                size="md"
                onClick={onSave}
            />
            <Button
                variant="primary"
                label={strings('save_as_new_filter')}
                size="md"
                onClick={onNewFilter}
            />
        </div>
    </div>;
};

export default SmartFilterActions;
