import {Icon, Label, TabNav, TabsManager, TContextMenuEntry, TIconType} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {faArrowUpRight, faDatabase, faPlusSquare} from '@fortawesome/pro-regular-svg-icons';
import {DockLocation} from 'flexlayout-react';
import React, {useEffect, useState} from 'react';

import useF4CurrentTab from '@/app/Home/hooks/useF4CurrentTab';
import AskForConfirmation from '@/asks/AskForConfirmation';
import AskForMenu from '@/asks/AskForMenu';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

import {AddToRecentlyUsedSBOs, STORAGE_KEY} from '../helpers/RecentlyUsedSBOs.fn.';

type TRecentSBO = {
    isEdited: boolean
    key: string
    label: string
    icon: TIconType,
}

type TSBOEditorSidebarProps = {
    recentSBO: Array<TRecentSBO>
    selectedSboId: string
    isSBOEdited?: boolean
    onChange?: (value: string) => void
    onSave?: () => void
}

export default function SBOEditorSidebar(props: TSBOEditorSidebarProps) {
    const {recentSBO, selectedSboId, onChange, onSave} = props;
    const [recentlyUsedSBOs, setRecentlyUsedSBOs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const currentTab = useF4CurrentTab();

    const fetchSBOs = React.useCallback(async () => {
        const res = await SBOService.getUsableSBOs(searchTerm, {id: 'name', order: '1'}, 0, 15);

        if (res.isSuccessful()) {
            const data = res.getData() as { data: { _id: string; name: string }[] };
            const SBOs = data?.data?.map((item) => ({
                key: item._id,
                label: item.name,
                icon: faDatabase,
            }));

            setRecentlyUsedSBOs(SBOs);
            localStorage.setItem(STORAGE_KEY, JSON.stringify(SBOs));
        }
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm) {
            fetchSBOs();
        } else {
            if (recentSBO.length !== 15) {
                fetchSBOs();
            } else setRecentlyUsedSBOs(recentSBO);
        }
    }, [searchTerm, recentSBO]);

    const handleSBOClick = async (newTab: boolean, sbo, event) => {
        if (newTab) {
            TabsManager.openNewTab('sboEditor', sbo.label, {sboId: sbo.key, onSave}, {keepFocus: true});
            AddToRecentlyUsedSBOs(sbo);
            event.stopPropagation();
        } else if (event === null && !newTab) {
            if (currentTab.getConfig().hasUnsavedChanges) {
                const canClose = await AskForConfirmation('Are you sure you want to close this window?',
                    'All the data you entered is not saved yet so everything will be discarded.',
                    {
                        confirmButtonProps: {variant: 'error', label: strings('discard')},
                        cancelButtonProps: {label: strings('stay_here')},
                    },
                );

                if (canClose) {
                    currentTab.updateConfig({title: sbo.label, hasUnsavedChanges: false});
                    currentTab.setTabState({sboId: sbo.key, onSave});
                }
            } else {
                currentTab.updateConfig({title: sbo.label, hasUnsavedChanges: false});
                currentTab.setTabState({sboId: sbo.key, onSave});
            }

            onChange(sbo.key);

            AddToRecentlyUsedSBOs(sbo);
        }
    };

    return (
        <div className="flex flex-col gap-1 p-4 pt-0 h-full">
            <div className="flex flex-col gap-4 bg-white elevation-1 !shadow-none pb-1 pt-4 ">

                <div className="flex justify-between items-center px-3">
                    <Label text={strings('recent_data_structures')} color="neutral-600" className="text-sm" fontWeight="semibold" />
                    <Icon tooltipProps={{showDelay: 1000}} tooltip="Add New Data Structure" icon={faPlusSquare} className="h-5 w-5" color="neutral-600"
                        onClick={() => TabsManager.openNewTab('sboEditor', 'New Data Structure', {onSave})} />
                </div>

                <SearchBox
                    onSearch={(e) => setSearchTerm(e)}
                    placeholder={strings('search_data_structures')}
                    hasBottomBorder={false}
                    textBoxInputClassName="!bg-neutral-100 !rounded-lg !text-neutral-900 !text-sm !font-normal !placeholder-neutral-500"
                />
            </div>

            {recentlyUsedSBOs.length > 0
                ? <div className="overflow-y-auto">
                    <TabNav
                        size="sm"
                        orientation="vertical"
                        tabs={recentlyUsedSBOs}
                        value={selectedSboId || recentlyUsedSBOs[0]}
                        onChange={(sboId) => {
                            const mySBO = recentlyUsedSBOs.find((sbo) => sbo.key === sboId);
                            const sbo = {
                                key: sboId,
                                label: mySBO.label,
                                icon: faDatabase,
                            };
                            handleSBOClick(false, sbo, null);
                        }}
                        className="!my-1"
                        buttonProps={{allowIconAutoPlacement: true}}
                        buttonClassName="hover:bg-primary-25 hover:text-primary-700 !min-h-10 !h-full text-wrap !text-left group"
                        getButtonProps={(tab) => {
                            return {
                                edgeIcons: {
                                    right:
                                    {
                                        icon: faArrowUpRight, tooltip: strings('open_in_a_new_tab'), tooltipProps: {showDelay: 1000},
                                        color: 'neutral-600',
                                        className: 'hidden group-hover:!flex rounded-lg hover:text-primary-600 hover:bg-primary-25 p-1 w-4 h-4',
                                        onClick: (e) => {
                                            const sbo = {
                                                key: tab.key,
                                                label: tab.label,
                                                icon: faDatabase,
                                            };
                                            handleSBOClick(true, sbo, e);
                                        },
                                    },
                                },
                                onContextMenu: (event: React.MouseEvent) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    const entries: TContextMenuEntry[] = [];
                                    const sbo = {
                                        key: tab.key,
                                        label: tab.label,
                                        icon: faDatabase,
                                    };
                                    entries.push({
                                        key: 'splitUp',
                                        icon: null,
                                        label: 'Split Up',
                                        onClick: () => {
                                            const node = TabsManager.openNewTab('sboEditor', sbo.label, {sboId: sbo.key, onSave}, {keepFocus: true});
                                            TabsManager.splitTab(node.getId(), DockLocation.TOP);
                                        },
                                        labelProps: {className: '!text-neutral-900 !font-medium'},
                                    });
                                    entries.push({
                                        key: 'splitDown',
                                        icon: null,
                                        label: 'Split Down',
                                        onClick: () => {
                                            const node = TabsManager.openNewTab('sboEditor', sbo.label, {sboId: sbo.key, onSave}, {keepFocus: true});
                                            TabsManager.splitTab(node.getId(), DockLocation.BOTTOM);
                                        },
                                        labelProps: {className: '!text-neutral-900 !font-medium'},
                                    });
                                    entries.push({
                                        key: 'splitLeft',
                                        icon: null,
                                        label: 'Split Left',
                                        onClick: () => {
                                            const node = TabsManager.openNewTab('sboEditor', sbo.label, {sboId: sbo.key, onSave}, {keepFocus: true});
                                            TabsManager.splitTab(node.getId(), DockLocation.LEFT);
                                        },
                                        labelProps: {className: '!text-neutral-900 !font-medium'},
                                    });
                                    entries.push({
                                        key: 'splitRight',
                                        icon: null,
                                        label: 'Split Right',
                                        onClick: () => {
                                            const node = TabsManager.openNewTab('sboEditor', sbo.label, {sboId: sbo.key, onSave}, {keepFocus: true});
                                            TabsManager.splitTab(node.getId(), DockLocation.RIGHT);
                                        },
                                        labelProps: {className: '!text-neutral-900 !font-medium'},
                                    });

                                    AskForMenu(event, entries);
                                },
                            };
                        }}
                    />
                </div>
                : <Label text={strings('no_data_structures_found')}
                    fontWeight="medium" color="neutral-600"
                    className="ml-2 !text-neutral-600 font-normal text-sm" />}
        </div>
    );
}
