import {Header, Icon, Label, TabsManager} from '@aktek/f4kit';
import {faBell, faDisplayChartUp, faHome, faRightFromBracket, faSatellite, faSearch, faUser} from '@fortawesome/pro-regular-svg-icons';
import compact from 'lodash/compact';
import {useCallback, useMemo} from 'react';

import AskForModal from '@/asks/AskForModal';
import AKTEKLogo from '@/assets/images/aktek_logo.png';
import Env from '@/config/env';
import {usePermissions} from '@/context/Permissions/usePermissions';
import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';
import {useSignUserOut} from '@/context/UserContext/Hooks/useSignUserOut';
import {useUserContext} from '@/context/UserContext/UserContext.Provider';
import {strings} from '@/localization/i18n';
import {useNavigateToLogin, useNavigateToRoot} from '@/routing/navigations';

import SwitchCompanyModal from './components/SwitchCompanyModal';

function AppHeader() {
    const {helperPermissions, permissions} = usePermissions();

    const {canAccessCompanySwitcher} = helperPermissions;
    const {userAuth} = useUserContext();
    const {theme} = useGlobalTheme();

    const {userCompanies, currentUser, currentCompany} = userAuth;
    const {companyName} = currentCompany || {};
    const profilePicURL = currentUser?.profilePicURL;

    const signOut = useSignUserOut();

    const navigateToRoot = useNavigateToRoot();
    const navigateToLogin = useNavigateToLogin();

    const toggleCompanySwitcherModal = useCallback(() => {
        if (currentUser && userCompanies) {
            AskForModal(SwitchCompanyModal);
        }
    }, [currentUser, userCompanies]);

    const getHeader = useCallback(
        ({onLogo, onLogout, profilePicURL}) => ({
            leftItems: compact([
                {
                    type: 'image',
                    src: theme?.images?.logo ? Env.getFileURL(theme.images.logo) : AKTEKLogo,
                    onClick: onLogo,
                    label: 'Logo',
                    className: theme?.images?.logo ? '!p-0' : '',
                },
                {
                    type: 'button',
                    icon: faHome,
                    label: strings('home'),
                    onClick: () => TabsManager.focusOrOpenNewTab('home', 'home', {}, {isPinned: true}),
                },

                // @TODO : permissions exists
                {
                    type: 'button',
                    icon: faDisplayChartUp,
                    label: strings('analysis'),
                    onClick: () => TabsManager.focusOrOpenNewTab('analysis', 'analysis'),
                },

                // @TODO : permissions exists
                {
                    type: 'button',
                    icon: faSatellite,
                    label: 'AKTEK iQ',
                    onClick: () => TabsManager.focusOrOpenNewTab('aktekIQ', 'aktekIQ', {}),

                },
                // @TODO: Permissions.App().ACCESS('Admin')
                {
                    type: 'button',
                    icon: faUser,
                    label: 'Admin',
                    onClick: ()=> TabsManager.focusOrOpenNewTab('navigationViewer', strings('admin'), {navKey: 'admin'}),
                },
            ]),

            rightItems: compact([
                {
                    type: 'button',
                    label: companyName || '',
                    className: `bg-neutral-100  ${!canAccessCompanySwitcher && 'cursor-default hover:!text-neutral-700 !text-neutral-700'} `,
                    buttonProps: {isDisabled: !canAccessCompanySwitcher},
                    onClick: canAccessCompanySwitcher && toggleCompanySwitcherModal,
                },
                {type: 'icon', icon: faSearch, label: 'Search', className: 'h-8 w-8'},
                {type: 'icon', icon: faBell, label: 'Notifications', className: 'h-8 w-8'},
                {
                    type: 'avatar',
                    src: profilePicURL,
                    label: 'Avatar',
                    size: 'xl',
                    subMenu: [
                        {
                            type: 'custom',
                            component: (
                                <div className="flex flex-row items-center justify-center mx-2 mt-2">
                                    <Icon icon={faUser} size="xs" color="neutral-700" />
                                    <div className="flex flex-col items-start justify-start">
                                        <Label
                                            text={currentUser?.name || 'Unknown'}
                                            size="xs"
                                            color="neutral-700"
                                            fontWeight="semibold"
                                            className="ml-4"
                                        />
                                        <Label
                                            text={currentUser?.email || ''}
                                            size="xs"
                                            color="neutral-700"
                                            fontWeight="normal"
                                            className="ml-4 -mt-0"
                                        />
                                    </div>
                                </div>
                            ),
                        },
                        {
                            type: 'button',
                            size: 'md',
                            icon: faRightFromBracket,
                            label: strings('account_settings'),
                        },
                        {
                            type: 'button',
                            size: 'md',
                            icon: faRightFromBracket,
                            label: strings('logout'),
                            onClick: onLogout,
                        },
                    ],
                },
            ]),
        }),
        [currentUser, companyName, profilePicURL, permissions?.admin, theme?.images],
    );

    const header = useMemo(
        () =>
            getHeader({
                onLogout: () => {
                    signOut();
                    navigateToLogin(true);
                },
                onLogo: navigateToRoot,
                profilePicURL: profilePicURL && Env.getFileURL(profilePicURL),
            }),
        [profilePicURL, permissions, currentCompany, theme?.images],
    );

    return <Header value={header} />;
}

export default AppHeader;
