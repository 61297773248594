import {useState} from 'react';

import {TRecordField} from '@/app/DataTable/types/Record.Types';
import {strings} from '@/localization/i18n';

const useInteger = (field: TRecordField, onChange?: (value: string) => void) => {
    const {minimum, maximum} = field;
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleOnChange = (value: string) => {
        const numValue = Number(value);

        if (minimum && numValue < minimum) {
            setErrorMessage(strings('value_must_be_greater_than_or_equal_to', minimum.toString()));
        } else if (maximum && numValue > maximum) {
            setErrorMessage(strings('value_must_be_less_than_or_equal_to', maximum.toString()));
        } else {
            setErrorMessage('');
        }

        onChange?.(value);
    };

    return {errorMessage, handleOnChange};
};

export default useInteger;
