import {Datetime, Label, TContextMenuEntry} from '@aktek/f4kit';

import AskForMenu from '@/asks/AskForMenu';
import {useIODict} from '@/iohub/hooks/useIODict';

import {getOpenEntry, openDetails} from '../Meta/Actions';

interface AutomaticCellProps {
    value: string | number;
    isTime?: boolean;
    userId?: number
}

const Automatic: React.FC<AutomaticCellProps> = ({value, isTime = false}) => {
    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    if (isTime) {
        return <Datetime value={parsedValue} />;
    }

    const userId = value + '';

    const {dict} = useIODict({type: 'user'});

    if (!dict) return null;
    const name = dict[userId] || '';

    const entries: TContextMenuEntry[] = [
        getOpenEntry(userId, name),
    ];

    return <div className="flex items-center gap-1 pointer gap-2 flex-wrap"
        onContextMenu={(event) => {
            AskForMenu(event, entries);
        }}
        onDoubleClick={() => openDetails(userId, name)}
    >

        <Label className="underline cursor-pointer" text={name + ''} />
    </div>;
};

export default Automatic;
