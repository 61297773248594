import {HandleCreateSection} from '../utils/Explorer.ComputeSections.fn';
import {FOLDERS_AND_DATA, RECENTLY_USED, REPOSITORIES, SHORTCUTS} from './constantIds.c';

const SECTIONS = {
    recently_used: HandleCreateSection(RECENTLY_USED, 'recently_used'),
    folders_and_data: HandleCreateSection(FOLDERS_AND_DATA, 'folders_and_data'),
    shortcuts: HandleCreateSection(SHORTCUTS, 'shortcuts'),
    repository: HandleCreateSection(REPOSITORIES, 'repository'),
};
export const EMPTY_EXPLORER_DATA = [
    SECTIONS.recently_used,
    SECTIONS.folders_and_data,
    SECTIONS.shortcuts,
    SECTIONS.repository,
];
