import IOSelect from '@/iohub/IOSelect';

const RefListCellEdit = ({values, onChange, sboId, fieldId}) => {
    if (!sboId || !fieldId) return null;

    return <IOSelect isMulti
        value={values}
        sboId={sboId}
        displayFieldId={fieldId}
        type="ref"
        onChange={(values) => {
            const updatedValues = values?.map?.((_id) => ({_id}));
            onChange(updatedValues);
        }}
    />;
};

export default RefListCellEdit;
