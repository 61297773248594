import {Button, Label, LoadingIndicator, TextBox} from '@aktek/f4kit';
import {faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';
import {useState} from 'react';

import AuthContainer from '@/app/Authentication/components/AuthContainer';
import {strings} from '@/localization/i18n';

import useSetPassword from '../hooks/useSetPassword';
import {getClasses} from '../styles/ForgetPassword.styles';

export default function SetPassword() {
    // todo: Clean this code, and make it more readable
    const [showPassword, setShowPassword]= useState(false);
    const [showConfirmPassword, setConfirmShowPassword]= useState(false);

    const passwordIcons= {right: [{'icon': showPassword ? faEyeSlash : faEye,
        'onClick': ()=> setShowPassword(!showPassword)}]};
    const confirmPasswordIcons= {right: [{'icon': showConfirmPassword ? faEyeSlash : faEye,
        'onClick': ()=> setConfirmShowPassword(!showConfirmPassword)}]};

    const {newPassword, validationResult, handleKeyDown, setNewPassword, forgetPasswordSuccessState, verificationToken, loading,
        handleSetPasswordRequest, handlePasswordChange, handleConfirmPasswordChange} = useSetPassword();

    const {userName} = forgetPasswordSuccessState;

    const {isValid, errors} = validationResult;
    const {passwordTouched, confirmPasswordTouched} = newPassword;

    const hasPasswordError = errors?.password && passwordTouched;
    const hasConfirmPasswordError = errors?.confirmPassword && confirmPasswordTouched;

    const onKeyDown = (event) => handleKeyDown(event, verificationToken, newPassword);

    const onPasswordBlur = ()=> setNewPassword({...newPassword, passwordTouched: true});

    const onConfirmPasswordBlur = ()=> setNewPassword({...newPassword, confirmPasswordTouched: true});

    const onSetPassword = ()=> handleSetPasswordRequest(verificationToken, newPassword);

    const classes = getClasses();

    return (
        <>
            {loading && <div className=" absolute flex items-center w-full h-full p-4f justify-center elevation-5 !shadow-none"
                style={{backgroundColor: 'rgba(0, 0, 0, 0.15)'}}>
                <LoadingIndicator size="xl" />
            </div>}
            {!loading && <AuthContainer>
                <div className={classes.container}>
                    <Label
                        text={strings('welcome') + ' ' + userName + '!'}
                        size="xl"
                        fontWeight="bold"
                        className="!mb-3"/>
                    <Label
                        text={strings('please_set_your_new_password_below')}
                        size="md" />
                </div>

                <TextBox
                    tabIndex={1}
                    label={strings('password')}
                    size="sm"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="• • • • • • • •"
                    className="mb-5"
                    icons={passwordIcons}
                    onChange={handlePasswordChange}
                    variant={hasPasswordError ? 'error': 'neutral'}
                    errorText={hasPasswordError && errors?.password}
                    onKeyDown={onKeyDown}
                    onBlur={onPasswordBlur}/>

                <TextBox
                    tabIndex={1}
                    label={strings('confirm_password')}
                    placeholder="• • • • • • • •"
                    size="sm"
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="!mb-5"
                    icons={confirmPasswordIcons}
                    onChange={handleConfirmPasswordChange}
                    variant={hasConfirmPasswordError ? 'error': 'neutral'}
                    errorText={hasConfirmPasswordError && errors?.confirmPassword}
                    onKeyDown={onKeyDown}
                    onBlur={onConfirmPasswordBlur}/>

                <div>
                    <Button
                        label={strings('set_password')}
                        className="w-full"
                        variant="primary"
                        isDisabled={!isValid}
                        onClick={onSetPassword}/>
                </div>
            </AuthContainer>}
        </>
    );
}

