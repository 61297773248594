import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

export default function GetDeleteFieldUpdateObject(data: XSBO, sectionId: string, fieldId) {
    const {tableFields, sectionsMap} = data;

    const section = sectionsMap[sectionId];
    const {fields} = section;
    const fieldIndex = fields.indexOf(fieldId);

    const updateObject: TUpdateObject<XSBO> = {
        [`fieldsMap.${fieldId}`]: UpdateActions.DELETE,
        [`sectionsMap.${sectionId}.fields.${fieldIndex}`]: UpdateActions.DELETE,
        'tableFields': tableFields.filter((id) => fieldId != id),
    };

    return updateObject;
}

