import {TabsManager} from '@aktek/f4kit';

import {FileTypes} from '@/types/FileTypes.t';

import {FOLDERS_AND_DATA, RECENTLY_USED, REPOSITORIES, SHORTCUTS} from '../constants/constantIds.c';

const treeNodeSections = [FOLDERS_AND_DATA, SHORTCUTS, REPOSITORIES, RECENTLY_USED];

const OnNodeClick = async (node, switchToFoldersView, switchToRecentView, onFolderSelect, openDataTable) => {
    const {type, label, id, props} = node;

    const isSection = treeNodeSections.includes(id);
    const isRecentlyUsed = id == RECENTLY_USED;
    const isFolderAndData = id == FOLDERS_AND_DATA;

    if (isSection) ls.openExplorerSection('explorer-' + id);

    if (isFolderAndData) {
        switchToFoldersView();

        onFolderSelect(node);

        return;
    }

    if (type == FileTypes.recent || isRecentlyUsed) {
        switchToRecentView();

        if (isSection) return onFolderSelect(node);

        return openDataTable({label, id: props.sboId, type: props.type});
    }

    if (type == FileTypes.report) return openDataTable({label, id, type});

    if (type == FileTypes.shortcut) {
        TabsManager.focusOrOpenNewTab(node.component, node.component, {selectedTab: node?.props?.selectedTab});
    }

    if (node.type == 'geo') return;

    switchToFoldersView();
    onFolderSelect(node);

    return;
};

export default OnNodeClick;
