import {F4Form, F4FormChildren, useF4FormRef} from '@aktek/f4form';
import {Stepper, TabNav, toast, TStepperProps, TTabNavProps, usePropState} from '@aktek/f4kit';
import cn from 'classnames';
import _isFunction from 'lodash/isFunction';
import {forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {F4CreatableFormContextProvider} from './F4CreatableFormContext';
import {TF4CreatableFormRef} from './hooks/useF4CreatableFormRef';
import {F4CreatableFormProps} from './types/F4CreatableFormProps.t';

function F4CreatableForm<DataType extends object = object>(props: F4CreatableFormProps<DataType>, ref) {
    const {
        _id,
        children,
        className,
        createSuccessMessage,
        defaultStep,
        editSuccessMessage,
        extra,
        f4FormProps,
        formKey,
        getFunction,
        isLoading,
        mode = 'create',
        onChange,
        onDataLoad,
        onEditStatusChange,
        onLoadingChange,
        onPreGetItem,
        onPreSave,
        onSave,
        onSetValue,
        onStepChange,
        sideBarExtraElement,
        sideBarExtraElementPosition = 'top',
        steps,
        storeFunction,
    } = props;

    const f4FormRef = useF4FormRef<DataType>();
    const isSaving = useRef<boolean>(false);

    const f4FormSteps = useMemo(() => {
        if (!steps?.length) return null;

        return steps.map((step) => step.key);
    }, [steps]);

    const hasSteps = !!steps?.length;

    const hasSidebar = hasSteps || !!sideBarExtraElement;

    const [finalIsLoading, setIsLoading] = usePropState(isLoading, false, onLoadingChange);
    const [currentStep, setCurrentStep] = useState(defaultStep ? steps?.findIndex?.((e) => e.key == defaultStep) : 0);
    const [initialValue, setInitialValue] = useState<DataType>({name: 'giogrio'} as DataType);
    const [errorSteps, setErrorSteps] = useState<string[]>();

    useEffect(() => {
        handleIDChange();
    }, [_id]);

    const handleIDChange = async () => {
        await onPreGetItem?.(_id);
        if (mode === 'create') return setInitialValue(null);

        if (_id && ['edit', 'view'].includes(mode)) {
            getItemCallback();
        }
    };

    const goToPreviousStep = () => {
        if (currentStep !== 0) {
            setCurrentStep(currentStep - 1);
            f4FormRef.current.previousStep();
        } else {
            ref.current?.close?.();
        }
    };

    const goToNextStep = () => {
        const validation = f4FormRef.current.validate({currentStepOnly: true});

        if (!validation.isFormValid) {
            setErrorSteps([steps[currentStep].key]);
            requestAnimationFrame(() => {
                const errorField = document.getElementById('error-field');

                if (errorField) {
                    errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
                }
            });

            return;
        }

        if (currentStep < steps?.length - 1) {
            setCurrentStep(currentStep + 1);
            f4FormRef.current.nextStep();
            setErrorSteps(['']);
        } else {
            finalStoreItem(f4FormRef.current.getData(), mode) && f4FormRef.current?.setEditStatus?.(false);
        }
    };

    const saveForm = () => {
        const validation = f4FormRef.current.validate();
        requestAnimationFrame(() => {
            const errorField = document.getElementById('error-field');

            if (errorField) {
                errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
            }
        });

        if (!validation.isFormValid) return;
        else finalStoreItem(f4FormRef.current.getData(), mode) && f4FormRef.current?.setEditStatus?.(false);
    };

    useImperativeHandle(ref, () => ({
        currentStep,
        isLoading,
        goToPreviousStep,
        goToNextStep,
        saveForm,
        setValue: (key, value) => f4FormRef?.current?.setValue(key, value),
        getData: () => {
            return f4FormRef?.current?.getData();
        },
        getIsChanged: ()=> f4FormRef.current?.getEditStatus(),
        setIsChanged: (value: boolean)=> f4FormRef.current.setEditStatus(value),
    }), [currentStep, f4FormRef.current?.isEdited, saveForm, currentStep]);

    const getItemCallback = useCallback(async () => {
        if (!getFunction) return null;

        setIsLoading(true);
        const res = await getFunction(_id);

        if (res.isSuccessful()) {
            const data = res.getData() as DataType;
            const _data = _isFunction(onDataLoad) ? onDataLoad?.(data) : data;
            setInitialValue(_data);
        }

        setIsLoading(false);
    }, [_id, getFunction, setIsLoading]);

    const finalStoreItem = useCallback(async (item, mode?) => {
        if (isSaving.current) return;

        isSaving.current = true;
        if (!storeFunction) return null;
        const data = typeof onPreSave == 'function' ? onPreSave(item) : item;

        setIsLoading(true);

        const res = await storeFunction(data, mode, _id);

        if (res.isSuccessful()) {
            mode == 'create'
                ? toast.success(createSuccessMessage)
                : toast.success(editSuccessMessage);

            setIsLoading(false);
            onSave?.();
        }

        setIsLoading(false);
        isSaving.current = false;
    }, [storeFunction, onSave]);

    const defaultStepIndex = steps?.findIndex((e) => e.key === defaultStep);
    const NavElement = hasSteps && (mode == 'create' ? Stepper : TabNav);
    const navElementCondProps = hasSteps
        ? mode == 'create'
            ? {
                steps,
                testId: 'stepper',
                errorSteps: errorSteps,
                buttonClassName: 'text-wrap text-left h-fit',
                value: currentStep,
                isJumpable: false,
                defaultValue: defaultStepIndex === -1 ? 0 : defaultStepIndex,
                orientation: 'vertical',
                onChange: (value: unknown) => {
                    setCurrentStep(value as number);
                    f4FormRef.current.goToStepIndex(value as number);
                },
            } as Partial<TStepperProps>
            : {
                testId: 'tabNav',
                value: steps[currentStep].key,
                orientation: 'vertical',
                tabs: steps,
                isDisabled: finalIsLoading,
                defaultValue: defaultStep ? defaultStep : steps[0].key,
                onChange: (value: string) => {
                    const newValue = steps?.findIndex((e) => e.key == value);
                    setCurrentStep(newValue);
                    f4FormRef.current.goToStepIndex(newValue);
                },
                className: 'w-full',
                buttonClassName: 'text-wrap text-left h-fit',
            } as Partial<TTabNavProps>
        : null;

    return (
        <F4Form
            className={cn(className, 'h-full')}
            containerClassName="h-full bg-success-300"
            formKey={formKey}
            ref={f4FormRef}
            onSetValue={onSetValue}
            onChange={onChange}
            initialValue={initialValue}
            steps={f4FormSteps}
            defaultStep={defaultStep}
            onStepIndexChange={onStepChange}
            onEditStatusChange={onEditStatusChange}
            layout={{
                gap: 0.625,
                minColumnWidth: 6.25,
                hasGridLayout: false,
            }}
            {...f4FormProps}
            isLoading={finalIsLoading}>
            {(props) => {
                return <div className="flex h-full w-full">
                    {hasSidebar && <div className="px-5 py-2 flex flex-col grow-3 border-r border-r-neutral-200">
                        {sideBarExtraElementPosition == 'top' && sideBarExtraElement}
                        {hasSteps && <NavElement {...navElementCondProps} />}
                        {sideBarExtraElementPosition == 'bottom' && sideBarExtraElement}
                    </div>
                    }

                    <F4CreatableFormContextProvider extra={extra}>
                        <F4FormChildren functionalProps={props} >
                            {children}
                        </F4FormChildren>
                    </F4CreatableFormContextProvider>
                </div>;
            }}
        </F4Form>
    );
}

export default forwardRef(F4CreatableForm) as <DataType extends object = object>(
    props: F4CreatableFormProps<DataType> & { ref?: Ref<TF4CreatableFormRef> }
  ) => React.ReactElement;

