import {TContextMenuEntry} from '@aktek/f4kit';
import {faCopy, faCorner, faTrash} from '@fortawesome/pro-regular-svg-icons';

import {TField, TSBOActions} from '../types/SBOEditor.t';

export default function GetFieldContextMenuEntries(sectionId, field: TField, sboActions: TSBOActions) : TContextMenuEntry[] {
    const result: TContextMenuEntry[] = [];
    const {id: fieldId} = field;

    result.push({
        key: 'duplicate',
        icon: faCopy,
        label: 'Duplicate',
        color: 'neutral-700',
        onClick: ()=> sboActions.addField(field, sectionId),
    });

    result.push({
        key: 'moveTo',
        icon: faCorner,
        label: 'Move To',
        iconProps: {
            color: 'neutral-700',
        },
        labelProps: {
            color: 'neutral-700',
        },
        onClick: ()=> alert('Logic missing'),
    });

    result.push({
        key: 'delete',
        icon: faTrash,
        label: 'Delete',
        color: 'error-700',
        onClick: ()=> sboActions.deleteField(sectionId, fieldId),
    });

    return result;
}
