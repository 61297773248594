import {useEffect, useState} from 'react';

import {ExplorerComputeSections} from './Explorer.ComputeSections.fn';
import {SearchFunction} from './SearchFunction';

export const getTreeItems = (items, searchValue, recentlyUsed) => {
    const [values, setValues] = useState([]);

    useEffect(() => {
        const filteredData = SearchFunction(ExplorerComputeSections(items, recentlyUsed), searchValue);
        setValues(filteredData);
    }, [items, searchValue]);

    return values;
};
