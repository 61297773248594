import {faColumns, faList} from '@fortawesome/pro-regular-svg-icons';

export const LAYOUT_OPTIONS = [
    {
        label: '',
        value: true,
        icon: faColumns,
    },
    {
        label: '',
        value: false,
        icon: faList,
    },
];
