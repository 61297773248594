import {TF4ThemeColors} from '@aktek/f3theme';
import {F4FormElementDecorator} from '@aktek/f4form';
import {Icon, TIconProps} from '@aktek/f4kit';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

type TToggleIconProps = {
    onChange?: (value: boolean) => void
    value?: boolean
    icons: {
        on: IconDefinition
        off: IconDefinition
    }
    colors: {
        on: TF4ThemeColors
        off: TF4ThemeColors
    }
    tooltip?: {
        on: string
        off: string
    }
} & Omit<TIconProps, 'icon' | 'onClick' | 'color' | 'tooltip'>

export const ToggleIcon = ({onChange, value = false, icons, colors, tooltip, tooltipProps, ...props}: TToggleIconProps) => {
    const finalValue = value ?? false;

    return <Icon
        {...props}
        icon={value ? icons['on'] : icons['off']}
        onClick={() => {
            onChange?.(!finalValue);
        }}
        color={value ? colors['on'] : colors['off']}
        tooltip={value ? tooltip['on'] : tooltip['off']}
        tooltipProps={tooltipProps}
    />;
};

const F4ToggleIcon = F4FormElementDecorator<TToggleIconProps>(ToggleIcon);
export default F4ToggleIcon;
