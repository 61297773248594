import isRequiredValid from '../helpers/validations/isRequiredValid';
import generateGetDeniedColumnProperty from './generateGetDeniedColumnProperty';

export default (field, editableFieldsIDsSet, fieldsMap, clearAffectsByField) => {
    return {
        key: field.id,
        field: field,
        label: ! field?.canCreateNew ? field.name
            : <span>{field.name}
                    +plus+
            </span>,
        pre: <span style={{marginRight: 2}}>{'->'}</span>,
        type: field.type,
        getValid: isRequiredValid(field),
        getDenied: generateGetDeniedColumnProperty(editableFieldsIDsSet, fieldsMap, field),
        isRequired: field.isRequired,
        validatePastedCells: !['string'].includes(field.type.toLowerCase()),
        asyncValidateEditedCells: false,
        clearAffects: clearAffectsByField[field.id],
        affecter: fieldsMap[field.connectedFieldId] || {},
        validateEditedCells: ['gps'].includes(field.type.toLowerCase()),
    };
};

