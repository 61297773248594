import {Label, TContextMenuEntry} from '@aktek/f4kit';
import _compact from 'lodash/compact';

import AskForMenu from '@/asks/AskForMenu';
import {useIODict} from '@/iohub/hooks/useIODict';

import {getOpenEntry, openDetails} from './Actions';

const MetaCellView = ({value, dynamicField, isMulti}) => {
    const id = value;

    const isUser = dynamicField == 'User';

    const {dict} = useIODict({type: isUser ? 'user' : 'group'});

    const names = !isMulti ? [dict?.[id]] : value?.map?.((id) => dict?.[id]);
    const entries: TContextMenuEntry[] = [
        getOpenEntry(id, name),
    ];

    return <div className="flex items-center gap-1 pointer gap-2 flex-wrap"
        onContextMenu={(event) => {
            AskForMenu(event, entries);
        }}
        onDoubleClick={() => openDetails(id, name)}
    >

        {_compact(names).map((name, index) => <Label
            key={name + '-' + index}
            className="underline cursor-pointer"
            text={name + ''}
        />)}
    </div>;
};

export default MetaCellView;
