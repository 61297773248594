import {F4FormDiv, F4Nestor} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';

import LicenseFeature from '@/components/LicenseComponents/LicenseFeature';
import {strings} from '@/localization/i18n';

const LicensingFormFeatures = ({features, isViewMode = false}) => {
    const hasFeatures = features?.length > 0;

    return (

        <F4Nestor name="features">

            <F4FormDiv className="flex justify-between items-center py-4">
                <Label
                    className="w-full"
                    size="xl"
                    text={strings('features')}
                    color="neutral-700"
                    fontWeight="medium"
                />
                {!isViewMode && <Label
                    className="pr-2"
                    size="sm"
                    text={strings('customize')}
                    color="neutral-600"
                    fontWeight="medium"
                />}
            </F4FormDiv>

            {features?.map?.((item, index) => (
                <LicenseFeature
                    isLicensePage={true}
                    isViewMode={isViewMode}
                    key={index}
                    item={item}
                />
            ))}
            {!hasFeatures && (
                <p className="flex items-center mt-3 ml-2 overflow-hidden">
                    {strings('no_results_found')}
                </p>
            )}
        </F4Nestor>

    );
};

export default LicensingFormFeatures;
