import {RefFieldButton, RefFieldButtonProps} from '@/components/RefFieldButton/RefFieldButton';

const RefCellView = ({value, sboId, label, fieldId}: RefFieldButtonProps) => {
    if (!sboId || (!label && !fieldId)) return null;

    return <RefFieldButton
        key={'RefFieldButton-' +sboId + '-' + fieldId}
        sboId={sboId}
        fieldId={fieldId}
        value={value}
        label={label}
    />;
};

export default RefCellView;
