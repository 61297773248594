import {Icon, Image, Label} from '@aktek/f4kit';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';

type TFieldBaseProp = {
    name:string;
    description:string;
    showDescription:boolean;
    image:string;
    classes:Record<string, string>;
}

const FieldBase = (props:TFieldBaseProp) => {
    const {name, description, showDescription, image, classes} = props;

    return <div className={`${classes.text}-1/4`}>
        <div className="flex flex-wrap gap-2 items-center justify-between">

            <div className="flex flex-row gap-2 items-center justify-start">

                <Image alt={name} src={image} className="w-5 h-5"/>
                <Label text={name} color="neutral-400" fontWeight="medium" />

            </div>
            <div>

                {!showDescription && description && <Icon
                    tooltip={description}
                    tooltipProps={{showDelay: 500}}
                    size="xl"
                    className="cursor-pointer pt-1 text-center"
                    icon={faInfoCircle}
                    color="neutral-600" />}
            </div>
        </div>
        <div className="pl-6">

            {showDescription && description && <Label
                text={description} className="italic" color="neutral-400" />}
        </div>
    </div>;
};

export default FieldBase;
