import {TF4FormContextValue} from '@aktek/f4form';
import {toast} from '@aktek/f4kit';
import {CompanySettingsService} from '@aktek/helios-frontend-sdk';
import {F4ArrayChildrenProps} from 'node_modules/@aktek/f4form/dist/Core/types/F4ArrayChildrenProps.t';
import {useEffect, useState} from 'react';

import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';
import {applyLoginLogo, applyLoginSideImage} from '@/helpers/applyLoginAssets.fn';
import XApplyTheme from '@/helpers/ApplyTheme.fn';
import {isEmptyOrAllNill} from '@/helpers/IsEmptyOrAllNill.fn';
import {strings} from '@/localization/i18n';

import {DEFAULT_APPEARANCE} from '../constants/Appearance.c';

const useAppearance = () => {
    const [initialValue, setInitialValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {setTheme, theme} = useGlobalTheme();

    useEffect(() => {
        getDesignSettings();
    }, []);

    const getDesignSettings = async () => {
        setIsLoading(true);
        const res = await CompanySettingsService.getDesignSettings();

        if (res.isSuccessful?.()) {
            if (isEmptyOrAllNill(res.getData()?.['appearance'])) setInitialValue(DEFAULT_APPEARANCE);
            else setInitialValue(res.getData()?.['appearance']);
        }

        setIsLoading(false);
    };

    const handleSave = async (appearanceF4FormContext: TF4FormContextValue<object> | F4ArrayChildrenProps) => {
        setIsLoading(true);

        if (appearanceF4FormContext.validate().isFormValid) {
            const settings = appearanceF4FormContext.data;
            const res = await CompanySettingsService.storeDesignSettings({appearance: settings});
            if (!res.isSuccessful()) return;
            setInitialValue(settings);

            const theme = {
                colors: settings?.['colors'],
                font: settings?.['font'],
                images: settings?.['images'],
            };
            setTheme(theme);
            applyLoginLogo(theme?.images?.logo);

            applyLoginSideImage(theme?.images?.background);

            XApplyTheme(theme);
        }

        setIsLoading(false);
        toast.success(strings('settings_saved_successfully'));
    };

    const handleReset = (appearanceF4FormContext: TF4FormContextValue) => {
        appearanceF4FormContext.reset(DEFAULT_APPEARANCE);
        appearanceF4FormContext.setEditStatus(true);
        XApplyTheme(theme);
    };

    const handleDiscard = (appearanceF4FormContext: TF4FormContextValue) => {
        appearanceF4FormContext.reset(initialValue);
        XApplyTheme(theme);
    };

    return {
        isLoading,
        setIsLoading,
        initialValue,
        getDesignSettings,
        handleSave,
        handleDiscard,
        handleReset,
    };
};

export default useAppearance;
