import {FileExtensionsToCategoryMap, ImageMagicNumbers, TFile, TFileCategory, TFileResource} from '@aktek/f4kit';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';

import Env from '@/config/env';
import {isValidURL} from '@/helpers/strings.fn';
import {strings} from '@/localization/i18n';
import {TMediaFile} from '@/types/Media';

type FileWithType = File & { type: string };

const isValidMagicNumber = (header: string, allowedTypes: string[]): boolean => {
    return allowedTypes.some((type) => ImageMagicNumbers[type]?.includes(header));
};

export const isValidFileType = async (file: FileWithType, allowedTypes: string[]): Promise<boolean> => {
    try {
        const header = await _getFileHeader(file);

        return isValidMagicNumber(header, allowedTypes);
    } catch {
        return false;
    }
};

export const getExtensionFromFile = (file: TFile) => {
    return file?.name?.split('.').pop();
};

export const getFileNameFromFile = (file: TFile) => {
    return file.name?.split?.('.')?.[0];
};

export const getFileCategoryFromFile = (file: TFile) => {
    return FileExtensionsToCategoryMap[file.type];
};

const _getFileHeader = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onloadend = (e) => {
            const arrayBuffer = e.target?.result;
            if (!arrayBuffer) return reject(strings('failedToReadFile'));
            const uint = new Uint8Array(arrayBuffer).subarray(0, 4);
            const header = uint.reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), '');
            resolve(header);
        };

        fileReader.onerror = () => reject(strings('failedToReadFile'));
        fileReader.readAsArrayBuffer(file.slice(0, 4));
    });
};

const getFileCategoryFromMediaFile: (file: TMediaFile) => TFileCategory = (mediaFile: TMediaFile) => {
    console.log('....File.ts -> Line 53', {newFile: mediaFile});

    return FileExtensionsToCategoryMap[mediaFile.extension];
};

export const getMediaFileFromResourceFile: (file: TFileResource) => TMediaFile = (resourceFile: TFileResource) => ({
    key: resourceFile.name,
    extension: getExtensionFromFile(resourceFile.originalFile),
    name: getFileNameFromFile(resourceFile.originalFile),
    type: resourceFile.originalFile?.type,
});

export const getResourceFileFromMediaFile: (file: TMediaFile) => TFileResource = (mediaFile: TMediaFile) => ({
    url: Env.getFileURL(mediaFile.key),
    fileCategory: getFileCategoryFromMediaFile(mediaFile),
    name: mediaFile.name,
});

export const getMediaFilesFromMediaValue = (value: unknown): TMediaFile[] => {
    let files: TMediaFile[] = [];

    if (!Array.isArray(value) && _isObject(value)) {
        if (value?.isExternal) {
            files = [{
                url: value.key,
            }];
        }

        if (!value?.isExternal) {
            files = [{
                url: Env.getFileURL(value.key),
            }];
        }
    }

    if (Array.isArray(value)) { // all items are media files
        files = value.map((valueItem) => {
            // @FIXME: handle legacy media files here,
            // incase any of the items are not media files
            return valueItem;
        });
    }

    if (_isString(value)) { // @FIXME: handle legacy media files here
        const isUrl = isValidURL(value);

        if (isUrl) {
            files = [{url: value}];
        }

        if (!isUrl) {// which supposed to mean it is a key
            files = [{url: Env.getFileURL(value)}];
        }
    }

    return files;
};
