import {FilesSwiper} from '@aktek/f4kit';

import {getResourceFileFromMediaFile} from '@/helpers/File';
import {TMediaFile} from '@/types/Media';

type MediaVideoCellViewProps = {
    files: TMediaFile[];
}

const MediaVideoCellView = ({files}: MediaVideoCellViewProps) => {
    return <div className="w-64 h-32">
        <FilesSwiper
            defaultFileCategory="video"
            itemSideLength={50}
            objectFit="contain"
            files={files?.map(getResourceFileFromMediaFile)}
        />
    </div>;
};

export default MediaVideoCellView;
