export const DefaultSchema = {
    'global': {
        'splitterSize': 5,
        'tabEnableClose': false,
        'tabEnableRename': false,
        'tabClassName': '!text-neutral-600 [&>:last-child]:!ml-4 !font-medium text-xs h-[2.375rem] !px-3 !py-5 rounded-xl max-w-60 overflow-hidden group !transition-all !duration-300 !ease-in-out',
        'tabBorderWidth': 0,
        'tabSetClassNameTabStrip': '!pt-0',
        'tabSetClassNameHeader': '!bg-primary',
        'tabSetHeaderHeight': 40,
        'tabSetTabStripHeight': 50,
        'tabSetMinWidth': 360,
    },
    'borders': [],
    'layout': {
        'type': 'row',
        'id': '#ae98b353-1ceb-402b-a964-cb00ef0a1bec',
        'children': [
            {
                'type': 'tabset',
                'id': '#0e82d610-03e7-46d3-9353-cadf60df3425',
                'children': [
                    {
                        'type': 'tab',
                        'id': '#c826330f-3673-4f35-a0da-847c7551fab3',
                        'config': {
                            'type': 'tab',
                            'title': 'Home',
                            'tabKey': 'home',
                            'componentKey': 'home',
                            'props': {},
                            'options': {
                                'isPinned': true,
                            },
                        },
                    },
                ],
                'active': true,
            },
        ],
    },
};
