
import {XSBO} from '@aktek/types';
import {forwardRef} from 'react';

import {TRecordSection} from '../../DataTable/types/Record.Types';
import {GetSingleRecordClasses} from '../styles/Record.Editor.styles';
import RecordField from './Record.Field';
import RecordSectionHeader from './Record.Section.Header';

type TRecordSectionProps = {
    section: TRecordSection
    fieldsMap: XSBO['fieldsMap']
    layout: boolean
}

const RecordSection = (props: TRecordSectionProps, ref: React.RefObject<HTMLDivElement>) => {
    const {section, fieldsMap, layout} = props;
    const {fields} = section || {};

    const classes = GetSingleRecordClasses(layout);

    return (
        <>
            <RecordSectionHeader name={section?.name} />
            <div className={classes.field}>
                {fields?.map((fieldId) => (
                    <div key={fieldId} ref={ref}>
                        <RecordField field={fieldsMap[fieldId]} layout={layout}/>
                    </div>
                ))}

            </div>
        </>
    );
};

export default forwardRef(RecordSection);
