
import SBOMoveModal from '@/components/SBOMoveModal/SBO.Move.Modal';
import {TFolder} from '@/types/Folder.t';

import AskForModal from './AskForModal';

export default function AskForFolder({folder} :{ folder?: TFolder}): Promise<object> {
    return new Promise<object>((resolve) => {
        AskForModal(SBOMoveModal, {onSave: resolve, folder});
    });
}
