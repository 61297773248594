import {faAdd, faTrash} from '@fortawesome/pro-regular-svg-icons';

import AskForMenu from '@/asks/AskForMenu';

export const HandleFileContextMenu = (event: React.MouseEvent, deleteFile) => {
    AskForMenu(event, [
        {
            key: 'remove',
            label: 'Remove File',
            icon: faTrash,
            onClick: deleteFile,

        },
    ]);
};

export const HandleSwiperContextMenus = (event: React.MouseEvent, selectFiles) => {
    AskForMenu(event, [
        {
            key: 'add_new',
            label: 'Add New File',
            icon: faAdd,
            onClick: selectFiles,

        },
    ]);
};

