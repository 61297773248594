
import {Cell, Row} from '@tanstack/react-table';

import DateCellView from '@/components/Fields/Datetime.Date/Date.Cell.View';

export const CellDate = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => (
            <DateCellView
                timestamp={row.getValue(cell.column.id)}
            />
        ),
    };
};
