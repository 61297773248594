import {TSpreadsheetProps} from '@aktek/f4kit';
import {XFieldTypes} from '@aktek/types';

// Automatic
import AutomaticCellEdit from '@/components/Fields/Automatic/Automatic.Cell.Edit';
import AutomaticCellView from '@/components/Fields/Automatic/Automatic.Cell.View';
// Boolean
import BooleanCellEdit from '@/components/Fields/Boolean/Boolean.Cell.Edit';
import BooleanCellView from '@/components/Fields/Boolean/Boolean.Cell.View';
// ClosedList
import ClosedListCellEdit from '@/components/Fields/ClosedList/ClosedList.Cell.Edit';
import ClosedListCellView from '@/components/Fields/ClosedList/ClosedList.Cell.View';
// Color
import ColorCellEdit from '@/components/Fields/Color/Color.Cell.Edit';
import ColorCellView from '@/components/Fields/Color/Color.Cell.View';
// Date
import DateCellEdit from '@/components/Fields/Datetime.Date/Date.Cell.Edit';
import DateCellView from '@/components/Fields/Datetime.Date/Date.Cell.View';
// Time
import TimeCellEdit from '@/components/Fields/Datetime.Time/Time.Cell.Edit';
import TimeCellView from '@/components/Fields/Datetime.Time/Time.Cell.View';
// Datetime
import DatetimeCellEdit from '@/components/Fields/Datetime/Cell/Datetime.Cell.Edit';
import DatetimeCellView from '@/components/Fields/Datetime/Cell/Datetime.Cell.View';
// Float
import FloatCellEdit from '@/components/Fields/Float/Float.Cell.Edit';
import FloatCellView from '@/components/Fields/Float/Float.Cell.View';
// Formula
import FormulaCellEdit from '@/components/Fields/Formula/Formula.Cell.Edit';
import FormulaCellView from '@/components/Fields/Formula/Formula.Cell.View';
// Gps
import GpsCellEdit from '@/components/Fields/Gps/Gps.Cell.Edit';
import GpsCellView from '@/components/Fields/Gps/Gps.Cell.View';
// Import components for all field types
// Integer
import IntegerCellEdit from '@/components/Fields/Integer/Integer.Cell.Edit';
import IntegerCellView from '@/components/Fields/Integer/Integer.Cell.View';
// MediaAlbum
import MediaAlbumCellEdit from '@/components/Fields/MediaAlbum/MediaAlbum.Cell.Edit';
import MediaAlbumCellView from '@/components/Fields/MediaAlbum/MediaAlbum.Cell.View';
// MediaAudio
import MediaAudioCellEdit from '@/components/Fields/MediaAudio/MediaAudio.Cell.Edit';
import MediaAudioCellView from '@/components/Fields/MediaAudio/MediaAudio.Cell.View';
// MediaGeneric
import MediaGenericCellEdit from '@/components/Fields/MediaGeneric/MediaGeneric.Cell.Edit';
import MediaGenericCellView from '@/components/Fields/MediaGeneric/MediaGeneric.Cell.View';
// MediaImage
import MediaImageCellEdit from '@/components/Fields/MediaImage/MediaImage.Cell.Edit';
import MediaImageCellView from '@/components/Fields/MediaImage/MediaImage.Cell.View';
// MediaVideo
import MediaVideoCellEdit from '@/components/Fields/MediaVideo/MediaVideo.Cell.Edit';
import MediaVideoCellView from '@/components/Fields/MediaVideo/MediaVideo.Cell.View';
// Meta
import MetaCellEdit from '@/components/Fields/Meta/Meta.Cell.Edit';
import MetaCellView from '@/components/Fields/Meta/Meta.Cell.View';
// RefAnnex
import RefAnnexCellEdit from '@/components/Fields/Ref.Annex/RefAnnex.Cell.Edit';
import RefAnnexCellView from '@/components/Fields/Ref.Annex/RefAnnex.Cell.View';
// ConnectedRef
import ConnectedRefCellEdit from '@/components/Fields/Ref.ConnectedRef/ConnectedRef.Cell.Edit';
import ConnectedRefCellView from '@/components/Fields/Ref.ConnectedRef/ConnectedRef.Cell.View';
// RefList
import RefListCellEdit from '@/components/Fields/Ref.List/RefList.Cell.Edit';
import RefListCellView from '@/components/Fields/Ref.List/RefList.Cell.View';
// RefListAnnex
import RefListAnnexCellEdit from '@/components/Fields/Ref.ListAnnex/RefListAnnex.Cell.Edit';
import RefListAnnexCellView from '@/components/Fields/Ref.ListAnnex/RefListAnnex.Cell.View';
// Ref
import RefCellEdit from '@/components/Fields/Ref/Ref.Cell.Edit';
import RefCellView from '@/components/Fields/Ref/Ref.Cell.View';
// String
import StringCellEdit from '@/components/Fields/String/String.Cell.Edit';
import StringCellView from '@/components/Fields/String/String.Cell.View';
// Tags
import TagsCellEdit from '@/components/Fields/Tags/Tags.Cell.Edit';
import TagsCellView from '@/components/Fields/Tags/Tags.Cell.View';
import {TMediaFile} from '@/types/Media';

const renderCell: TSpreadsheetProps['cellRenderer'] = (props) => {
    const {type, isEditing, onChange, value} = props;
    const field = props?.column?.field || {};

    switch (type) {
        case XFieldTypes.String:
            if (isEditing) {
                return <StringCellEdit
                    onChange={onChange}
                    value={value}
                />;
            }

            return <StringCellView
                value={value}
            />;

        case XFieldTypes.Integer:
            if (isEditing) {
                return <IntegerCellEdit
                    onChange={onChange}
                    value={value}
                />;
            }

            return <IntegerCellView
                value={value}
            />;

        case XFieldTypes.Float:
            if (isEditing) {
                return <FloatCellEdit
                    onChange={onChange}
                    value={value}
                />;
            }

            return <FloatCellView value={value} />;

        // reference fields ------------------------------------------------------------------------------------
        case XFieldTypes.Ref:
            if (isEditing) {
                return <RefCellEdit
                    onChange={onChange}
                    value={value}
                    sboId={field.sboId}
                    fieldId={field.fieldId}
                />;
            }

            return <RefCellView
                value={value as string}
                sboId={field.sboId}
                fieldId={field.fieldId}
            />;

        case XFieldTypes.RefAnnex:
            if (isEditing) {
                return <RefAnnexCellEdit
                    sboId={field.sboId}
                    fieldId={field.fieldId}
                    value={value}
                />;
            }

            return <RefAnnexCellView
                sboId={field.sboId}
                fieldId={field.fieldId}
                value={value}
            />;

        case XFieldTypes.RefList:
            if (isEditing) {
                return <RefListCellEdit
                    values={value?.map?.((refValue) => refValue?._id) || []}
                    onChange={onChange}
                    sboId={field.sboId}
                    fieldId={field.fieldId}
                />;
            }

            return <RefListCellView
                values={value?.map?.((refValue) => refValue._id) as string[]}
                fieldId={field.fieldId}
                sboId={field.sboId}
            />;

        case XFieldTypes.RefListAnnex:
            if (isEditing) {
                return <RefListAnnexCellEdit
                    fieldId={field.fieldId}
                    sboId={field.sboId}
                    value={value}
                />;
            }

            return <RefListAnnexCellView
                fieldId={field.fieldId}
                sboId={field.sboId}
                value={value}
            />;

        case XFieldTypes.ConnectedRef:
            if ( isEditing) {
                return <ConnectedRefCellEdit
                    sboId={field.sboId}
                    fieldId={field.fieldId}
                    onChange={onChange}
                    value={value}
                />;
            }

            return <ConnectedRefCellView
                affecter={field?.affecter}
                sboId={field.sboId}
                fieldId={field.fieldId}
                value={value}
            />;

        // ----------------------------------------------------------------------------------------------------
        case XFieldTypes.Color:
            if ( isEditing) return <ColorCellEdit onChange={onChange} value={value} />;

            return <ColorCellView value={value} isSquare={false} />;

        case XFieldTypes.ClosedList:
            const options = column.field.options;
            const selectedLabel = options[value]?.label;

            if ( isEditing) {
                return <ClosedListCellEdit
                    onChange={onChange}
                    value={value}
                    options={options}
                />;
            }

            return <ClosedListCellView
                value={selectedLabel}
            />;

        case XFieldTypes.Boolean:
            if ( isEditing) return <BooleanCellEdit onChange={onChange} value={value} />;

            return <BooleanCellView value={value} />;

        case XFieldTypes.Gps:
            if ( isEditing) {
                return <GpsCellEdit
                    onChange={onChange}
                    lat={value?.lat}
                    lng={value?.lng}
                />;
            }

            return <GpsCellView
                lat={value?.lat}
                lng={value?.lng}
            />;

            // Media

        case XFieldTypes.MediaImage: {
            if (isEditing) {
                return <MediaImageCellEdit
                    files={value as TMediaFile[]}
                    onChange={onChange}
                    maximum={field?.maximum}
                    minimum={field?.minimum}
                />;
            }

            return <MediaImageCellView
                files={value as TMediaFile[]}
            />;
        }

        case XFieldTypes.MediaVideo: {
            if (isEditing) {
                return <MediaVideoCellEdit
                    onChange={onChange}
                    files={value as TMediaFile[]}
                    maximum={field?.maximum}
                    minimum={field?.minimum}
                />;
            }

            return <MediaVideoCellView
                files={value as TMediaFile[]}
            />;
        }

        case XFieldTypes.MediaAlbum:
            if (isEditing) {
                return <MediaAlbumCellEdit
                    onChange={onChange}
                    files={value as TMediaFile[]}
                    maximum={field?.maximum}
                    minimum={field?.minimum}
                />;
            }

            return <MediaAlbumCellView
                files={value as TMediaFile[]}
            />;

        case XFieldTypes.MediaGeneric:
            if (isEditing) {
                return <MediaGenericCellEdit
                    onChange={onChange}
                    files={value as TMediaFile[]}
                    maximum={field?.maximum}
                    minimum={field?.minimum}
                />;
            }

            return <MediaGenericCellView files={value as TMediaFile[]} />;

        case XFieldTypes.MediaAudio:
            if (isEditing) {
                return <MediaAudioCellEdit
                    onChange={onChange}
                    files={value as TMediaFile[]}
                    maximum={field?.maximum}
                    minimum={field?.minimum}
                />;
            }

            return <MediaAudioCellView
                files={value as TMediaFile[]}
            />;

        case XFieldTypes.Date:
            return isEditing ? <DateCellEdit onChange={onChange} value={value} />
                : <DateCellView timestamp={value} />;

        case XFieldTypes.Time:
            return isEditing ? <TimeCellEdit onChange={onChange} value={value} />
                : <TimeCellView value={value} />;

        case XFieldTypes.Datetime:
            return isEditing ? <DatetimeCellEdit onChange={onChange} value={value} />
                : <DatetimeCellView value={value} />;

            // case XFieldTypes.Multisubform:
            //     return isEditing ? <MultisubformCellEdit onChange={onChange} value={value} />
            //         : <MultisubformCellView value={value} />;

            // case XFieldTypes.Subform:
            //     return isEditing ? <SubformCellEdit onChange={onChange} value={value} />
            //         : <SubformCellView value={value} />;

        case XFieldTypes.Meta:
            if (isEditing) {
                return <MetaCellEdit
                    onChange={onChange}
                    value={value}
                    dynamicField={field.dynamicField}
                    isMulti={field.isMulti}
                />;
            }

            return <MetaCellView
                dynamicField={field.dynamicField}
                isMulti={field.isMulti}
                value={value}
            />;

        case XFieldTypes.Automatic:
            if (isEditing) {
                return <AutomaticCellEdit
                    value={value}
                    dynamicField={field.dynamicField}
                />;
            }

            return <AutomaticCellView
                value={value}
                dynamicField={field.dynamicField}
            />;

            // case XFieldTypes.Stopwatch:
            //     return isEditing ? <StopwatchCellEdit onChange={onChange} value={value} />
            //         : <StopwatchCellView value={value} />;

        case XFieldTypes.Formula:
            return isEditing ? <FormulaCellEdit onChange={onChange} value={value} />
                : <FormulaCellView value={value} />;

        case 'tags':
            return isEditing ? <TagsCellEdit onChange={onChange} value={value} />
                : <TagsCellView value={value} />;

        default:
            console.log('....not supported field type:', {value, column});

            return null;

    }
};

export default renderCell;

