import {XSBO} from '@aktek/types';

import {TColumn} from '@/components/Table/Types/Table.t';

export default function GetSBOColumns(): TColumn<XSBO>[] {
    return [
        {
            key: 'name',
            header: 'Name',
            type: 'string',
        },
        {
            key: 'folder',
            header: 'Folder',
            type: 'string',
        },
        {
            key: 'fieldsCount',
            header: 'Fields Count',
            type: 'string',
        },
        {
            key: 'fieldTypes',
            header: 'Fields Types',
            type: 'string',
        },
    ];
}
