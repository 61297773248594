import {RefFieldButton} from '@/components/RefFieldButton/RefFieldButton';

const RefAnnexCellView = ({value, sboId, fieldId}) => {
    if (!sboId || !fieldId) return null;

    return <RefFieldButton
        sboId={sboId}
        fieldId={fieldId}
        value={value}
    />;
};

export default RefAnnexCellView;
