import {FilesSwiper} from '@aktek/f4kit';

import {getResourceFileFromMediaFile} from '@/helpers/File';
import {TMediaFile} from '@/types/Media';

interface MediaImageCellViewProps {
    files: TMediaFile[];
}

const MediaImageCellView = ({files}: MediaImageCellViewProps) => {
    return <div className="w-64 h-16">
        <FilesSwiper
            defaultFileCategory="image"
            itemSideLength={50}
            objectFit="contain"
            files={files?.map(getResourceFileFromMediaFile)}
        />
    </div>;
};

export default MediaImageCellView;
