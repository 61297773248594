import {Companiesv4Service, CompanyLicenseService} from '@aktek/helios-frontend-sdk';
import _pick from 'lodash/pick';
import _sortBy from 'lodash/sortBy';

import {AddDays, DateToUnix} from '@/helpers/globalDates.fn';

export const StoreCompany = async (company, canAccessLicense, userCompanies, setUserCompanies) => {
    const updatedCompany = {
        ...company,
        accountManagers: company.accountManagers == null ? [] : company.accountManagers,

    };

    const res = await Companiesv4Service.store(updatedCompany);
    const data = res.getData();

    if (!res.isSuccessful?.()) return {isSuccessful: () => false, getData: () => {}};

    if (!canAccessLicense) return {isSuccessful: () => true, getData: () => {}};
    // Storing the new/edited company to the local storage/context so we can switch to it directly in the switch modal
    const targetCompanyData = res.getData();
    const idExists = userCompanies.some((company) => company?._id === targetCompanyData?._id);

    const newCompany = {
        ..._pick(targetCompanyData, ['_id', 'companyLogo', 'companyName', 'companyType']),
    };
    let updatedCompanies = [];

    if (idExists) {
        // replacing the existing company with the new info
        updatedCompanies = userCompanies.map((company) =>
            company._id === targetCompanyData._id
                ? {...newCompany, ancestors: company.ancestors}
                : company,
        );
    } else {
        updatedCompanies = [
            ...userCompanies,
            {
                ...newCompany,
                ancestors: [targetCompanyData?.parentCompanyId],
            },
        ];
    }

    const sortedCompanies = _sortBy(updatedCompanies, 'companyName');

    setUserCompanies(sortedCompanies);

    if (!company.license || !company.license.licensePlan) {
        return {
            isSuccessful: () => true,
            getData: () => targetCompanyData,
        };
    }

    let licenseData = company.license;

    if (company?.activation?.validity) {
        const today = new Date();
        licenseData = {
            ...company.license,
            issuedOn: DateToUnix(today),
            expiryDate: DateToUnix(AddDays(today, company?.activation?.validity)),
            validity: company?.activation?.validity,
            isNewLicense: true,
            isValid: !!company.license.verificationHash,
        };
    }

    const licenseRes = await CompanyLicenseService.setLicenseProgram(data?._id, licenseData);

    return {
        isSuccessful: () => licenseRes.isSuccessful?.(),
        getData: () => licenseRes.getData() || licenseData,
    };
};

