import {TConfig} from 'node_modules/@aktek/f4kit/dist/components/TierOne/Spreadsheet/Types/TConfig.t';

import {strings} from '@/localization/i18n';

import RefAddButton from '../components_temp/AddRefButton';
export const Config: TConfig = {
    Icons: {
        sortIcon: (sort, isTarget, onUp, onDown, onReset) => (
            <RefAddButton
                onDown={onDown}
                onUp={onUp}
                isDown={sort === 'down'}
                isTarget={isTarget}
                onReset={onReset}
            />
        ),
    },
    Messages: {
        isRequired: strings('spreadsheet_message_is_required'),
        prependRows: strings('spreadsheet_message_prepend_rows'),
        appendRows: strings('spreadsheet_message_append_rows'),
        warningRowsLimit: strings('spreadsheet_message_warning_rows_limit'),
        dangerRowsLimit: strings('spreadsheet_message_danger_rows_limit'),
        deleteNow: strings('spreadsheet_message_delete_now'),
        confirmationForDelete: strings('spreadsheet_confirmation_deleted_items'),
        spreadsheetLimitsMessage: strings('spreadsheet_limits_message'),
        areYouSureProceed: strings('spreadsheet_are_you_sure_proceed'),
    },
    Labels: {
        PREPEND_ROWS: strings('spreadsheet_label_prepend_rows'),
        APPEND_ROWS: strings('spreadsheet_label_append_rows'),
        SAVE: strings('spreadsheet_save'),
        ROWS: strings('spreadsheet_rows'),
        ID_COLUMN: 'ID',
        COUNT_NEW_ITEMS: strings('spreadsheet_count_new_items'),
        COUNT_MODIFIED_ITEMS: strings('spreadsheet_count_modified_items'),
        COUNT_DELETED_ITEMS: strings('spreadsheet_count_deleted_items'),
        DELETE: strings('spreadsheet_delete'),
        SPREADSHEET: strings('spreadsheet'),
        EDITING_CELL: strings('spreadsheet_editing'),
        EXIT: strings('spreadsheet_exit'),
        PROCEED: strings('spreadsheet_proceed'),
        CANCEL: strings('spreadsheet_cancel'),
        ERRORS: strings('error'),
    },
};

