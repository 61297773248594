import {F4FormElementDecorator, F4FormStep, useF4FormContext} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';
import {TF4DecoratedElementProps} from 'node_modules/@aktek/f4form/dist/Core/types/F4DecoratedElementProps.t';
import {useCallback, useMemo, useState} from 'react';

import AskForConfirmation from '@/asks/AskForConfirmation';
import {TMode} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import LicenseIssued from '@/components/LicenseComponents/LicenseIssued';
import {ConvertUnixToDate, DaysUntil} from '@/helpers/globalDates.fn';
import {strings} from '@/localization/i18n';

import {RevokeFunction} from '../helpers/Agency.RevokeFunction.fn';
import {TCompanyProgram} from './Agency.Create.Modal';
import NoLicenseNotice from './Agency.NoLicenseNotice';
import ValidityDatePicker from './Agency.ValidityDatePicker';

type TActivationInfo = TF4DecoratedElementProps & {
    mode: TMode;
    companyProgram: TCompanyProgram;
}

const ActivationInfo = ({mode, companyProgram}: TActivationInfo) => {
    const {data: companyInfo, setValue} = useF4FormContext();
    const [hasValidity, setHasValidity] = useState(false);

    const companyId = companyInfo?.['_id'];
    const pickedDateState = companyInfo?.['activation']?.validityDate;
    const isViewMode = mode == 'view';

    const isLicenseActive = useMemo(() => {
        const hasCompanyProgram = Object.keys(companyProgram).length > 0;
        const isProgramActive = companyProgram?.isActive;
        const isProgramValid = companyProgram?.isValid;

        return hasCompanyProgram && isProgramActive && isProgramValid;
    }, [companyProgram, companyInfo]);

    const hasSelectedPlan = useMemo(() => companyInfo?.['license']?.licensePlan, [companyInfo]);

    const onClickHandler = useCallback((timestamp) => {
        const daysRemaining = DaysUntil(timestamp);

        setValue('activation.validity', daysRemaining);
        setValue('activation.validityDate', ConvertUnixToDate(timestamp));
    }, []);

    const onClearField = useCallback(() => {
        setValue('activation.validity', null);
        setValue('activation.validityDate', null);
    }, []);

    const onRevoke = useCallback(() => {
        AskForConfirmation(
            strings('revoke_license'),
            strings('revoke_license_confirm'),
            {
                onConfirm: () => RevokeFunction(companyId, companyProgram, setValue),
            },
        );
    }, [companyId, companyProgram]);

    if (!hasSelectedPlan || (isViewMode && !companyInfo?.['license']?.validity)) return <NoLicenseNotice isViewMode={isViewMode} />;

    return (
        <F4FormStep step="activation" autoNest>
            <div className="overflow-y-auto">
                {!isViewMode && (
                    <div className="flex flex-col gap-2 pb-3">
                        <Label
                            text={strings('activation_description')}
                            color="neutral-600"
                            size="md"
                        />
                    </div>
                )}

                {(hasValidity || !companyInfo?.['license']?.expiryDate) && (
                    <ValidityDatePicker
                        onClick={onClickHandler}
                        pickedDateState={pickedDateState}
                        onClearField={onClearField}
                    />
                )}

                {companyProgram?.validity && companyInfo?.['license']?.expiryDate && (
                    <div className="flex items-center justify-center p-5">
                        <LicenseIssued
                            validity={isLicenseActive ? companyProgram.validity : 0}
                            issueDate={ConvertUnixToDate(companyProgram.issuedOn)}
                            expiryDate={ConvertUnixToDate(companyProgram.expiryDate)}
                            isActive={isLicenseActive}
                            mode={mode}
                            onRevoke={onRevoke}
                            onReissue={() => setHasValidity(true)}
                        />
                    </div>
                )}
            </div>
        </F4FormStep>
    );
};

const F4ActivationInfo = F4FormElementDecorator<TActivationInfo>(ActivationInfo);
export default F4ActivationInfo;
