import {MorphService} from '@aktek/morph-frontend-sdk';
import {XSDKResponse} from '@aktek/types';

import {TMorphObject} from '../types/TApis';

type TResponseData = {
    objects: TMorphObject[];
}

export const CreateNewMorphs = async (
    sboId: string,
    newIds: string[],
    modifications: Record<string, Partial<TMorphObject>>,
): Promise<TMorphObject[] | null> => {
    if (!newIds?.length) return [];

    const morphsToCreate = newIds.map((id) => {
        const morph: TMorphObject = {sboId, ...(modifications?.[id] || {})};
        delete morph._id;
        delete morph.rowId;

        return morph;
    }).reverse();
    const bulkCreateResponse = await MorphService.bulkstoreNewObjects(morphsToCreate, sboId) as XSDKResponse<TResponseData>;

    if (!bulkCreateResponse.isSuccessful()) {
        return null;
    }

    const newCreatedData = bulkCreateResponse.getData();

    return newCreatedData?.objects as TMorphObject[];
};
