import {LoadingIndicator, Spreadsheet, SpreadsheetRef, toast} from '@aktek/f4kit';
import {XSmartFilter} from '@aktek/types';
import React, {forwardRef, RefObject, useImperativeHandle, useRef} from 'react';

import {strings} from '@/localization/i18n';

import {CreateNewMorphs} from '../api/CreateNewMorphs';
import {DeleteRows} from '../api/DeleteRows';
import {SaveModifications} from '../api/SaveModifications';
import {Config} from '../config/MorphSpreadsheet.Config';
import ValidateBulkCell from '../helpers/validations/ValidateBulkCell';
import validateCell from '../helpers/validations/ValidateCell';
import useMorphSpreadsheet from '../hooks/useMorphSpreadsheet';
import transformPastedData from '../parsers/transformPastedData';
import renderCell from '../Render/RenderCell';

interface F4TableSpreadsheetProps {
    mode: 'creation' | 'edit';
    sboId: string;
    onSave?: () => void;
    filter?: XSmartFilter;
}

export type F4TableSpreadsheetRef = {
    save: () => Promise<boolean>;
}

export const useF4TableSpreadsheetRef = () => {
    return useRef<F4TableSpreadsheetRef>();
};

const F4TableSpreadsheet = ((props: F4TableSpreadsheetProps, ref: RefObject<F4TableSpreadsheetRef>) => {
    const isEditing = props.mode === 'edit';
    const state = useMorphSpreadsheet(props);
    const spreadsheetRef = React.useRef<SpreadsheetRef>(null);

    useImperativeHandle(ref, () => ({
        save: async () => {
            const newRowsCreated = await spreadsheetRef.current?.createNewRows();
            const changesSaved = await spreadsheetRef.current?.saveChanges();
            const rowsDeleted = await spreadsheetRef.current?.deleteRows();

            changesSaved && toast.success(strings('saved_successfully'));
            !changesSaved && toast.error(strings('failed_to_save'));

            return changesSaved;
        },
    }));

    if (state.isPageLoading) {
        return (
            <div className="flex items-center justify-center p-10">
                <LoadingIndicator />
            </div>
        );
    }

    if (isEditing && state.isEmpty) {
        return null;
    }

    if (!state.dataState.columns?.length) {
        return null;
    }

    return <Spreadsheet alertMode hideRibbonBar
        ref={spreadsheetRef}
        topBarRightAdditionalView={<div>Right</div>}
        idColumn="_id"
        allowAddingNewRecords={props.mode === 'creation'}
        initialCount={props.mode === 'creation' ? 1 : 0}
        sorting={state.sorting}
        setSorting={state.setSorting}
        deleteRows={(ids) => DeleteRows(props.sboId, ids)}
        onSave={props.onSave}
        confirm={async (title, message) => {
            console.log('....F4TableSpreadsheet.tsx__53__: confirm', {title, message}, '\n_________');

            return true;
        }}
        aSyncCellValidation={async (cellInfo) => {
            const result = await validateCell(cellInfo);
            console.log('....F4TableSpreadsheet.tsx__41__: ', {result}, '\n_________');

            return result;
        }}
        aSyncBulkCellValidation={async (cells) => {
            const result = ValidateBulkCell(cells);
            console.log('....F4TableSpreadsheet.tsx__57__: ', {result}, '\n_________');

            return result;
        }}
        onChange={(tableState) => {
            state.setTableState(tableState);
        }}
        columns={state.dataState.columns}
        pastedDataTransformer={(rowIndex, colIndex, pastedValue) => {
            const column = state.dataState.columns[colIndex];
            const field = column.field;

            return transformPastedData(field, pastedValue);
        }}
        defaultData={state.dataState.dataByKey[state.key]}
        cellRenderer={({colIndex, ...rest}) => {
            const column = state.dataState?.columns[colIndex];

            return renderCell({column, ...rest});
        }}
        customStyles={{
            thContainer: {whiteSpace: 'nowrap'},
        }}
        config={Config}
        createNewRows={(newIds, modifications) => CreateNewMorphs(props.sboId, newIds, modifications)}
        saveModifications={(modifications) => SaveModifications(props.sboId, modifications)}
    />;
});

export default forwardRef<F4TableSpreadsheetRef, F4TableSpreadsheetProps>(F4TableSpreadsheet);
