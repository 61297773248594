
import IOSelect from '@/iohub/IOSelect';

const MetaCellEdit = ({value, onChange, dynamicField, isMulti = {}}) => {
    const isUser = dynamicField == 'User';

    return <IOSelect
        type={isUser ? 'user': 'group'}
        isMulti={isMulti}
        value={value}
        onChange={(value) => {
            onChange(value);
        }}
    />;
};

export default MetaCellEdit;
