import {Icon, Image, Label} from '@aktek/f4kit';
import {faBuildings, faCheck} from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';

import AKTEKLogo from '@/assets/images/aktek_logo.png';
import Env from '@/config/env';

export const DisplayCompanies = ({companies, level = 0, rootCompanyId, currentCompany, onSwitchCompany}) => companies.map((company) => {
    const rootCompany = level == 0;
    const companyLogo = company?.companyLogo;
    const isRoot = company?._id === rootCompanyId;
    const isCurrentCompanySelected = company?._id === currentCompany?._id;

    return (
        <div
            key={company?._id}
            className={cn(
                !rootCompany && 'border-l border-neutral-300 pl-2.5 ml-8',
            )} >

            <div
                className={cn(
                    !isCurrentCompanySelected && 'cursor-pointer hover:bg-neutral-50 ',
                    'p-4 rounded-xl ml-0.5',
                    'transition-all duration-200 ease-in-out',
                    'flex items-center justify-between gap-0',
                    isCurrentCompanySelected && 'bg-primary-25',
                )}
                onClick={() => !isCurrentCompanySelected && onSwitchCompany(company?._id)} >

                <div className="flex flex-row items-center justify-between gap-2">

                    {isRoot && <Image alt="AKTEK Logo" src={AKTEKLogo}
                        className="rounded-full !w-10 !h-10 !content-stretch p-0.5  " />}

                    {!isRoot && (companyLogo
                        ? <Image src={Env.getFileURL(companyLogo)} alt="image logo"
                            // object-cover
                            className="!w-10 !h-10 !rounded-full !content-stretch" />
                        : <div className="rounded-full !w-10 !h-10 p-2.5 bg-neutral-100">
                            <Icon icon={faBuildings} size="xl" color="neutral-600" className="w-full h-full" />
                        </div>)}

                    <Label text={company.companyName} className={cn(!isCurrentCompanySelected && 'cursor-pointer')} color="neutral-900" size="sm" />
                </div>

                {isCurrentCompanySelected && <Icon icon={faCheck} color="primary-600"/>}
            </div>

            {company.children?.length > 0
            && <DisplayCompanies
                companies={company.children}
                level={level + 1}
                currentCompany={currentCompany}
                onSwitchCompany={onSwitchCompany}
                rootCompanyId={rootCompanyId}
            /> }
        </div>
    );
});
