/* eslint-disable max-len */
import React from 'react';
import {uuid} from 'short-uuid';

import {DOMInjectionManager} from '@/context/DOMInjectionManager';

type TEmptyFn = () => void;

type TResolve<T> = (input?: T) => void;
type TReject = TEmptyFn
type TRemove = TEmptyFn

type InjectAsyncElementArgs<T> = {
    getElementFn: (resolve: TResolve<T>, reject?: TReject, remove?: TRemove) => React.ReactNode;
    then: TEmptyFn;
}

/**
 * Injects an Element in the body of the document and returns a promise to control it.
 * @param {function} getElementFn A function of the format (resolve, reject, remove) => element. Resolve/Reject are for the promise. Remove() removes the injected Div.
 * @param {function}  then An optional function that would be executed after the element if added to the DOM.
 * @return {null} Nothing
 * @example  return InjectAsyncElement((resolve, reject, remove) => <span> Thank you ! </span> , ()=>{alert('Thanked!')});
 * */
export default function InjectAsyncElement<T>(getElementFn: InjectAsyncElementArgs<T>['getElementFn'], then?: InjectAsyncElementArgs<T>['then']) {
    if (!getElementFn) return;
    const key = uuid();

    return new Promise((resolve, reject) => {
        function remove() {
            DOMInjectionManager.ref.removeInjectedComponent(key);
        }

        const injectedElement = getElementFn(resolve, reject, remove);
        DOMInjectionManager.ref.addInjectedComponent(key, injectedElement);

        then?.();
    });
}
