import RefCellView from '../Ref/Ref.Cell.View';

const RefListCellView = ({values, labels, sboId, fieldId}) => {
    if (!sboId || (!labels && !fieldId)) return null;

    return <div className="flex gap-1">
        {values?.map?.((refValue, index) => <RefCellView
            key={refValue + '-' + index}
            sboId={sboId}
            fieldId={fieldId}
            value={refValue}
            label={labels?.[index]}
        />)}
    </div>;
};

export default RefListCellView;
