import {TBaseTableRef, usePagination} from '@aktek/f4kit';
import React, {forwardRef, Ref, useImperativeHandle, useRef} from 'react';

import useColumns from './Hooks/useColumns';
import useCount from './Hooks/useCount';
import useRows from './Hooks/useRows';
import useSearch from './Hooks/useSearch';
import useSort from './Hooks/useSort';
import StatelessTable from './StatelessTable';
import {TTableProps} from './Types/Table.t';
import {TTableState} from './Types/Table.t';
import {TTableController, TTableRef} from './Types/TableRef.t';

function Table <RowType = unknown>(
    {noPagination,
        isLoading,
        ribbonActions,
        onRibbonAction,
        onRowAction, rows, getRows,
        columns, getColumns,
        getRowsCount, rowsCount,
        emptyProps,
        events,
        defaults = {},
        name,
        className,
        baseTableClasses,
        onEmptyStateClick,
        rowActions,
        ribbonProps,
        children,
    }:TTableProps<RowType>, ref: TTableRef) {
    const countState = useCount({getRowsCount, rowsCount});
    const sortState = useSort(defaults.sortingState);
    const searchState = useSearch();

    const paginationState = usePagination({defaultPageSize: defaults.pageSize || 20, itemsCount: countState.count});

    const rowsState = useRows({rows, paginationState, getRows, sortingState: sortState.sortingState, countState, searchState});

    const columnState = useColumns({columns, getColumns, paginationState});

    const tableState = React.useMemo<TTableState>(() => ({
        countState,
        paginationState,
        rowsState,
        columnState,
        sortState,
        searchState,
    }), [countState, paginationState, rowsState.rows, columnState, sortState, searchState]);

    const baseTableRef = useRef<TBaseTableRef>();
    const modalColumnsVisibilityRef = {open: null};
    const refresh = () => tableState.rowsState.fetchRows();
    const editColumnsVisibility = () => modalColumnsVisibilityRef?.open?.();

    useImperativeHandle(ref, () => ({
        state: tableState,
        baseTableRef,
        editColumnsVisibility,
        refresh}
    ), [tableState]);

    return (
        <StatelessTable<RowType>
            baseTableClasses={baseTableClasses}
            className={className}
            name={name}
            onRibbonAction={onRibbonAction}
            modalColumnsVisibilityRef={modalColumnsVisibilityRef}
            baseTableRef={baseTableRef}
            events={events}
            emptyProps={emptyProps}
            onEmptyStateClick={onEmptyStateClick}
            onRowAction={onRowAction}
            state={tableState}
            searchState={searchState}
            ribbonActions={ribbonActions}
            isLoading={isLoading || rowsState.isRowLoading || columnState.isColumnsLoading}
            rows={rowsState.rows}
            count={countState.count}
            columns={columnState.columns}
            pagination={!noPagination && paginationState}
            sorting={sortState}
            rowActions={rowActions}
            ribbonProps={ribbonProps}
        >
            {children}
        </StatelessTable>
    );
}

export default forwardRef(Table) as <T>(
    props: TTableProps<T> & { ref?: Ref<TTableController> }
  ) => React.ReactElement;
