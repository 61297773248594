import {Label} from '@aktek/f4kit';

import TagsRecordEdit from '@/components/Fields/Tags/Tags.Record.Edit';

const RecordTags = ({hasRecordsTags, required}) => {
    if (!hasRecordsTags) return null;

    return (
        <div className="flex flex-row justify-between items-center my-6">
            <Label text="Tags" className="w-1/4" fontWeight="medium" color="neutral-400" />
            <div className="w-3/4">
                <TagsRecordEdit name="tags.tags" required={required} />
            </div>
        </div>
    );
};

export default RecordTags;
