import {TFile, TFileResource} from '@aktek/f4kit';
import axios, {AxiosResponse} from 'axios';

import Env from '@/config/env';

type UploadFileResponse = {
    resourceFile?: TFileResource;
    response: AxiosResponse;
};

type UploadMultipleFilesResult = {
    resourceFile?: TFileResource;
    file: TFile;
    status: 'success' | 'fail';
    response?: AxiosResponse;
    error?: string;
};

export const uploadFileToURL = async (file: TFile, url: string): Promise<AxiosResponse> => {
    const options = prepareUploadOptions(file);

    return axios.put(url, file, options);
};

export const uploadFileToNewURL = async (file: TFile): Promise<UploadFileResponse> => {
    const {url, fileName} = Env.getNewEmptyFileURL();
    const res = await uploadFileToURL(file, url);

    return {resourceFile: {name: fileName, url, originalFile: file}, response: res};
};

;

export const uploadMultipleFilesToNewURLs = async (files: TFile[]): Promise<UploadMultipleFilesResult[]> => {
    const results: UploadMultipleFilesResult[] = [];

    for (const file of files) {
        try {
            const res = await uploadFileToNewURL(file);
            results.push({file, status: 'success', ...res});
        } catch (error) {
            results.push({file, status: 'fail', error: (error as Error).message});
        }
    }

    return results;
};

const prepareUploadOptions = (file: TFile) => ({
    headers: {
        'Content-Type': file.type,
    },
});
