import {useF4FormContext} from '@aktek/f4form';
import {useMemo, useState} from 'react';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import SearchBox from '@/components/SearchBox/SearchBox';
import {LICENSE_FEATURES, LIMITS_FOR_METRICS} from '@/constants/LicenseFeatures.c';
import {StringInclude} from '@/helpers/strings.fn';
import {strings} from '@/localization/i18n';

import LicensingFormFeatures from './Licensing.Form.Features';
import LicensingFormLimits from './Licensing.Form.Limits';

const LicenseInfo = () => {
    const [search, setSearch] = useState('');

    const {filteredFeatures, filteredLimits} = useMemo(() =>({
        filteredFeatures: LICENSE_FEATURES.filter((item) => StringInclude(item.name, search)),
        filteredLimits: LIMITS_FOR_METRICS.filter((item) => StringInclude(item.name, search)),
    }), [search]);

    const {data: licenseInfo} = useF4FormContext();

    const hasAmpWlc = useMemo(() => {
        const hasAmpWlcLimit = licenseInfo?.['limits']?.has_amp_wlc;
        const hasAmpWlcCustomization = licenseInfo?.['license']?.customizations?.limits?.has_amp_wlc;

        return hasAmpWlcLimit || hasAmpWlcCustomization;
    }, [licenseInfo]);

    return (

        <>
            <div className="!sticky -top-5 bg-white w-full elevation-1 !shadow-none">
                <F4Textbox type="text" name="plan" label={strings('plan_name')} required autoFocus />

                <SearchBox
                    placeholder={strings('search_features_limits')}
                    className="!bg-neutral-100 rounded-lg w-full my-4 !mx-0 !px-0"
                    textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                    hasBottomBorder={false}
                    value={search}
                    autoFocusOnOpen={false}
                    onSearch={(value) => setSearch(value.toLowerCase())}
                />
            </div>
            <div className="flex flex-col justify-between items-center relative">
                <LicensingFormFeatures features={filteredFeatures} />

                <div className="w-full h-px bg-neutral-200 mx-auto my-4" />

                <LicensingFormLimits hasAmpWlc={hasAmpWlc} limits={filteredLimits} />
            </div>
        </>

    );
};

export default LicenseInfo;
