import {useMemo} from 'react';

import {TRecordField} from '@/app/DataTable/types/Record.Types';
import FreeTextIcon from '@/assets/freeTextFieldType.png';
import F4Textbox from '@/components/F4Elements/F4TextBox';

import FieldBase from '../components/Field.Base';
import {StringClasses} from './styles/Strings.styles';

type TStringRecordEdit = {
    field:TRecordField;
    onChange?: (data) => void;
    value?:string;
    layout:boolean;
}

const StringRecordEdit = (props:TStringRecordEdit) => {
    const {field, onChange, layout} = props;
    const {id, showDescription, description, isRequired} = field;

    const classes = StringClasses(layout);

    const name = useMemo(() => {
        if (!isRequired) return field.name;

        return `${field.name}*`;
    }, [field.name, isRequired]);

    return <div className={classes.container}>
        <FieldBase
            name={name}
            description={description}
            showDescription={showDescription}
            image={FreeTextIcon}
            classes={classes}
        />

        <F4Textbox
            isGhost
            // isMultiLine
            placeholder=""
            inputClassName="!text-base"
            name={id}
            onChange={onChange}
            required={isRequired}
            className={classes.textbox}
        />
    </div>;
};

// name={`fieldsMap.${props.field.id}.name`}
export default StringRecordEdit;
