import {F4FormDiv, F4Nestor} from '@aktek/f4form';

import {LICENSE_FEATURES, LIMITS_FOR_METRICS} from '@/constants/LicenseFeatures.c';

import LicensingFormFeatures from '../../Licensing/components/Licensing.Form.Features';
import LicensingFormLimits from '../../Licensing/components/Licensing.Form.Limits';

export const AgencyCustomizations = ({hasAmpWlc, hasCustomizations, isViewMode}) => {
    if (!hasCustomizations) return null;

    return (
        <>
            <F4Nestor name="customizations">
                <F4FormDiv className="flex flex-col justify-between items-center" >

                    <LicensingFormFeatures features={LICENSE_FEATURES} isViewMode={isViewMode} />

                    <div className="w-full h-px bg-neutral-200 mx-auto my-4" />

                    <LicensingFormLimits limits={LIMITS_FOR_METRICS} hasAmpWlc={hasAmpWlc} isViewMode={isViewMode} />

                </F4FormDiv>
            </F4Nestor>
        </>

    );
};

export default AgencyCustomizations;
