
import {F4FormElementDecorator} from '@aktek/f4form';
import {Button, TButtonProps} from '@aktek/f4kit';
import classNames from 'classnames';

type TToggleButtonProps = {
    onChange?: (value: boolean) => void
    value?: boolean
    text: string

} & TButtonProps

const ToggleButton = ({onChange, value=false, text, ...props}: TToggleButtonProps) => {
    const finalValue = value ?? false;

    return <Button {...props}
        label={text}
        onClick={() => onChange?.(!finalValue)}
        className={classNames(finalValue ? 'bg-primary-25' : 'bg-neutral-50', props.className)}
        textColor={finalValue ? 'primary-600' : 'neutral-700'} />;
};

const F4ToggleButton = F4FormElementDecorator<TToggleButtonProps>(ToggleButton);
export default F4ToggleButton;
