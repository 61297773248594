import {FileViewer, Modal} from '@aktek/f4kit';
import {forwardRef} from 'react';

import AskForModal from '@/asks/AskForModal';
import {strings} from '@/localization/i18n';

export const HandleFileDoubleClick = (event, file) => {
    console.log('....HandleFileDoubleClick.tsx -> Line 7', {});
    AskForModal(FileFullScreenRef, {file});
};

const FileFullScreen = ({file}, ref) => {
    return <div>
        <Modal ref={ref} size="xl" title={''}
            className={'overflow-hidden !rounded-3xl h-5/6 !w-3/5'}
            headerClassName="border-b border-b-neutral-100 px-5 pt-2 pb-1 text-xl " >

            <FileViewer
                containerClassName=""
                fileName={file.name}
                fileCategory={file.fileCategory}
                url={file.url}
            />
        </Modal>
    </div>;
};

const FileFullScreenRef = forwardRef(FileFullScreen);

