import {ContentDivider, Label} from '@aktek/f4kit';

const RecordSectionHeader = ({name}:{name:string}) => {
    return <ContentDivider
        backgroundColor="neutral-50"
        className="mb-6"
        middleChild={

            <Label
                text={name}
                color="neutral-600"
                size="sm"
                fontWeight="medium"
            />
        }
    />;
};

export default RecordSectionHeader;
