import {Cell, Row} from '@tanstack/react-table';

import {RefFieldButton} from '@/components/RefFieldButton/RefFieldButton';

export const CellSpecialList = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const labels = row.getValue(cell.column.id) as string[];
            const ids = row.original[cell.column.id + '_id'];
            const sboId = columnDef?.sboId;

            if (sboId) {// it means it is a ref
                return <div className="flex gap-1">
                    {labels?.map((label, index) => <RefFieldButton
                        key={index + '-' +label}
                        value={ids?.[index]}
                        sboId={sboId}
                        label={label + ''}

                    />)}
                </div>;
            }

            return <div>--{JSON.stringify({labels, ids: ids})}--</div>;
        },
    };
};
