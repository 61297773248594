import {MorphService} from '@aktek/morph-frontend-sdk';
import {XSDKResponse} from '@aktek/types';

type TableMetadata ={
    count: number;
    [key: string]: unknown;
}

type TableResponse ={
    data: object[];
    metadata: TableMetadata[]
}

type TResponse ={
    data: object[];
    count: number
}

export const FetchRows = async (
    sboId: string,
    sorting: { column: string; order: 'up' | 'down' } | null,
    filter: Record<string, unknown> | null,
    page: number,
    pageLength: number,
    search: string | null,
): Promise<TResponse> => {
    const sort ={id: sorting?.column || 'designation', order: sorting?.order == 'up' ? 1 : -1};

    const res = await MorphService.retrieveRawMorphsBySBOId(
        sboId,
        null,
        search,
        sort,
        pageLength * (page - 1),
        pageLength,
        filter,
        true,
    ) as XSDKResponse<TableResponse>;

    if (!res.isSuccessful()) {
        return {data: [], count: 0};
    }

    const tableData = res.getData();
    const data = tableData.data;

    const count = tableData?.metadata?.[0]?.count || 0;

    return {data, count};
};
