import '@aktek/f4kit/dist/style.css';
import '@/index.css';
import '@/utils/storage/exports';

import {ContextMenu, Toast, useContextMenuRef} from '@aktek/f4kit';
import React from 'react';
import {createRoot} from 'react-dom/client';

import App from '@/App.tsx';

import Env from './config/env';
import GlobalContextProviders from './context/Global.Provider';
import DevBox from './DevBox';
import XApplyTheme from './helpers/ApplyTheme.fn';
import {ContextMenuManager} from './utils/ContextMenuManager';

const Main = () => {
    // apply f3theme to the html after getting the saved theme for the user
    XApplyTheme(ls.theme);
    ContextMenuManager.ref = useContextMenuRef();

    return (
        <React.StrictMode>
            <GlobalContextProviders>
                {Env.hasDevMode && <DevBox />}
                <ContextMenu ref={ContextMenuManager.ref} entries={[]} className="!max-h-[450px] !overflow-y-auto" matchHoverColor/>
                <Toast />
                <App />
            </GlobalContextProviders>
        </React.StrictMode>
    );
};

const root = document.getElementById('root')!;

createRoot(root).render(<Main />);
