import {F4Form} from '@aktek/f4form';
import {LoadingIndicator} from '@aktek/f4kit';
import _isEmpty from 'lodash/isEmpty';

import useF4CurrentTab from '@/app/Home/hooks/useF4CurrentTab';

import RecordFooter from './components/Record.Footer';
import RecordHeader from './components/Record.Header';
import RecordSection from './components/Record.Section';
import RecordTags from './components/Record.Tags';
import useRecordEditor from './hooks/useRecordEditor';

 type TRecordEditorTab = {
        props: {
            sboId: string
        },
        onSave: () => void,
        layout: boolean
    };

const RecordEditor = () => {
    const currentTab = useF4CurrentTab<TRecordEditorTab>();
    const {props, onSave, layout} = currentTab.getTabState();

    const {loading, sbo, handleCancel, handleSave, handleSaveAndFillAnother} = useRecordEditor(props.sboId, onSave);
    const {sections, fieldsMap, hasRecordTags, sectionsMap, name, description, areRecordTagsRequired} = sbo;

    if (loading && _isEmpty(sbo)) {
        return <div className="bg-neutral-50 w-full h-full overflow-y-auto flex items-center justify-center">
            <LoadingIndicator size="xl" />
        </div>;
    }

    return (
        <div className="bg-neutral-50 w-full h-full overflow-y-auto">
            <F4Form
                formKey="single-record"
                className="h-full bg-neutral-50"
                isLoading={loading}
                onEditStatusChange={(status) => {
                    currentTab.updateConfig({hasUnsavedChanges: status});
                }}
            >
                <div className="px-32 pt-14">
                    <RecordHeader
                        title={name}
                        layout={layout}
                        onLayoutChange={(value) => currentTab.setTabState({layout: value})}
                        description={description} />

                    <RecordTags
                        hasRecordsTags={hasRecordTags}
                        required={areRecordTagsRequired} />
                    <div className="flex flex-col gap-2">

                        {sections?.map((sectionId) => (
                            <RecordSection
                                key={sectionId}
                                layout={layout}
                                section={sectionsMap?.[sectionId]}
                                fieldsMap={fieldsMap} />
                        ))}
                    </div>
                </div>
                <RecordFooter
                    onCancel={handleCancel}
                    onSave={handleSave}
                    onSaveAndFillAnother={handleSaveAndFillAnother} />
            </F4Form>
        </div>
    );
};

export default RecordEditor;
