import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

export default function GetDeleteSectionUpdateObject(data: XSBO, sectionId: string) {
    const {tableFields, sections, sectionsMap} = data;

    const section = sectionsMap[sectionId];
    const {fields} = section;

    const targetSectionIndex = sections.indexOf(sectionId);

    const updateObject: TUpdateObject<XSBO> = {
        [`sectionsMap.${sectionId}`]: UpdateActions.DELETE,
        [`sections.${targetSectionIndex}`]: UpdateActions.DELETE,
        'tableFields': tableFields.filter((id) => !fields.includes(id)),
    };

    for (const fieldId of fields) {
        updateObject[`fieldsMap.${fieldId}`] = UpdateActions.DELETE;
    }

    return updateObject;
}

