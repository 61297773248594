import {F4FormStep, useF4FormContext} from '@aktek/f4form';
import {UserRolesService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import {TMode} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4UserSelector from '@/components/F4Elements/F4UserSelector';

type TUsersAssigned = {
    mode: TMode
}

export function UsersAssigned(props: TUsersAssigned) {
    const {mode} = props;

    const f4FormContext = useF4FormContext();
    const roleId = f4FormContext.data?.['id'];

    const [initialState, setInitialState] = useState<unknown>();

    const fetchSelectedUsers = async () => {
        const res = await UserRolesService.getUsersByRole(roleId as string);
        const data = res.getData();
        !initialState && setInitialState(data);

        return res;
    };

    useEffect(() => {
        if (roleId) {
            fetchSelectedUsers();
        }
    }, [roleId]);

    return (
        <F4FormStep step={'assignedUsers'}>
            <F4UserSelector name="users"
                state={initialState}
                getFunction={mode == 'edit' && fetchSelectedUsers}
            />
        </F4FormStep>
    );
}

export default UsersAssigned;
