import {TF4ThemeColors} from '@aktek/f3theme';
import {TContextMenuEntry} from '@aktek/f4kit';
import {faT} from '@fortawesome/free-solid-svg-icons';
import {fa00, faAlbumCollection, faCalendar, faCalendarClock, faClock, faEarthAmericas, faFileLines, faFillDrip, faImage, faMap, faOutdent, faSquare3, faToggleOn, faVideo, IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import {TField, TSBOActions, TSection} from '../types/SBOEditor.t';

export const fieldTypesDictionary: Record<string, Partial<TField> & {color: TF4ThemeColors, icon: IconDefinition}> = {
    string: {
        type: 'string',
        label: 'Text',
        icon: faT,
        color: 'success-600',
    },
    boolean: {
        type: 'boolean',
        label: 'Yes/No',
        icon: faToggleOn,
        color: 'semantic2-600',
    },
    integer: {
        type: 'integer',
        label: 'Integer',
        icon: faSquare3,
        color: 'semantic3-600',
        showThousandsSeparator: true,
    },
    decimal: {
        type: 'decimal',
        label: 'Decimal',
        icon: fa00,
        color: 'error-600',
        showThousandsSeparator: true,
    },
    date: {
        type: 'date',
        label: 'Date',
        icon: faCalendar,
        color: 'neutral-600',
    },
    time: {
        type: 'time',
        label: 'Time',
        icon: faClock,
        color: 'error-600',
    },
    dateTime: {
        type: 'dateTime',
        label: 'Date & Time',
        icon: faCalendarClock,
        color: 'primary-600',
    },
    gps: {
        type: 'gps',
        label: 'GPS',
        icon: faEarthAmericas,
        color: 'warning-600',
    },
    color: {
        type: 'color',
        label: 'Color',
        icon: faFillDrip,
        color: 'semantic2-600',
    },
    geoRepository: {
        type: 'geoRepository',
        label: 'Geo Repository',
        icon: faMap,
        color: 'warning-600',
    },
    genericFile: {
        type: 'genericFile',
        label: 'Generic File',
        icon: faFileLines,
        color: 'semantic1-600',
    },
    image: {
        type: 'image',
        label: 'Image',
        icon: faImage,
        color: 'neutral-600',
    },
    video: {
        type: 'video',
        label: 'Video',
        icon: faVideo,
        color: 'semantic3-600',
    },
    album: {
        type: 'album',
        label: 'Album',
        icon: faAlbumCollection,
        color: 'warning-600',
    },
};

export default function GetSectionActionsContextMenuEntries(section: TSection, sboActions: TSBOActions): TContextMenuEntry[] {
    const result: TContextMenuEntry[] = [];
    const {id: sectionId} = section;
    result.push({
        key: 'Section',
        size: 'sm',
        label: 'Section',
        labelProps: {color: 'neutral-500'},
    });

    result.push({
        key: 'newSection',
        icon: faOutdent,
        label: 'New section',
        size: 'sm',
        labelProps: {fontWeight: 'medium', color: 'neutral-900'},
        iconProps: {
            size: 'sm',
        },
        className: 'hover:!bg-neutral-100 mb-2 rounded-md',
        onClick: () => sboActions.addSection(),
    });

    result.push('divider');

    result.push({
        key: 'basic',
        size: 'sm',
        label: 'Basic',
        labelProps: {color: 'neutral-500'},
        className: 'mt-2',

    });

    Object.entries(fieldTypesDictionary).forEach(([type, field]) => {
        result.push({
            key: type,
            label: field.label,
            icon: field.icon,
            labelProps: {fontWeight: 'medium', color: 'neutral-900'},
            size: 'sm',
            iconProps: {color: field.color},
            className: 'hover:!bg-neutral-100 rounded-md',
            onClick: () => sboActions.addField(field, sectionId),
        });
    });

    return result;
}
