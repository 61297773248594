
import {FileTypeExtensions} from '@aktek/f4kit';

import FilesSwiperUpload from '@/components/Swipers/FilesSwiperUpload';

const MediaAlbumCellEdit = ({files = [], onChange, maximum, minimum}) => {
    return <FilesSwiperUpload isEditMode
        fileCategory={'unknown'}
        maximum={maximum}
        minimum={minimum}
        extensions={[
            ...FileTypeExtensions.video,
            ...FileTypeExtensions.image,
        ]}
        mediaFiles={files}
        onChange={(files) => onChange(files)}
        onNewFiles={(mediaFiles) => {
            onChange([...files, ...mediaFiles]);
        }}
    />;
};

export default MediaAlbumCellEdit;
