import {useF4FormContext} from '@aktek/f4form';
import {TF4FormContextValue} from '@aktek/f4form';
import {Button} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

import {GetSingleRecordClasses} from '../styles/Record.Editor.styles';

type TRecordFooterProps = {
    onSave?: (context: TF4FormContextValue, closeTab?: boolean) => void;
    onSaveAndFillAnother?: (context: TF4FormContextValue) => void;
    onSwitchToBulkEntry?:() => void;
    onCancel?: (isEdited: TF4FormContextValue['isEdited']) => void;
}

const RecordFooter = (props: TRecordFooterProps) => {
    const f4FormContext = useF4FormContext();

    const {onSave, onSaveAndFillAnother, onCancel, onSwitchToBulkEntry} = props;

    const handleCancelClick = () => onCancel(f4FormContext.isEdited);
    const handleSwitchToBulkEntry = () => onSwitchToBulkEntry();
    const handleSaveAndFillAnotherClick = () => onSaveAndFillAnother(f4FormContext);
    const handleSaveClick = () => onSave(f4FormContext, true);

    const classes = GetSingleRecordClasses();

    return (
        <div className={classes.footerContainer}>
            <Button
                isGhost
                size="sm"
                className="capitalize ml-1"
                onClick={handleCancelClick}
                textColor="neutral-600"
                label={strings('cancel')}
            />
            <Button
                size="sm"
                className="capitalize ml-1"
                textColor="neutral-700"
                variant="white"
                onClick={handleSwitchToBulkEntry}
                label={strings('switch_to_bulk_entry')}
            />
            <Button
                size="sm"
                className="capitalize ml-1"
                textColor="neutral-700"
                onClick={handleSaveAndFillAnotherClick}
                variant="white"
                label={strings('save_and_fill_another')}
            />
            <Button
                size="sm"
                onClick={handleSaveClick}
                className="capitalize ml-1"
                label={strings('save')}
            />
        </div>
    );
};

export default RecordFooter;
