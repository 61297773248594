/* eslint-disable camelcase */
import {toast} from '@aktek/f4kit';
import {TabsManager} from '@aktek/f4kit';
import {Authentication_TokensService} from '@aktek/helios-frontend-sdk';

import {strings} from '@/localization/i18n';

export const OnSwitchCompany = async (companyId: string, setIsLoading: (value: boolean) => void, onSignIn: (value: unknown) => void) => {
    setIsLoading(true);

    const res = await Authentication_TokensService.switchCompany(companyId);

    if (!res.isSuccessful?.()) {
        toast.error(strings('user_already_in_this_company'));

        return;
    }

    const data = res.getData();
    TabsManager.clear();
    onSignIn(data);

    ls.recentlyUsedSBO = undefined;
    setIsLoading(false);
    location.reload();
    ls.permissionSection = 'admin';
};
