
import {FileExtension} from '@aktek/f4kit';

import FilesSwiperUpload from '@/components/Swipers/FilesSwiperUpload';

const MediaGenericCellEdit = ({files = [], onChange, maximum, minimum}) => {
    return <FilesSwiperUpload isEditMode
        maximum={maximum}
        minimum={minimum}
        fileCategory={'unknown'}
        extensions={Object.values(FileExtension)}
        mediaFiles={files}
        onChange={(files) => onChange(files)}
        onNewFiles={(mediaFiles) => {
            onChange([...files, ...mediaFiles]);
        }}
    />;
};

export default MediaGenericCellEdit;
