import navigationPagesMap from '../Pages/Navigation.Pages.Map.c';
import {TNavigationSection} from '../types/Navigation.t';

export default function GetNavigationSections(navKey: string) : TNavigationSection[] {
    const fn = navigationPagesMap[navKey];

    if (typeof fn == 'function') {
        return fn();
    }
}

