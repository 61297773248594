
import {Cell, Row} from '@tanstack/react-table';

import IntegerCellView from '@/components/Fields/Integer/Integer.Cell.View';

export const CellNumber = () => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = row.getValue(cell.column.id);

            return <IntegerCellView value={value} />;
        },

    };
};
