import IntegerRecordEdit from '@/components/Fields/Integer/Integer.Record.Edit';
import StringRecordEdit from '@/components/Fields/String/String.Record.Edit';

import {TRecordField} from '../../DataTable/types/Record.Types';

export default function RecordField({field, layout} : {field: TRecordField, layout:boolean}) {
    if (!field) return null;

    const {type} = field;

    switch (type) {
        case ('string'): {
            return <StringRecordEdit field={field} layout={layout} />;
        }

        case ('integer'): {
            return <IntegerRecordEdit field={field} layout={layout} />;
        }

        case 'decimal':

        case 'float': {
            return <IntegerRecordEdit field={field} layout={layout} isDecimalField={true} />;
        }

        default: {
            return <StringRecordEdit field={field} layout={layout} />;
        }
    }
}
