import {Icon, Label} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';
import {faEye, faEyeSlash, faTag} from '@fortawesome/pro-regular-svg-icons';

import F4ToggleButton from '@/components/F4Elements/F4ToggleButton';
import {ToggleIcon} from '@/components/F4Elements/F4ToggleIcon';

type TSBOEditorRecordsTagsFieldProps = {
    currentSBO: XSBO
    toggleTagFieldTableVisibility: () => void;
}

export default function SBOEditorRecordsTagsField(props: TSBOEditorRecordsTagsFieldProps) {
    const {currentSBO, toggleTagFieldTableVisibility} = props;

    return (
        <div>
            <div className="flex">
                {currentSBO?.hasRecordTags
                && <div className="flex w-full justify-between bg-neutral-100 rounded-xl items-center p-4 gap-2" >
                    <div className="flex items-center gap-0">
                        <Icon icon={faTag} color="success-600" className="w-4 h-4"/>
                        <Label text="Record tags" className="ml-2"/>
                    </div>
                    <div className="flex items-center gap-3 float-end">
                        <F4ToggleButton isGhost text={'Required'} name="areRecordTagsRequired" className="text-sm" size="sm" />
                        <ToggleIcon
                            value={currentSBO.tableFields.includes('tags')}
                            onChange={() => toggleTagFieldTableVisibility()}
                            icons={{on: faEye, off: faEyeSlash}}
                            tooltip={{on: 'Hide in Data Table', off: 'Show from Data Table'}}
                            tooltipProps={{showDelay: 1000}}
                            colors={{on: 'neutral-600', off: 'neutral-600'}}
                            className="w-4 h-4"/>

                    </div>
                </div>
                }
            </div>
        </div>
    );
}
