import {faCircleNodes, faTable} from '@fortawesome/pro-regular-svg-icons';

import {TRecentFolders} from '@/hooks/useFilesAndFoldersState';
import {strings} from '@/localization/i18n';

import {FOLDERS_AND_DATA, RECENTLY_USED, REPOSITORIES, SHORTCUTS} from '../constants/constantIds.c';

export const HandleCreateSection = (id: string, labelKey: string, children = []) => ({
    id,
    label: strings(labelKey),
    // type: 'container',
    children,
    isNodeExpanded: ls.isExplorerSectionsOpen('explorer-' + id),
    labelProps: {className: '!font-semibold !text-neutral-900 !cursor-pointer'},
});

const FILE_TYPES = ['folder', 'report', 'shortcut'];

export const ExplorerComputeSections = (files, recentlyUsed?: TRecentFolders[]) => {
    if (!files?.length) return [];

    const sections = {
        recently_used: HandleCreateSection(RECENTLY_USED, 'recently_used'),
        folders_and_data: HandleCreateSection(FOLDERS_AND_DATA, 'folders_and_data'),
        shortcuts: HandleCreateSection(SHORTCUTS, 'shortcuts'),
        repository: HandleCreateSection(REPOSITORIES, 'repository'),
    };
    // note: this will be static for now until we build the geo section
    sections.repository.children = [{id: 'geo', label: 'Geo', icon: faCircleNodes, type: 'geo'}];

    files.forEach((file) => {
        if (!FILE_TYPES.includes(file.type)) return undefined;

        if (file.type == 'shortcut') return sections.shortcuts.children.push(file);

        sections.folders_and_data.children.push(file);
    });

    recentlyUsed?.forEach((item) => {
        sections.recently_used.children.push({...item as object, label: item.component, id: item._id, type: 'recent', icon: faTable});
    });

    return [
        sections.recently_used,
        sections.folders_and_data,
        sections.shortcuts,
        sections.repository,
    ];
};
