import {toast} from '@aktek/f4kit';
import {CompanyLicenseService} from '@aktek/helios-frontend-sdk';

import {strings} from '@/localization/i18n';

export const RevokeFunction = async (id, license, onSuccess) => {
    const finalLicense = {
        ...license,
        validity: -1,
        isNewLicense: true,
    };
    const res = await CompanyLicenseService.setLicenseProgram(id, finalLicense);
    const success = res.isSuccessful?.();

    if (!success) return toast.error(strings('license_updated_failed'));
    onSuccess?.('license', finalLicense);

    toast.success(strings('license_updated_success'));
};
