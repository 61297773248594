import {XSDKResponsePromise} from '@aktek/types';
import {XSmartFilter} from '@aktek/types';
import {useCallback, useMemo, useRef, useState} from 'react';

import {F4TableSpreadsheetRef, useF4TableSpreadsheetRef} from '@/components/F4TableSpreadsheet/components_temp/F4TableSpreadsheet';
import {TF4ViewableComponentProps} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import {TSmartFilterModalRef} from '@/components/SmartFilter/SmartFilter.Modal';
import useTableRef from '@/components/Table/Hooks/useTableRef';
import {TTableRibbonActions} from '@/components/Table/Types/Ribbon.t';
import {TTableRef} from '@/components/Table/Types/TableRef.t';

import {GetDataTableColumns} from '../helpers/DataTable.GetColumns.fn';
import {GetRibbonActions} from '../helpers/DataTable.GetRibbonActions.fn';
import {GetDataTableRows} from '../helpers/DataTable.GetRows.fn';
import {GetDataTableRowsCount} from '../helpers/DataTable.GetRowsCount.fn';

export enum MODES {
    TableView = 'table-view',
    SpreadsheetCreation = 'creation',
    SpreadsheetEdit = 'edit',
}

interface SortObject {
    [key: string]: 'asc' | 'desc';
}

interface UseDataTableReturn {
    getRows: (search: unknown, sort: SortObject, skip: number, limit: number)=>
        XSDKResponsePromise<unknown>
    getColumns: TF4ViewableComponentProps['getColumns']
    getRowsCount: () => Promise<number>;
    mode: MODES;
    setMode: React.Dispatch<React.SetStateAction<MODES>>;
    filter: XSmartFilter;
    setFilter: React.Dispatch<React.SetStateAction<XSmartFilter | null>>;
    onRibbonAction: TF4ViewableComponentProps['onRibbonAction'];
    ribbonActions: TTableRibbonActions
    smartFilterModalRef: React.RefObject<TSmartFilterModalRef>;
    f4TableSpreadSheetRef: React.RefObject<F4TableSpreadsheetRef>
    tableRef: TTableRef
}

export const useDataTable = (sboId: string): UseDataTableReturn => {
    const [mode, setMode] = useState<MODES>(MODES.TableView);
    const [filter, setFilter] = useState<XSmartFilter | null>(null);

    const f4TableSpreadSheetRef = useF4TableSpreadsheetRef();
    const smartFilterModalRef = useRef<TSmartFilterModalRef>(null);
    const tableRef = useTableRef();

    const getRows = useCallback(
        (search: unknown, sort: SortObject, skip: number, limit: number) =>
            GetDataTableRows(sboId, search, sort, skip, limit, filter, true),
        [sboId, filter],
    );

    const getColumns = useCallback(() => GetDataTableColumns({sboId}), [sboId]);

    const getRowsCount = useCallback(() => GetDataTableRowsCount({sboId}), [sboId]);

    const onRibbonAction = useCallback(
        async (value) => {
            switch (value) {
                case 'edit':
                    if (mode == MODES.SpreadsheetEdit) return setMode(MODES.TableView);

                    return setMode(MODES.SpreadsheetEdit);
                case 'create':
                    return setMode(MODES.SpreadsheetCreation);
                case 'save':
                    if (mode == MODES.SpreadsheetEdit || mode == MODES.SpreadsheetCreation) {
                        const saved = await f4TableSpreadSheetRef.current?.save();
                        if (saved) setMode(MODES.TableView);

                        return;
                    }

                    tableRef?.current?.state?.paginationState?.goToPage(0);
                    tableRef?.current?.refresh();

                    break;
                case 'filter':
                    smartFilterModalRef.current?.open();
                    break;
                default:
                    break;
            }
        },
        [mode],
    );

    const hasFilter = !!filter;
    const ribbonActions = useMemo(
        () => GetRibbonActions({
            isEditMode: mode === MODES.SpreadsheetEdit,
            isCreatingMode: mode === MODES.SpreadsheetCreation,
            hasFilter,
        }),
        [mode, hasFilter],
    );

    return {
        getRows,
        getColumns,
        getRowsCount,
        mode,
        setMode,
        filter,
        setFilter,
        onRibbonAction,
        ribbonActions,
        smartFilterModalRef,
        f4TableSpreadSheetRef,
        tableRef,
    };
};
