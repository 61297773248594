
import {toast} from '@aktek/f4kit';
import {JobTrackingService} from '@aktek/helios-frontend-sdk';
import _get from 'lodash/get';

import {TResponse} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import Table from '@/components/Table/Table';
import {strings} from '@/localization/i18n';

import IO_JOBS_COLUMNS from './iOJobs.columns';

export default function iOJobs() {
    return (
        <div className="bg-neutral-50 h-full p-4">

            <Table
                getColumns={async ()=> IO_JOBS_COLUMNS}
                getRows={async (paginationState, sortingState, countState, searchState) => {
                    const {pageSize, currentPage} = paginationState;
                    const search = searchState.query;
                    const sort = {id: sortingState[0]?.id, order: sortingState[0]?.desc ? '-1' : '1'};
                    const offset = pageSize * (currentPage -1);

                    const showFinished = false;
                    const showError = false;
                    const showCancelled = false;
                    const showAllCompanies = false;

                    const res
                            = await JobTrackingService.getAllJobs( showFinished, showError, showCancelled, showAllCompanies, search, sort, offset, pageSize);

                    if (!res.isSuccessful()) {
                        toast.error(strings('error_fetching_data'));

                        return [];
                    }

                    const data = res.getData() as TResponse;

                    if (!data) { // @TODO: double check this
                        toast.error(strings('error_fetching_data'));

                        return [];
                    }

                    const items = (Array.isArray(data) ? data : data?.data) || [];
                    const count = _get(data, ['metadata', 0, 'count']);

                    if (count !== undefined) countState.setCount(count);

                    return items;
                }}
                ribbonActions={
                    {
                        leftActions: {
                            refresh: {
                                onRibbonActionClick: (name, e, state) => {
                                    state.rowsState.fetchRows();
                                },
                            },
                        },
                        rightActions: {
                            search: {
                                searchProps: {
                                    onDebouncedChange(state, value) {
                                        state.searchState.setQuery(value);
                                    },
                                },
                            },
                        },
                    }
                }
            />
        </div>
    );
}
