import {RolePermissionsService, RolesService, UserRolesService, UserRolesv4Service} from '@aktek/helios-frontend-sdk';
import _omit from 'lodash/omit';
import {useState} from 'react';

const useRoles = () => {
    const [initialIsSuperAdmin, setInitialIsSuperAdmin] = useState();

    const storeRole = async (role) => {
        const res = await RolesService.storeRole(_omit(role, ['permissions', 'users']));

        if (!res.isSuccessful?.()) {
            return {isSuccessful: () => false, getData: () => {}};
        }

        const permissionsData = role.permissions || [];
        const usersData = role.users || [];

        if (role.permissions) {
            await RolePermissionsService.storeRolePermissions(role.id || res.getData()?.['id'], permissionsData);
        }

        if (role.users) {
            await UserRolesv4Service.assignUsersToRole(usersData, role.id || res.getData()?.['id'] );
        }

        return res;
    };

    const getRoles = async (roleId) => {
        const res = await RolesService.getRoleByID(roleId);
        const usersRes = await UserRolesService.getUsersByRole(roleId);

        if (!res.isSuccessful?.() || !usersRes.isSuccessful()) {
            return {isSuccessful: () => false, getData: () => {}};
        }

        const data = res.getData();
        const finalInitialValue = {
            ...data as object,
            users: usersRes.getData(),
        };

        setInitialIsSuperAdmin(finalInitialValue?.['overridePermissions']);

        return {
            isSuccessful: () => true,
            getData: () => finalInitialValue,
        };
    };

    return {getRoles, storeRole, initialIsSuperAdmin};
};

export default useRoles;
