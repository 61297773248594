import {Label} from '@aktek/f4kit';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';

import {TSBOEditorFieldProps} from '../../types/SBOEditor.t';
import SBOFieldCommon from './SBO.Field.Common';

export default function SBOFieldImageVideoAlbum(props: TSBOEditorFieldProps) {
    const {field} = props;
    // const {field, tableFields, sboActions, children} = props;
    // const {toggleFieldTableVisibility} = sboActions;

    return (
        <SBOFieldCommon {...props}>
            <div className="flex flex-col w-full bg-neutral-100 rounded-xl items-center gap-1" >

                {/* ********** Minimum ********** */}
                <div className="flex flex-col items-center justify-between gap-2 w-full">
                    <div className="flex items-center gap-2 w-full group/description ">
                        <Label text="Minimum" className="!w-60" color="neutral-400"/>
                        <F4Textbox isGhost type="number" allowDecimal
                            inputClassName="!text-base font-medium" name={`fieldsMap.${field.id}.minimum`}
                            className="text-neutral-600 group-hover/description:!bg-neutral-50 rounded-lg"/>
                    </div>

                    {/* ********** Maximum ********** */}
                    <div className="flex items-center gap-2 w-full group/description ">
                        <Label text="Maximum" className="!w-60" color="neutral-400"/>
                        <F4Textbox isGhost type="number" allowDecimal
                            inputClassName="!text-base font-medium" name={`fieldsMap.${field.id}.maximum`}
                            className="text-neutral-600 group-hover/description:!bg-neutral-50 rounded-lg"/>
                    </div>

                    <div className="flex flex-row items-start w-full gap-4">
                        <F4ToggleSwitch
                            className="w-44"
                            name={`fieldsMap.${field.id}.showName`}
                            description="Show name"
                            defaultValue={false}
                            size="sm"
                        />

                        <F4ToggleSwitch
                            className=" w-44"
                            name={`fieldsMap.${field.id}.saveMetadata`}
                            description="Save metadata"
                            defaultValue={false}
                            size="sm"
                        />
                    </div>
                </div>
            </div>
        </SBOFieldCommon>
    );
}

