import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

export default function GetToggleFieldTableVisibilityUpdateObject(data: XSBO, fieldId: string) {
    const updateObject : TUpdateObject<XSBO> = {};
    const fieldIndex = data.tableFields.indexOf(fieldId);

    if (fieldIndex !== -1) {
        updateObject[`tableFields.${fieldIndex}`] = UpdateActions.DELETE;
    } else {
        updateObject[`tableFields`] = {[UpdateActions.APPEND]: fieldId};
    }

    return updateObject;
}

