import {Icon, Label} from '@aktek/f4kit';
import {faChevronDown, faChevronRight, faEye, faEyeSlash, faInfoCircle, faInputText} from '@fortawesome/pro-regular-svg-icons';
import {useState} from 'react';

import F4NSwitch from '@/components/F4Elements/F4NSwitch';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleButton from '@/components/F4Elements/F4ToggleButton';
import {ToggleIcon} from '@/components/F4Elements/F4ToggleIcon';

import {fieldTypesDictionary} from '../../helpers/GetSectionActionsContextMenuEntries.fn';
import {TSBOEditorFieldProps} from '../../types/SBOEditor.t';

const N_SWITCH_OPTIONS = [
    {icon: faInputText, value: true, label: '', tooltip: 'Show description under the field name'},
    {icon: faInfoCircle, value: false, label: '', tooltip: 'Show description as an info button'},
];

export default function SBOFieldCommon(props: TSBOEditorFieldProps) {
    const {field, tableFields, sboActions, children} = props;
    const {toggleFieldTableVisibility} = sboActions;

    const [isFieldExpanded, setIsFieldExpanded] = useState(field.name === '');
    const icon = fieldTypesDictionary[field.type]?.icon;
    const color = fieldTypesDictionary[field.type]?.color;

    return (
        <div id={`field-${field.id}`} className={`flex flex-col w-full hover:bg-neutral-100 rounded-xl items-center p-3 gap-3 ${isFieldExpanded && 'bg-neutral-100'}`} >
            {/* ********** Field Name ********** */}
            <div className="flex items-start justify-between w-full">
                <div className="flex gap-2 w-full !items-start group/name">
                    <Icon className="w-3 h-3 mt-2.5 p-1.5 hover:!bg-neutral-300 rounded-lg" color="neutral-600"
                        onClick={()=> setIsFieldExpanded(!isFieldExpanded)}
                        icon={isFieldExpanded ? faChevronDown : faChevronRight} />

                    <Icon icon={icon}
                        color={color} className="w-4 h-4 py-3.5"/>

                    <F4Textbox name={`fieldsMap.${field.id}.name`} isGhost required size="sm"
                        placeholder="Name of field" inputClassName="!text-base mt-0.5"
                        className="text-neutral-600 group-hover/name:!bg-neutral-50 rounded-lg" errorClassName="ml-3"/>
                </div>

                {/* ********** Required + Show in Table ********** */}
                {isFieldExpanded && <div className="flex items-center gap-3 float-end mt-1 ml-2">
                    <F4ToggleButton isGhost text={'Required'}
                        name={`fieldsMap.${field.id}.isRequired`} className="text-sm" size="sm"/>

                    <ToggleIcon
                        value={tableFields.indexOf(field.id)!== -1 ? true : false}
                        onChange={() => toggleFieldTableVisibility(field.id)}
                        icons={{on: faEye, off: faEyeSlash}}
                        tooltip={{on: 'Hide in Data Table', off: 'Show from Data Table'}}
                        tooltipProps={{showDelay: 1000}}
                        colors={{on: 'neutral-600', off: 'neutral-600'}}
                        className="w-4 h-4"/>

                </div>}
            </div>

            {isFieldExpanded && <>
                {/* ********** Description ********** */}
                <div className="flex items-center justify-between gap-2 w-full">
                    <div className="flex items-center gap-2 w-full group/description ">
                        <Label text="Description" className="!w-60" color="neutral-400"/>

                        <F4Textbox isGhost
                            inputClassName="!text-base font-medium" name={`fieldsMap.${field.id}.description`}
                            className="text-neutral-600 group-hover/description:!bg-neutral-50 rounded-lg"/>
                    </div>

                    {/* ********** Show Description ********** */}
                    <div className="flex items-center gap-3">
                        <F4NSwitch name={`fieldsMap.${field.id}.showDescription`}
                            options={N_SWITCH_OPTIONS}
                            defaultValue={false}
                            size="xs"
                            className="!p-0"
                        />

                    </div>
                </div>

                {children}

                {/* ********** Show on condition ********** */}
                <div className="flex items-center w-full group/condition">
                    <Label text="Show on condition" className="!w-60" color="neutral-400"/>
                    <F4Textbox isGhost
                        inputClassName="!text-base font-medium !w-full"
                        className="text-neutral-600 group-hover/condition:!bg-neutral-50 rounded-lg"/>
                </div>
            </>
            }
        </div>
    );
}
