import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';
import {LicensePlanService, UserService} from '@aktek/helios-frontend-sdk';
import {useState} from 'react';

import {TransformDataToDropdownOptions} from '@/helpers/TransformDataToDropdownOptions.fn';

const useAgency = () => {
    const [listAccountManagers, setListAccountManagers] = useState<Array<Record<TDropdownValue, TDropdownOption>>>([]);
    const [dropdownOptions, setDropdownOptions] = useState();

    const getLicenses = async (search, sort, skip, limit) => {
        const res = await LicensePlanService.getAllLicensePlans(search, sort, skip, limit);
        const success = res.isSuccessful?.();
        const data = res.getData();
        success && setDropdownOptions(TransformDataToDropdownOptions(data, '_id', 'plan'));

        return data?.['data'];
    };

    const getAllAccountManagers = async () => {
        const res = await UserService.getAllAccountManagers();
        const success = res.isSuccessful?.();
        const data = res.getData();

        success && setListAccountManagers(data as Array<Record<TDropdownValue, TDropdownOption>>);

        return data;
    };

    return {
        dropdownOptions, getLicenses,
        getAllAccountManagers, listAccountManagers};
};

export default useAgency;

