import {useF4FormContext} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

import {TSBOActions} from '../types/SBOEditor.t';
import SBOEditorRecordsTagsField from './SBO.EditorRecordsTagsField';
import SBOEditorSections from './SBO.EditorSections';

type TSBOEditorBodyProps = {
    sboActions: TSBOActions;
}

export default function SBOEditorBody(props:TSBOEditorBodyProps) {
    const {sboActions} = props;
    const {getData} = useF4FormContext<XSBO>();
    const currentSBO = getData();

    return (
        <div className="pb-6 select-none">
            <SBOEditorRecordsTagsField currentSBO={currentSBO} toggleTagFieldTableVisibility={sboActions.toggleTagFieldTableVisibility}/>

            <div className="group">
                <SBOEditorSections currentSBO={currentSBO} sboActions={sboActions} />
            </div>
        </div>
    );
}
