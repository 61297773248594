
import {TSBOEditorFieldProps} from '../../types/SBOEditor.t';
import SBOFieldCommon from './SBO.Field.Common';
import SBOFieldGenericFile from './SBO.Field.GenericFile';
import SBOFieldImageVideoAlbum from './SBO.Field.ImageVideoAlbum';
import SBOFieldIntegerDecimal from './SBO.Field.IntgerDecimal';

export default function SBORenderField(props: TSBOEditorFieldProps) {
    switch (props.field?.type?.toLowerCase?.()) {
        case 'string':
        case 'boolean':
        case 'date':
        case 'dateTime':
        case 'time':
        case 'gps':
        case 'color':
        case 'geoRepository':
            return <SBOFieldCommon {...props} />;

        case 'integer':
        case 'decimal':
            return <SBOFieldIntegerDecimal {...props} />;

        case 'genericFile':
            return <SBOFieldGenericFile {...props} />;

        case 'image':
        case 'video':
        case 'album':
            return <SBOFieldImageVideoAlbum {...props} />;

        default:
            return <SBOFieldCommon {...props} />;
    }
}

