import {Button, TabsManager} from '@aktek/f4kit';
import {useEffect, useState} from 'react';

import {isEventEnterKey} from './helpers/Events';

export default function DevBox() {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle function for toggling `isOpen`
    function toggle() {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    }

    useEffect(() => {
        // Event listener for keyboard shortcuts
        function handleKeyDown(event) {
            if (event.ctrlKey && isEventEnterKey(event)) {
                toggle();
            }
        }

        // Add event listener
        window.addEventListener('keydown', handleKeyDown);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="App">
            {/* Conditional rendering based on isOpen */}
            {isOpen && (
                <div className="fixed top-0 w-full bg-error-100 p-2 flex gap-4 z-50">

                    {localStorage.devMode !== 'true' && <Button
                        variant="warning"
                        size="md"
                        onClick={() => {
                            localStorage.clear();
                            localStorage.setItem('devMode', true);
                            window.location.reload();
                        }}
                        label={'Click to switch to Dev Mode'}
                    />}

                    {localStorage.devMode === 'true' && <Button
                        label={'Click to leave Dev Mode'}
                        variant="success"

                        onClick={() => {
                            localStorage.clear();
                            localStorage.setItem('devMode', false);
                            window.location.reload();
                        }}
                    />}
                    <Button
                        onClick={() => {
                            TabsManager.focusOrOpenNewTab('playgroundIOSelect', 'Playground');
                        }}
                        label="Add Playground Tab"
                        size="md"
                        variant="primary"/>
                </div>
            )}
        </div>
    );
}
