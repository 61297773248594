import {TContextMenuEntry} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';
import {faCopy, faTrash} from '@fortawesome/pro-regular-svg-icons';

import {TSBOActions, TSection} from '../types/SBOEditor.t';

export default function GetSectionContextMenuEntries(currentSBO: XSBO, section: TSection, sboActions: TSBOActions) : TContextMenuEntry[] {
    const result: TContextMenuEntry[] = [];
    const {id: sectionId} = section;

    result.push({
        key: 'duplicate',
        icon: faCopy,
        label: 'Duplicate',
        color: 'neutral-700',
        onClick: ()=> sboActions.addSection(section),
    });

    if (currentSBO.sections.length > 1) {
        result.push({
            key: 'delete',
            icon: faTrash,
            label: 'Delete',
            color: 'error-700',
            onClick: ()=> sboActions.deleteSection(sectionId),
        });
    }

    return result;
}
