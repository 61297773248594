import cn from 'classnames';

export const IntegerClasses = (layout:boolean) => ({
    container: cn(
        'flex gap-6 justify-between',
        layout && 'flex-col items-start',
        !layout && 'flex-auto items-center',
    ),
    text: layout ? 'h' : 'w',
    textbox: cn(
        'text-neutral-600 rounded-lg placeholder-neutral-300 bg-neutral-100',
        layout && 'h-3/4',
        !layout && 'w-3/4',
    ),
});

