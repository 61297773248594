import IOSelect from '@/iohub/IOSelect';

const RefCellEdit = ({value, onChange, sboId, fieldId}) => {
    if (!sboId || !fieldId) return null;

    return <IOSelect autoOpenOnMount
        value={value}
        sboId={sboId}
        displayFieldId={fieldId}
        type="ref"
        onChange={(value) => {
            onChange(value);
        }}
    />;
};

export default RefCellEdit;
