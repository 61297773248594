import {useContext, useEffect, useState} from 'react';

import useFilesAndFoldersState from '@/hooks/useFilesAndFoldersState';
import {useTheme} from '@/hooks/useTheme';
import {UserAuth} from '@/types/UserAuth';

import usePermissionsLoader from '../Permissions/usePermissionsLoader';
import {UserContext} from './UserContext';

export default function UserContextProvider({children}:{children: React.ReactNode}) {
    const [userAuth, setUserAuth] = useState<UserAuth>(ls.getUserData());
    const [path, setPath] = useState('');

    const filesAndFoldersState = useFilesAndFoldersState();
    const theme = useTheme();

    const permissionsState = usePermissionsLoader({userId: userAuth?.currentUser?._id, companyId: userAuth?.currentCompany?._id});
    useEffect(() => {
        if (!userAuth.auth_token) return;

        filesAndFoldersState?.refresh();
    }, [userAuth.auth_token]);

    return (
        <UserContext.Provider value={{userAuth, setUserAuth, filesAndFoldersState, permissionsState, theme, setPath, path}}>
            {children}
        </UserContext.Provider>
    );
}

export function useUserContext() {
    const userContext = useContext(UserContext);

    if (!userContext) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }

    return userContext;
}

