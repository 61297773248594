import {Drawer, TabNav, useCurrentTab} from '@aktek/f4kit';
import {Suspense, useEffect, useMemo} from 'react';

import {Tabs} from '@/app/Home/Tabs';

import WIPPage from './Pages/WIPPage';
import {TNavigationPagesViewerProps} from './types/Navigation.t';

export default function NavigationPagesViewer() {
    const currentTab = useCurrentTab<{isOpen: boolean}, TNavigationPagesViewerProps>();
    const {pages, selectedPage} = currentTab.getTabState();

    const hasSinglePage = pages.length === 1;

    const tabComponents = useMemo(() => {
        return pages.reduce((obj, page) => {
            const {tabKey, props = {}} = page;
            const Component = Tabs[tabKey]?.component || WIPPage;
            const {key, ...rest} = props;

            obj[tabKey] = <Suspense fallback={<div>Loading Module...</div>}>
                <Component key={key as string} tabKey={tabKey} {...rest} />
            </Suspense>;

            return obj;
        }, {}) as Record<string, JSX.Element>;
    }, [pages]);

    const tabOptions = useMemo(() => {
        return pages.map((e) => {
            return {...e, key: e.tabKey};
        });
    }, [pages]);

    const finalSelectedPage = selectedPage || tabOptions.find((tab) => tab.isVisible != false)?.key;

    useEffect(() => {
        const persistedSelectedPage = selectedPage;

        if (!persistedSelectedPage) {
            if (!selectedPage) {
                const firstTab = tabOptions.find((tab) => tab.isVisible != false);// Find the first visible tab
                if (!firstTab) return;
                currentTab.updateConfig({icon: firstTab.icon, title: firstTab.label});
                currentTab.setTabState({selectedPage: firstTab.tabKey});
            } else {
                const page = tabOptions.find((page) => page.tabKey == selectedPage); // Find the selected tab
                currentTab.updateConfig({icon: page.icon, title: page.label});
                currentTab.setTabState({selectedPage: page.tabKey});
            }
        }
    }, [selectedPage]);

    if (hasSinglePage) {
        return <div className="p-4 bg-neutral-50 h-full border-l border-neutral-200">
            {tabComponents[finalSelectedPage]}
        </div>;
    }

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                tooltipDelay={1000}
                defaultOpen={currentTab.getConfig().isOpen ?? true}
                onOpenChange={(e) => currentTab.updateConfig({isOpen: e})}
                buttonSize="sm"
            >

                <TabNav
                    size="sm"
                    orientation="vertical"
                    tabs={tabOptions}
                    value={finalSelectedPage}
                    defaultValue={finalSelectedPage}
                    onChange={(selectedPage: string) => {
                        const page = tabOptions.find((page) => page.tabKey == selectedPage);

                        currentTab.updateConfig({icon: page.icon, title: page.label});
                        currentTab.setTabState({selectedPage: page.tabKey});
                    }}
                    className="overflow-x-visible"
                    buttonClassName="[&>:first-child]:[&>:first-child]:[&>:first-child]:!w-8"
                />
                <div className="p-4 bg-neutral-50 h-full border-l border-neutral-200">
                    {tabComponents[finalSelectedPage]}
                </div>
            </Drawer>
        </div>
    );
}

