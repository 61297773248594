
export default function GetNewFieldSelectedAndFocused(fieldId: string) {
    setTimeout(() => {
        const fieldElement = document.getElementById(`field-${fieldId}`);

        if (fieldElement) {
            fieldElement.style.scrollMarginTop = '50px';

            fieldElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });

            setTimeout(() => {
                const nameInput = fieldElement.querySelector(`input[name="fieldsMap.${fieldId}.name"]`) as HTMLInputElement;

                if (nameInput) {
                    nameInput.focus();
                    nameInput.select();
                }

                fieldElement.style.scrollMarginTop = '';
            }, 50);
        }
    }, 200);
}
