import {Icon, Tag, TDropdownOption} from '@aktek/f4kit';
import {GroupsService, UserService} from '@aktek/helios-frontend-sdk';
import {MorphService, SBOService, TagService} from '@aktek/morph-frontend-sdk';
import {faUser} from '@fortawesome/pro-light-svg-icons';

import {FieldIcons} from '@/constants/FieldIcons.c';

// import {fetchTagsDictionary} from '@/services/api/TagService';
import type {TIOHubRules} from './types/IOHub.t';

const rules: TIOHubRules = {
    'sbo': {
        cacheKeys: ['targetFeature', 'isComplete'],
        apiCall: (props, lastClientCacheTime) => {
            const {targetFeature, isComplete} = props;

            return SBOService.getSBOOptions(lastClientCacheTime, targetFeature, isComplete);
        },

        allowSubstringSearch: true,
    },

    'field': {
        cacheKeys: ['sboId', 'includeDerivedFields'],
        apiCall: (props, lastClientCacheTime) => {
            const {sboId, includeDerivedFields} = props;

            return SBOService.getFieldsOptions(sboId, includeDerivedFields, lastClientCacheTime);
        },
        refreshKeys: ['sboId'],
        renderOption: () =>{
            return (option: TDropdownOption)=>{
                const icon = FieldIcons[option.object?.type] || FieldIcons['default'];

                return <span className="flex items-center space-x-2">
                    {/* Icon */}
                    {icon && <span className="flex justify-center items-center w-[1rem] h-[1rem]">
                        <Icon {...icon} />
                    </span>}
                    {/* Text */}
                    <span>{option.label}</span>
                </span>;
            };
        },
        getConstraintText: (props) => {
            if (!props.sboId) return 'Select data structure...';

            return null;
        },
    },

    'ref': {
        cacheKeys: ['sboId', 'displayFieldId', 'keepDuplicates', 'search'],
        apiCall: (props, lastClientCacheTime) => {
            const {sboId, displayFieldId, allowSubstringSearch, keepDuplicates, search} = props;

            return MorphService.getOptionsViewing(sboId, displayFieldId, allowSubstringSearch, keepDuplicates, lastClientCacheTime, search, false);
        },
        getMissingValues: (props, missingValues) => {
            const {sboId, displayFieldId} = props;

            return MorphService.getOptionsViewingById(sboId, displayFieldId, missingValues, 0);
        },
        limitCount: 1000,

    },
    'group': {
        apiCall: (props, lastClientCacheTime) => {
            return GroupsService.getGroupsDictionary(lastClientCacheTime);
        },
        renderOption: () => (option: TDropdownOption) => {
            return <span>  <Icon icon={faUser}/>  {option}</span>;
        },
    },
    'user': {
        apiCall: (props, lastClientCacheTime) => {
            return UserService.getUsersDictionary(lastClientCacheTime);
        },
        renderOption: () => (option: string) => {
            return <span>  <Icon icon={faUser}/>  {option}</span>;
        },
    },
    'tags': {
        apiCall: async (props, lastClientCacheTime) => {
            return TagService.getAllTagsDictionary(props?.search || '');
        },
        renderOption: () => (option: TDropdownOption) => {
            return <Tag label={option?.label + ''} />;
        },
    },
};

export default rules;
