import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

import {TSection} from '../../types/SBOEditor.t';
import GenerateId from '../GenerateID.fn';

export default function GetAddSectionUpdateObject(data: XSBO, section: TSection) {
    const sectionId = GenerateId();
    const newSection = {...section, id: sectionId, fields: []};
    newSection.name = section.id ? `${section.name || ''}` : 'Section ' + (data.sections.length + 1);

    const updateObject: TUpdateObject<XSBO> = {
        [`sectionsMap.${sectionId}`]: newSection,
        ['sections']: {[UpdateActions.APPEND]: sectionId},
    };

    section.fields?.forEach((fieldId) => {
        const field = data.fieldsMap[fieldId];
        if (!field) return;
        const newFieldId = GenerateId();
        const newField = {...field, id: newFieldId};

        newSection.fields.push(newFieldId);
        updateObject[`fieldsMap.${newFieldId}`] = newField;

        if (data.tableFields.includes(fieldId)) {
            updateObject['tableFields'] = {[UpdateActions.APPEND]: newField.id};
        }
    });

    return updateObject;
}

