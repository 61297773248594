import {MorphService} from '@aktek/morph-frontend-sdk';

type TGetRowsCount = {
    sboId: string
}

export const GetDataTableRowsCount = async ({sboId}: TGetRowsCount) => {
    // add the filter object
    const res = await MorphService.retrieveMorphsCountBySBOId(sboId, '', null);
    if (!res.isSuccessful()) return 0;

    return res.getData() as number;
};
