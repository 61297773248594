import {URL_REGEX} from '@/constants/Regex.c';

export const StringInclude = (item, searchQuery) => {
    return item.toLowerCase().includes(searchQuery.toLowerCase());
};

export const toCamelCase = (str) => {
    return str
        .toLowerCase() // Convert the entire string to lower case
        .replace(/[-\s]+(.)/g, (match, char) => char.toUpperCase()) // Convert first char after space/hyphen to upper case
        .replace(/^[a-z]/, (char) => char.toLowerCase()); // Ensure the first character is lowercase
};

export const isValidURL = (string) => {
    const res = string.match(URL_REGEX);

    return (res !== null);
};
