
import {Cell, Row} from '@tanstack/react-table';

import MediaAlbumCellView from '@/components/Fields/MediaAlbum/MediaAlbum.Cell.View';
import {getMediaFilesFromMediaValue} from '@/helpers/File';

export const CellMediaAlbum = (columnDef) => ({
    cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
        const value = cell.getValue();
        const files = getMediaFilesFromMediaValue(value);

        return (
            <div className="w-64">
                <MediaAlbumCellView files={files} />
            </div>
        );
    },

});

