import {LoadingIndicator, Modal, TModalRef} from '@aktek/f4kit';
import {forwardRef, useCallback, useEffect, useState} from 'react';

import SearchBox from '@/components/SearchBox/SearchBox';
import {useSignUserIn} from '@/context/UserContext/Hooks/useSignUserIn';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {EventKeys} from '@/helpers/Events';
import {strings} from '@/localization/i18n';

import {OnSwitchCompany} from '../helpers/Switch.OnSwitchCompany.fn';
import useKeydownEventListener from '../hooks/useKeydownEventListener';
import useSwitchCompaniesSearch, {isRootCompany} from '../hooks/useSwitchCompaniesSearch';
import {DisplayCompanies} from './Switch.DisplayCompanies';

const SwitchCompanyModal = ({}, ref:TModalRef) => {
    const signIn = useSignUserIn();

    const [searchQuery, setSearchQuery] = useState<string>('');
    const userAuth = useUserAuth();

    const [isLoading, setIsLoading] = useState(false);
    const filteredCompaniesData = useSwitchCompaniesSearch(searchQuery, userAuth);
    const userCompanies = userAuth?.userCompanies;
    const rootCompanyId = userCompanies?.find(isRootCompany)?._id;
    const {currentCompany} = useUserAuth();

    // logging out the user, because we are not firing an api call in the switch company modal
    useEffect(() => {
        if (userCompanies?.length == 0) {
            location.reload();
        }
    }, []);

    const isCompaniesDataEmpty = !filteredCompaniesData || filteredCompaniesData.length === 0;

    const handleSwitchCompany = useCallback((companyId:string) => OnSwitchCompany(companyId, setIsLoading, signIn), []);

    const handleKeyDown = useCallback((event) => EventKeys.isEnter(event)
    && filteredCompaniesData[0]?._id !== currentCompany?._id
    && handleSwitchCompany(filteredCompaniesData[0]?._id), [filteredCompaniesData, currentCompany]);

    useKeydownEventListener(handleKeyDown, filteredCompaniesData);

    return (
        <Modal
            className="overflow-hidden relative h-full"
            title={strings('switch_companies')}
            headerClassName="pl-6 border-b border-b-neutral-100 mb-4 text-xl"
            ref={ref}
            onClose={() => document.removeEventListener('keydown', handleKeyDown)} >

            {isLoading && <div className=" absolute flex items-center w-full h-full p-4 justify-center elevation-5 !shadow-none"
                style={{backgroundColor: 'rgba(0, 0, 0, 0.15)'}}>
                <LoadingIndicator size="xl" />
            </div>}

            <SearchBox
                onSearch={(e) => setSearchQuery(e)}
                className="!bg-neutral-100 rounded-lg mx-6"
                textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                hasBottomBorder={false}
                placeholder={strings('search_companies')}
            />

            <div className="h-4/6 overflow-y-auto mx-6 my-4 relative">
                {isCompaniesDataEmpty && <div className="px-2 py-4 text-center text-neutral-600">{strings('no_results_found')}</div>}

                {!isCompaniesDataEmpty
                && <DisplayCompanies companies={filteredCompaniesData}
                    currentCompany={currentCompany}
                    onSwitchCompany={handleSwitchCompany}
                    rootCompanyId={rootCompanyId} /> }
            </div>

        </Modal>
    );
};

export default forwardRef(SwitchCompanyModal);
