
import {XSBO} from '@aktek/types';

import {TSBOActions} from '../types/SBOEditor.t';
import SBOEditorSection from './SBO.EditorSection';
import SBOEditorSectionFields from './SBO.EditorSectionFields';

type TSBOEditorSectionsProps = {
    sboActions: TSBOActions;
    currentSBO: XSBO;
}

export default function SBOEditorSections(props: TSBOEditorSectionsProps) {
    const {currentSBO, sboActions} = props;

    if (!currentSBO?.sections) return null;

    return currentSBO.sections.map((sectionId, index)=>{
        const section = currentSBO?.sectionsMap[sectionId];
        if (!section) return null;

        return <SBOEditorSection
            key={sectionId}
            currentSBO={currentSBO}
            index={index}
            section={section}
            sboActions={sboActions}
        >

            <SBOEditorSectionFields
                currentSBO={currentSBO }
                sboActions={sboActions}
                section={section}/>
        </SBOEditorSection>;
    });
}
