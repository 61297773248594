import {F4FormDiv, F4Nestor} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';

import LicenseFeature from '@/components/LicenseComponents/LicenseFeature';
import {strings} from '@/localization/i18n';

const LicensingFormLimits = ({hasAmpWlc, limits, isViewMode = false}) => {
    const hasLimits = limits?.length > 0;

    return (

        <F4Nestor name="limits">
            <F4FormDiv className="flex justify-between items-center py-4">
                <Label
                    size="xl"
                    text={strings('iq_areas_limits')}
                    color="neutral-700"
                    fontWeight="medium"
                />
                {!isViewMode && <Label
                    className="pr-2"
                    size="sm"
                    text={strings('customize')}
                    color="neutral-600"
                    fontWeight="medium"
                />}
            </F4FormDiv>
            {limits?.map?.((item, index) => (
                <LicenseFeature
                    isLicensePage={true}
                    isViewMode={isViewMode}
                    key={index}
                    item={item}
                    hasAmpWlc={hasAmpWlc}
                />
            ))}

            {!hasLimits && (
                <p className="flex items-center mt-3 ml-2 overflow-hidden">
                    {strings('no_results_found')}
                </p>
            )}
        </F4Nestor>

    );
};

export default LicensingFormLimits;
