import {Cell, Row} from '@tanstack/react-table';

import GpsCellView from '@/components/Fields/Gps/Gps.Cell.View';

export const CellGPS = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = cell.getValue?.() || '';
            const [lat, lng] = value.split(',');

            return <GpsCellView lat={lat} lng={lng}/>;
        },
    };
};
