
import {TextBox} from '@aktek/f4kit';
import {useRef} from 'react';

const GpsCellEdit = ({lat, lng, onChange}) => {
    const lngRef = useRef();

    return <div className="flex items-center gap-1">
        <TextBox allowDecimal
            onKeyDown={(event) => {
                if (event.code !== 'Tab') return;
                event.stopPropagation();
                // here
            }}
            type="number"
            label={'lag'}
            value={lat}
            className="max-w-fit min-w-16"
            onChange={(lat) => onChange({lat, lng})}
        />
        <TextBox allowDecimal
            ref={lngRef}
            type="number"
            label={'lng'}
            value={lng}
            className="max-w-fit min-w-16"
            onChange={(lng) => onChange({lat, lng})}
        />
    </div>;
};

export default GpsCellEdit;
