import {useCallback, useMemo} from 'react';

import {TRecordField} from '@/app/DataTable/types/Record.Types';
import integerIcon from '@/assets/integer.png';
import F4Textbox from '@/components/F4Elements/F4TextBox';

import FieldBase from '../components/Field.Base';
import GetIntegerFieldName from './helpers/Integer.GetFieldName.fn';
import ValidateIntegerNumberValue from './helpers/Integer.ValidateNumberValue.fn';
import useInteger from './hooks/useInteger';
import {IntegerClasses} from './styles/Integer.styles';

type TIntegerRecordEdit = {
    field:TRecordField;
    onChange?: (data) => void;
    isDecimalField?:boolean;
    layout:boolean;
}

const IntegerRecordEdit = (props:TIntegerRecordEdit) => {
    const {field, onChange, isDecimalField = false, layout} = props;
    const {id, showDescription, description, maximum, minimum, isRequired, currencyType, numberType} = field;

    const {errorMessage, handleOnChange} = useInteger(field, onChange);

    const isPercentageType = numberType == 'percentage';

    const rightPlaceholder = isPercentageType ? '%' : '';

    const name = useMemo(() => GetIntegerFieldName(field), [field]);

    const handleValidation = useCallback((value: number) => {
        return ValidateIntegerNumberValue(value, maximum, minimum);
    }, [maximum, minimum]);

    const classes = IntegerClasses(layout);

    return (
        <div className={classes.container}>
            <FieldBase
                name={name}
                description={description}
                showDescription={showDescription}
                image={integerIcon}
                classes={classes}
            />
            <F4Textbox
                isGhost
                name={id}
                allowDecimal={isDecimalField || isPercentageType}
                required={isRequired}
                type="number"
                leftPlaceholder={currencyType ?? ''}
                rightPlaceholder={rightPlaceholder}
                onChange={handleOnChange}
                validate={handleValidation}
                errorText={errorMessage}
                variant={errorMessage ? 'error' : 'neutral'}
                className={classes.textbox}
            />
        </div>

    );
};

export default IntegerRecordEdit;
