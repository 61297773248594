const {
    BASE_URL,
    UPLOAD_FILE_HOST,
    GOOGLE_MAP_LAT_LNG_API,
    LOCAL,
    DEV_MODE_PATH,
} = process.env;

const requiredEnvVariables = {
    BASE_URL,
};

const validateRequiredEnvVariables = (variables: Record<string, string | undefined>) => {
    Object.entries(variables)
        .forEach(([key, value]) => {
            if (value) return;
            throw new Error(`Required environment variable ${key} is undefined`);
        });
};

validateRequiredEnvVariables(requiredEnvVariables);

const hasDevMode = !!DEV_MODE_PATH;

if (!hasDevMode && localStorage.devMode === 'true') delete localStorage.devMode;

let processedBaseURL = BASE_URL;

if (hasDevMode) {
    processedBaseURL = localStorage.devMode === 'true' ? DEV_MODE_PATH : BASE_URL;
}

export default {
    BASE_URL: processedBaseURL,
    UPLOAD_FILE_HOST,
    GOOGLE_MAP_LAT_LNG_API,
    LOCAL,
    hasDevMode,
};
