import {XLicenseFeaturesKeys, XLicenseLimitsKeys} from '@aktek/types';

import {strings} from '@/localization/i18n';
export const LICENSE_FEATURES: {
    name: string;
    value: boolean | number;
    key: XLicenseFeaturesKeys;
}[]= [
    {name: strings('features_amp'), value: false, key: XLicenseFeaturesKeys.amp},
    {name: strings('features_amp_monitor'), value: false, key: XLicenseFeaturesKeys.amp_monitor},
    {name: strings('features_amp_kmz_uploader'), value: false, key: XLicenseFeaturesKeys.amp_kmz_uploader},
    {name: strings('features_download_amp'), value: false, key: XLicenseFeaturesKeys.download_amp},
    {name: strings('features_amp_country_search'), value: false, key: XLicenseFeaturesKeys.amp_country_search},
    {name: strings('features_amp_device_info'), value: false, key: XLicenseFeaturesKeys.amp_device_info},
    {name: strings('features_amp_ip_devices'), value: false, key: XLicenseFeaturesKeys.amp_ip_devices},
    {name: strings('features_amp_aid_to_id'), value: false, key: XLicenseFeaturesKeys.amp_aid_to_id},
    {name: strings('features_amp_id_to_aid'), value: false, key: XLicenseFeaturesKeys.amp_id_to_aid},
    {name: strings('features_amp_light_area_query'), value: false, key: XLicenseFeaturesKeys.amp_light_area_query},
    {name: strings('features_iq_incidents'), value: false, key: XLicenseFeaturesKeys.iq_incidents},
    {name: strings('features_fire_data'), value: false, key: XLicenseFeaturesKeys.fire_data},
    {name: strings('features_media'), value: true, key: XLicenseFeaturesKeys.media},
    {name: strings('features_ota'), value: false, key: XLicenseFeaturesKeys.ota},
    {name: strings('features_agency'), value: false, key: XLicenseFeaturesKeys.agency},
    {name: strings('features_advanced_data_actions'), value: false, key: XLicenseFeaturesKeys.advanced_data_actions},
    {name: strings('features_analysis'), value: true, key: XLicenseFeaturesKeys.analysis},
    {name: strings('features_public_link'), value: false, key: XLicenseFeaturesKeys.public_link},
    {name: strings('features_data_toolbox'), value: false, key: XLicenseFeaturesKeys.data_toolbox},
    {name: strings('features_audit'), value: true, key: XLicenseFeaturesKeys.audit},
    {name: strings('features_automation'), value: false, key: XLicenseFeaturesKeys.automation},
    {name: strings('features_ccol'), value: true, key: XLicenseFeaturesKeys.ccol},
    {name: strings('features_download'), value: true, key: XLicenseFeaturesKeys.download},
    {name: strings('features_tac'), value: false, key: XLicenseFeaturesKeys.tac},
    {name: strings('features_translation'), value: true, key: XLicenseFeaturesKeys.translation},
    {name: strings('features_whitelabel'), value: true, key: XLicenseFeaturesKeys.whitelabel},
];

export const LIMITS_FOR_METRICS: {
    name: string;
    value: boolean | number | string[];
    key: XLicenseLimitsKeys;

}[] = [
    {name: strings('limits_amp'), value: 0, key: XLicenseLimitsKeys.amp},
    {name: strings('limits_amp_wlc'), value: [], key: XLicenseLimitsKeys.amp_wlc},
    {name: strings('limits_amp_history_limit'), value: 0, key: XLicenseLimitsKeys.amp_history_limit},
    {name: strings('limits_amp_time_range_limit'), value: 0, key: XLicenseLimitsKeys.amp_time_range_limit},
];
