import {XSBO} from '@aktek/types';

import {TField, TSBOActions, TSection} from '../types/SBOEditor.t';
import SBOEditorField from './SBO.EditorField';

type TSBOEditorSectionFieldsProps = {
    currentSBO: XSBO;
    sboActions: TSBOActions;
    section: TSection;
}

export default function SBOEditorSectionFields(props: TSBOEditorSectionFieldsProps) {
    const {section, currentSBO, sboActions} = props;

    if (section && section.fields) {
        return section.fields.map((fieldId, index) => {
            if (currentSBO?.fieldsMap[fieldId]) {
                const field: TField = currentSBO?.fieldsMap[fieldId];

                return <SBOEditorField
                    key={fieldId}
                    field={field}
                    index={index}
                    sectionId={section.id}
                    sboActions={sboActions}
                    tableFields={currentSBO.tableFields}
                />;
            }

            return null;
        }).filter(Boolean);
    }

    return [];
}
