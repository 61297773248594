import _compact from 'lodash/compact';

import AskForMenu from '@/asks/AskForMenu';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {openInGoogleMaps} from '@/helpers/Map.fn';

import {getGpsMenuOptions} from './Gps.getMenuOptions';

const GpsCellView = ({lat, lng}) => {
    const textToCopy = _compact([lat, lng]).join(', ');

    const onCopy = () => CopyToClipboard(textToCopy);
    const onView = () => openInGoogleMaps(lat, lng);

    console.log('....Gps.Cell.View.tsx -> Line 15', {lat, lng});

    return <div className="flex flex-col items-center gap-1"
        onContextMenu={(event) => {
            event.stopPropagation();
            event.preventDefault();
            AskForMenu(event, getGpsMenuOptions(onCopy, onView));
        }}
    >
        <div>
            <span className="max-w-2 truncate">{lat}</span>, {' '}
            <span className="max-w-2 truncate">{lng}</span>
        </div>
    </div>;
};

export default GpsCellView;
