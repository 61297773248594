import {ContentDivider, Icon, Label} from '@aktek/f4kit';
import {XSBO} from '@aktek/types';
import {faChevronDown, faChevronUp, faGripDotsVertical, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {ReactNode, useState} from 'react';

import AskForMenu from '@/asks/AskForMenu';
import F4Textbox from '@/components/F4Elements/F4TextBox';

import GetSectionActionsContextMenuEntries from '../helpers/GetSectionActionsContextMenuEntries.fn';
import GetSectionContextMenuEntries from '../helpers/GetSectionContextMenuEntries.fn';
import {TSBOActions, TSection} from '../types/SBOEditor.t';

type TSBOEditorSectionProps ={
    children?: ReactNode
    section: TSection
    index: number;
    sboActions: TSBOActions;
    currentSBO: XSBO;
}

export default function SBOEditorSection(props:TSBOEditorSectionProps) {
    const {section, sboActions, index, children, currentSBO} = props;

    const [isSectionExpanded, setIsSectionExpanded] = useState(true);

    return (
        // TODO: here we can add listener to '/' and use K Bar react to show a modal in the middle of the screen, autofocussed  with many other shortcuts
        <div onKeyDown={()=> { }}>
            {/* ********** Section ********** */}
            <div className="flex items-center -ml-3 group/section" onContextMenu={(event)=>{
                AskForMenu(event, GetSectionContextMenuEntries(currentSBO, section, sboActions));
            }}>
                <Icon
                    icon={faGripDotsVertical}
                    color="neutral-600"
                    className="invisible group-hover/section:!visible w-4 h-4 cursor-pointer"
                    onClick={(event)=>{
                        AskForMenu(event, GetSectionContextMenuEntries(currentSBO, section, sboActions));
                    }}
                />
                <ContentDivider
                    backgroundColor="neutral-50"
                    className="my-2"
                    middleChild={
                        <F4Textbox isGhost required
                            inputClassName="text-center !p-0 !w-42"
                            placeholder={`Section ${index + 1}`}
                            name={`sectionsMap.${section.id}.name`}
                            className="text-neutral-600 rounded-none  placeholder-neutral-300"
                            wrapper={{className: 'text-center'}}/>}
                    leftChild={
                        <div className="flex items-center bg-neutral-50 rounded-lg absolute left-4 hover:bg-neutral-100 p-3 -mt-5 !cursor-pointer select-none"
                            onClick={()=>setIsSectionExpanded(!isSectionExpanded)}>
                            <Icon
                                color="neutral-600"
                                className=" font-semibold"
                                icon={isSectionExpanded ? faChevronUp : faChevronDown}
                            />
                        </div>}
                />
            </div>

            <div className="flex flex-col gap-2 bg-neutral-50 rounded-lg -ml-3.5">

                {/* ********** Fields ********** */}
                {isSectionExpanded && children}

                {/* ********** Add Section or Field ********** */}
                {isSectionExpanded
                && <div className="flex items-center gap-2 mt-1 ml-4"
                    onClick={(event)=> AskForMenu(event, GetSectionActionsContextMenuEntries(section, sboActions) )}>
                    <Icon icon={faPlus} color="neutral-600" className="w-3 h-3 cursor-pointer"/>
                    <Label text="Click to insert block" color="neutral-400" className="cursor-pointer"/>
                </div>}
            </div>
        </div>
    );
}
