import {Cell, Row} from '@tanstack/react-table';

import MediaImageCellView from '@/components/Fields/MediaImage/MediaImage.Cell.View';
import {getMediaFilesFromMediaValue} from '@/helpers/File';

export const CellMediaGeneric = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = cell.getValue();
            const files = getMediaFilesFromMediaValue(value);

            return (
                <div className="w-64">
                    <MediaImageCellView files={files} />
                </div>
            );
        },

    };
};
