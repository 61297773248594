import {Button, CountDown, Icon, Label, TextBox} from '@aktek/f4kit';
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useRef, useState} from 'react';

import {strings} from '@/localization/i18n';

import useHandle2FA from '../hooks/useHandle2FA';
import {TTwoFactorState} from '../types/twoFactorState.t';

enum ViewModes { login, twoFactorAuth}

type TTwoFactorAuthProps = {
    twoFactorAuthState: TTwoFactorState;
    on2FASuccess?: ()=> void;
    setLoginView?:React.Dispatch<React.SetStateAction<ViewModes>>
}

export default function TwoFactorAuth({twoFactorAuthState, on2FASuccess, setLoginView} : TTwoFactorAuthProps) {
    const handle2FAHook = useHandle2FA(twoFactorAuthState, on2FASuccess);
    const [code, setCode] = useState(new Array(6).fill(''));
    const inputRefs = useRef([]);

    useEffect(() => {
        if (handle2FAHook.flag == 3) {
            setLoginView(ViewModes.login);
        }
    }, [handle2FAHook.flag]);

    useEffect(() => {
        const isCodeComplete = code.every((digit) => digit !== '');

        if (!isCodeComplete) {
            handle2FAHook.setVerificationCode(null);

            return;
        }

        handle2FAHook.setVerificationCode(code.join(''));
    }, [code]);

    const handleInputChange = (value, index) => {
        if (value.length > 1) {
            if (index < 5) {
                inputRefs.current[index + 1].focus();
            }

            return;
        }

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (!isNaN(value) && value.length > 0) {
            focusNextInput(index);
        } else if (value === '') {
            focusPreviousInput(index);
        }
    };

    const focusNextInput = (index) => {
        if (index < 5) {
            inputRefs.current[index + 1].focus();
            inputRefs.current[index + 1].select();
        }
    };

    const focusPreviousInput = (index) => {
        if (index > 0) {
            inputRefs.current[index - 1].focus();
            inputRefs.current[index - 1].select();
        }
    };

    const handleOnKeyDown = (event)=>{
        if (code[code.length - 1] != '') {
            handle2FAHook.handle2FAKeyDown(event);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text');
        const digits = pasteData.split('').filter((char) => !isNaN(char)).slice(0, 6);

        if (digits.length == 0) return;

        const newCode = [...code];
        digits.forEach((digit, i) => {
            newCode[i] = digit;
            inputRefs.current[i].value = digit;
        });
        setCode(newCode);

        const nextIndex = Math.min(digits.length, 5);

        inputRefs.current[nextIndex].focus();
    };

    const numberOfFailedAttempts = 3 - handle2FAHook.flag;

    return (
        <>
            <div className="flex flex-col mb-8">
                <Label
                    text={strings('check_your_email')}
                    size="4xl"
                    fontWeight="semibold"
                    color="neutral-900"
                    className="!mb-4"/>
                <div>

                    <Label
                        text={strings('check_your_email_for_the_verification_code')}
                        size="md" color="neutral-600" />
                    <Label
                        text={handle2FAHook.email + '.'}
                        size="md" fontWeight="medium" color="neutral-600" />
                </div>

            </div>

            <div className="flex flex-col gap-6">

                <div className="flex flex-col items-start justify-center gap-1">
                    <div className="flex space-x-2.5">
                        {code.map((digit, index) => {
                            return (
                                <TextBox
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    key={index}
                                    size="xl"
                                    type="text"
                                    autoComplete="off"
                                    variant={handle2FAHook.flag > 0 ? 'error' : 'neutral'}
                                    autoFocus={index == 0}
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    onKeyDown={handleOnKeyDown}
                                    inputClassName="!text-3xl !text-center !w-14 !h-14"
                                    maxLength={1}
                                    onPaste={handlePaste}
                                />
                            );
                        })}
                    </div>
                    <div className="flex-col items-start flex mb-6">

                        {handle2FAHook.flag > 0
                         && <Label
                             text={strings('Invalid_verification_code_n_attempts_left',
                                 numberOfFailedAttempts,
                                 numberOfFailedAttempts > 1 ? strings('attempts') : strings('attempt')) }
                             size="sm" color="error-600" />}
                    </div>
                </div>
                <Button
                    label={strings('login')}
                    className="w-full !h-11 !py-2.5"
                    variant="primary"
                    isLoading={handle2FAHook.loading}
                    onClick={handle2FAHook.loginWithTwoFA}
                    isDisabled={!handle2FAHook.verificationCode}/>

                <div>

                    <Label text={strings('enter_the_code_before_the_expiry_of_this_timer')}
                        size="sm" color="neutral-600" className="pr-1" />
                    <CountDown durationInSeconds={300} size="sm"
                        onTimeEnd={() => setLoginView(ViewModes.login)}
                    />
                </div>

                <div
                    className="flex flex-row gap-2 items-center justify-start cursor-pointer"
                    onClick={() => setLoginView(ViewModes.login)}>
                    <Icon icon={faArrowLeft} color="neutral-600" size="sm" />
                    <Label text={strings('back_to_login')} size="sm"
                        color="neutral-600" fontWeight="medium" className="cursor-pointer" />
                </div>
            </div>

        </>
    );
}

