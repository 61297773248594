
import {ToggleSwitch} from '@aktek/f4kit';

const BooleanCellEdit = ({value, onChange}) => {
    console.log('....Boolean.Cell.Edit.tsx -> Line 5', {value});

    return <ToggleSwitch
        value={value}
        onChange={(value) => {
            console.log('....Boolean.Cell.Edit.tsx -> Line 10', {value});
            onChange(value);
        }}
    />;
};

export default BooleanCellEdit;
