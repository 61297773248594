
import {Button, Modal, TModalController, TSize, useModalRef} from '@aktek/f4kit';
import cn from 'classnames';
import {forwardRef, Ref, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import AskForConfirmation from '@/asks/AskForConfirmation';
import {strings} from '@/localization/i18n';

import F4CreatableForm from '../F4CreatableForm/F4CreatableForm';
import useF4CreatableFormRef from '../F4CreatableForm/hooks/useF4CreatableFormRef';
import {TF4CreatableModalProps} from './types/F4CreatableModalProps.t';

function F4CreatableModal<DataType extends object = object>(props: TF4CreatableModalProps<DataType>, ref) {
    const {
        buttons,
        children,
        childrenClassName,
        className,
        closeOnSave = true,
        createSuccessMessage = strings('success'),
        defaultStep,
        editSuccessMessage = strings('success'),
        errorMessage = strings('failed'),
        extra,
        formKey,
        getFunction,
        isDisabled = false,
        modalProps = {},
        onClose,
        onDataLoad,
        onPreGetItem,
        onPreSave,
        onSave,
        onSetValue,
        sideBarExtraElement,
        sideBarExtraElementPosition,
        viewState = {mode: 'create', actionTitle: 'Create'},
        steps,
        storeFunction,
        title,
        f4FormProps,
    } = props;

    const {mode, _id, actionTitle} = viewState;

    const creatableFormRef = useF4CreatableFormRef();

    const modalRef = useModalRef(ref);
    const isEditedRef = useRef<boolean>(false);

    const [currentStep, setCurrentStep] = useState(0);
    const [modalSize, setModalSize] = useState<TSize>('md');
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        if (window.innerWidth < 1024) setModalSize('xl');
        if (window.innerWidth >= 1024) setModalSize('md');
    }, [window.innerWidth]);

    const askForCloseConfirmation = useCallback(async () => { // G: i think this can be outside
        return AskForConfirmation(strings('discard_confirmation'), strings('discard_confirmation_desc'),
            {
                confirmButtonProps: {variant: 'error', label: strings('discard')},
                cancelButtonProps: {label: strings('stay_here')},
                hasXIcon: true,
            },
        );
    }, []);

    const modalTitle = useMemo(() => {
        let prefix = strings('edit');

        if (actionTitle) prefix = actionTitle;
        if (mode === 'create') prefix = strings('create');
        if (mode === 'view') prefix = strings('view');

        return `${prefix} ${title}`;
    }, [mode, actionTitle]);

    const visibleSteps = steps?.filter((step) => step.isVisible !== false);

    const isLastStep = !steps || steps?.length == 0 || currentStep == visibleSteps.length - 1;

    const nextButtonText = useMemo(() => {
        if (mode == 'edit') {
            return strings('save');
        }

        if (mode == 'create') {
            if (isLastStep) return strings('create');

            return strings('next');
        }
    }, [steps, currentStep, mode]);

    const previousButtonText = useMemo(() => {
        if (mode === 'view') return strings('close');

        if (currentStep === 0 || !steps || mode === 'edit') return strings('cancel');

        return strings('back');
    }, [mode, currentStep, steps]);

    const handlePreviousButtonClick = async () => {
        if (currentStep <= 0 || mode == 'view' || mode == 'edit') {
            modalRef.current.close();

            return;
        }

        setCurrentStep(currentStep - 1);
        creatableFormRef.current.goToPreviousStep();
    };

    const handleNextButtonClick = () => {
        if (mode == 'edit' || isLastStep) {
            creatableFormRef.current.saveForm();
        } else {
            creatableFormRef.current?.goToNextStep();
        }
    };

    return (
        <Modal
            id={_id}
            ref={modalRef}
            headerClassName={cn('border-b border-b-neutral-100 pr-2 pl-6 pb-1 pt-2  text-xl', modalProps.headerClassName)}
            canCloseModalFn={async () => {
                if (isEditedRef.current && !isDisabled && mode != 'view') {
                    return askForCloseConfirmation();
                }

                return true;
            }}
            size={modalSize}
            className={cn('overflow-hidden !rounded-3xl h-full ', className)}
            title={modalTitle}
            isControlled
            onClose={onClose}
            {...modalProps}
        >
            <F4CreatableForm<DataType>
                ref={creatableFormRef}
                _id={_id}
                // isLoading={isLoading}
                onEditStatusChange={(isChanged) => isEditedRef.current = isChanged}
                onLoadingChange={setIsLoading}
                className="h-full overflow-auto p-1 w-full"
                childrenClassName={childrenClassName}
                onPreGetItem={onPreGetItem}
                onPreSave={onPreSave}
                onSetValue={onSetValue}
                extra={extra}
                defaultStep={defaultStep}
                onSave={() => {
                    creatableFormRef.current.setIsChanged(false);

                    if (closeOnSave) {
                        modalRef.current.close();
                    }

                    onSave?.();
                }}
                onStepChange={setCurrentStep}
                mode={mode}
                sideBarExtraElement={sideBarExtraElement}
                sideBarExtraElementPosition={sideBarExtraElementPosition}
                createSuccessMessage={createSuccessMessage}
                editSuccessMessage={editSuccessMessage}
                errorMessage={errorMessage}
                formKey={formKey}
                getFunction={getFunction}
                storeFunction={storeFunction}
                onDataLoad={onDataLoad}
                steps={visibleSteps}
                f4FormProps={f4FormProps}
            >
                {({isEdited}) => {
                    return <div className="flex flex-col grow-7 h-full w-full">
                        <div className="flex-1 overflow-y-auto p-5 max-h-[calc(100%-4.5rem)]">
                            {children}
                        </div>

                        <div className="border-t border-t-neutral-200 p-3 flex justify-end gap-3">
                            {buttons ? buttons
                                : <>
                                    <Button
                                        size="sm"
                                        isGhost={mode != 'view'}
                                        label={previousButtonText}
                                        onClick={handlePreviousButtonClick}
                                        isDisabled={isLoading} />

                                    {mode != 'view' && <Button
                                        onClick={handleNextButtonClick}
                                        size="sm"
                                        variant="primary"
                                        label={nextButtonText}
                                        isDisabled={!isEdited || isLoading}
                                    />}
                                </>}
                        </div>
                    </div>;
                }}
            </F4CreatableForm>
        </Modal>
    );
}

export default forwardRef(F4CreatableModal) as <DataType extends object = object>(
    props: TF4CreatableModalProps<DataType> & { ref?: Ref<TModalController> }
) => React.ReactElement;
