
import {BaseTable, LoadingWrapper, Pagination} from '@aktek/f4kit';
import React from 'react';

import AskForModal from '@/asks/AskForModal';

import ColumnVisibilityModal from './ColumnVisibilityModal';
import EmptyTableState from './Components/EmptyTableState';
import TableRibbon from './Components/Ribbon';
import {getRowContextMenu} from './Helpers/GetRowContextMenu';
import {getUpdateColumns} from './Helpers/GetUpdatedColumns';
import {GetClasses} from './Styles/GetClasses';
import {TStatelessTableProps} from './Types/Table.t';

function StatelessTable <RowType>({
    state,
    onRibbonAction,
    columns = [],
    rows = [],
    searchState,
    isRowsLoading = false,
    isColumnsLoading = false,
    pagination,
    events,
    isLoading,
    ribbonActions,
    sorting,
    onRowAction,
    emptyProps,
    name,
    baseTableRef,
    modalColumnsVisibilityRef,
    className,
    baseTableClasses,
    onEmptyStateClick,
    rowActions,
    ribbonProps,
    children,
}: TStatelessTableProps<RowType>) {
    modalColumnsVisibilityRef.open = () => AskForModal(ColumnVisibilityModal, {baseTableRef});
    const hasActions = !!ribbonActions;
    const updatedColumns = React.useMemo<TStatelessTableProps['columns']>(() =>
        getUpdateColumns({state, columns, sorting, onRowAction, rowActions})
    , [columns, sorting?.sortingState, onRowAction, rowActions]);

    const classes = GetClasses(className);

    const finalEvents = {
        onRowContextMenu: getRowContextMenu({rowActions, state, onRowAction}),
        ...events,
    };

    return <div className={classes.container} >
        {ribbonActions && <div className={classes.actionsContainer}>
            <TableRibbon onActionClick={onRibbonAction} ribbonProps={ribbonProps} actions={ribbonActions} searchState={searchState} state={state} />
        </div>}

        {!children && <BaseTable
            classes={baseTableClasses}
            ref={baseTableRef}
            events={finalEvents}
            isRoundedTop={!hasActions}
            isBordered={false}
            columns={updatedColumns}
            rows={rows}
            views={{
            }}
        />}
        {children}

        {isLoading && <div className={classes.loadingIndicator}>
            <LoadingWrapper size={'xl'} isLoading borderRadius={'xl'} className="w-full h-full backdrop-blur-sm" />
        </div>}

        {!isLoading && Array.isArray(rows) && !rows.length && <div className={`text-center text-neutral-900 text-lg w-full h-full font-semibold py-7 ${emptyProps?.className}`}>
            <EmptyTableState
                name={name}
                message={emptyProps?.message}
                onClick={onEmptyStateClick}
                search={searchState.query}
                addLabel={emptyProps?.addLabel}
                {...emptyProps}/>
        </div> }

        {!isLoading && !Array.isArray(rows) && <div>There was an error, please try again</div>}

        {pagination && !(Array.isArray(rows) && !rows.length) && <div className="py-2 px-6 border-t border-neutral-200">
            <Pagination paginationState={pagination}/>
        </div>}
    </div>;
}

;

export default StatelessTable;
