
import {ToggleSwitch} from '@aktek/f4kit';

const BooleanCellView = ({value}) => {
    return <ToggleSwitch
        value={value}
    />;
};

export default BooleanCellView;
