import React, {forwardRef, useImperativeHandle, useState} from 'react';

type TInjectedComponentController = {
    addInjectedComponent: (key: string, component: React.ReactNode) => void;
    removeInjectedComponent: (key: string) => void;
}

export class DOMInjectionManager {

    static ref: TInjectedComponentController;

}

const InjectionTargetComponent = (props: { children?: React.ReactNode }, ref) => {
    const [injectedComponent, setInjectedComponent] = useState<Record<string, React.ReactNode>>({});

    const addInjectedComponent = (key: string, component: React.ReactNode) => {
        setInjectedComponent((prev) => ({...prev, [key]: component}));
    };

    const removeInjectedComponent = (key: string) => {
        setInjectedComponent((prev) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {[key]: oldComponent, ...rest} = prev;

            return rest;
        });
    };

    useImperativeHandle(ref, () => ({
        addInjectedComponent,
        removeInjectedComponent,
    }));

    return Object.entries(injectedComponent).map(([key, component]) => {
        return <React.Fragment key={key}>
            {component}
        </React.Fragment>;
    });
};

export const InjectionTarget = forwardRef(InjectionTargetComponent);
