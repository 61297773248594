import {F4Form, useF4FormRef} from '@aktek/f4form';
import {Button, Drawer, FitHeight, toast} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {TTags, XSBO} from '@aktek/types';
import {faDatabase} from '@fortawesome/pro-regular-svg-icons';
import React, {useEffect, useState} from 'react';

import useF4CurrentTab from '@/app/Home/hooks/useF4CurrentTab';
import AskForFolder from '@/asks/AskForFolder';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';

import {defaultSBO} from '../constants/SBO.DefaultSBO.c';
import GetNewFieldSelectedAndFocused from '../helpers/GetNewFieldSelectedAndFocused';
import {AddToRecentlyUsedSBOs, GetRecentlyUsedSBOs, STORAGE_KEY} from '../helpers/RecentlyUsedSBOs.fn.';
import GetAddFieldUpdateObject from '../helpers/UpdateObjects/GetAddFieldUpdateObject.fn';
import GetAddSectionUpdateObject from '../helpers/UpdateObjects/GetAddSectionUpdateObject.fn';
import GetDeleteFieldUpdateObject from '../helpers/UpdateObjects/GetDeleteFieldUpdateObject.fn';
import GetDeleteSectionUpdateObject from '../helpers/UpdateObjects/GetDeleteSectionUpdateObject.fn';
import GetToggleFieldTableVisibilityUpdateObject from '../helpers/UpdateObjects/GetToggleFieldTableVisibilityUpdateObject.fn';
import GetToggleTagFieldTableVisibilityUpdateObject from '../helpers/UpdateObjects/GetToggleTagFieldTableVisibilityUpdateObject.fn';
import {TField, TSBOActions, TSection} from '../types/SBOEditor.t';
import SBOEditorBody from './SBO.EditorBody';
import SBOEditorMain from './SBO.EditorMain';
import SBOEditorSidebar from './SBO.EditorSidebar';

export type TSBOEditorTabState = {
    sboId: string;
    onSave?: () => void
}

export default function SBOEditor() {
    const currentTab = useF4CurrentTab<TSBOEditorTabState, { isDrawerOpen: boolean, tmpData?: XSBO }>();
    const {sboId, onSave} = currentTab.getTabState();

    const sboFormRef = useF4FormRef<XSBO>();

    const {refresh: refreshFolders} = useGlobalFilesAndFolders();
    // const [isLoading, setIsLoading] = useState(false);
    const recentUsedSBOs = GetRecentlyUsedSBOs();

    const [currentSBO, setCurrentSBO] = useState<XSBO>(/* currentTab.getConfig().tmpData ||*/ defaultSBO);

    const mode = sboId ? 'edit' : 'create';

    useEffect(() => {
        sboId && fetchSBOById();
    }, [sboId]);

    const fetchSBOById = async () => {
        sboFormRef.current?.showLoader();
        const res = await SBOService.getCompleteSBOById(sboId);
        // for f4form to not return error that tags is null

        if (res.isSuccessful()) {
            const sbo = res.getData() as XSBO;
            sbo.tags ??= {tags: [], joined: ''};

            sboFormRef.current.reset(sbo);
            setCurrentSBO(sbo);
        }

        sboFormRef.current?.hideLoader();
    };

    const addSection = (section: TSection = {id: '', fields: []}) => {
        const data = sboFormRef.current.getData();
        const updateObject = GetAddSectionUpdateObject(data, section);

        sboFormRef.current.updateData(updateObject);
    };

    const deleteSection = (sectionId: string) => {
        const data = sboFormRef.current.getData();
        const updateObject = GetDeleteSectionUpdateObject(data, sectionId);
        sboFormRef.current.updateData(updateObject);
    };

    const addField = (field: Partial<TField> = {}, sectionId: string) => {
        const {updateObject, fieldId} = GetAddFieldUpdateObject(field, sectionId);

        sboFormRef.current.updateData(updateObject);
        // to be reviewed by G
        GetNewFieldSelectedAndFocused(fieldId);
    };

    const deleteField = (sectionId: string, fieldId: string) => {
        const data = sboFormRef.current.getData();

        const updateObject = GetDeleteFieldUpdateObject(data, sectionId, fieldId);

        sboFormRef.current.updateData(updateObject);
    };

    const toggleFieldTableVisibility = (fieldId: string) => {
        const data = sboFormRef.current.getData();
        const updateObject = GetToggleFieldTableVisibilityUpdateObject(data, fieldId);

        sboFormRef.current.updateData(updateObject);
    };

    const toggleTagFieldTableVisibility = () => {
        const data = sboFormRef.current.getData();
        const updateObject = GetToggleTagFieldTableVisibilityUpdateObject(data);

        sboFormRef.current.updateData(updateObject);
    };

    const handleSave = async () => {
        let validRequest = true;
        const validation = sboFormRef.current.validate();
        const data = sboFormRef.current.getData() as XSBO;

        if (!data.name || Object.keys(data.fieldsMap).length === 0) {
            validRequest = false;
            toast.error(strings('mandatory_fields_are_missing_or_some_configurations_are_incorrect'));
        }

        if (validRequest === true && validation.isFormValid) {
            const tags = {
                tags: (data.tags as TTags)?.tags,
                joined: (data.tags as TTags)?.tags?.join(','),
            };
            data.tags = tags;
            const assets = {
                customIcon: data.assets?.type == 'image' ? data.assets.value : '',
                customColor: data.assets?.type == 'color' ? data.assets.value : '',
            };

            // creating a new data structure
            let folderResults;

            if (mode == 'create') {
                folderResults = await AskForFolder({folder: null});

                setCurrentSBO({...currentSBO, folder: folderResults?.parentId});
            }

            sboFormRef.current?.showLoader();

            const finalResults = {
                ...data,
                folder: folderResults?.parentId || data?.folder || null,
                customIcon: assets.customIcon,
                customColor: assets.customColor,
            };

            const createSBO = await SBOService.storeSBO(finalResults);
            const SBO = createSBO.getData() as XSBO;

            if (createSBO.isSuccessful()) {
                recentUsedSBOs.map((item, index) => {
                    if (item.key === sboId) {
                        const tab = {
                            key: SBO._id,
                            label: typeof SBO.name !== 'string' ? SBO.name.def : SBO.name,
                            icon: faDatabase,
                        };

                        return recentUsedSBOs[index] = tab;
                    }
                });
                localStorage.setItem(STORAGE_KEY, JSON.stringify(recentUsedSBOs));

                setCurrentSBO(data);
                toast.success(strings('data_structure_is_saved_successfully'));

                const myTab = {
                    key: SBO._id,
                    label: typeof SBO.name !== 'string' ? SBO.name.def : SBO.name,
                    icon: faDatabase,
                };
                AddToRecentlyUsedSBOs(myTab);
                sboFormRef.current?.hideLoader();

                onSave?.();
                currentTab.updateConfig({hasUnsavedChanges: false, title: data.name as string}, false);
                currentTab.setTabState({sboId: SBO._id, onSave});

                if (mode == 'create') refreshFolders?.();
            }
        } else {
            toast.error(strings('mandatory_fields_are_missing_or_some_configurations_are_incorrect'));
        }

        sboFormRef.current?.hideLoader();
    };

    const handleSidebarChange = React.useCallback(() => {
        if (currentTab.getConfig().hasUnsavedChanges) {
            currentTab.updateConfig({hasUnsavedChanges: false}, false);
        }
    }, []);

    const sboActions: TSBOActions = {
        addField,
        addSection,
        deleteField,
        deleteSection,
        toggleFieldTableVisibility,
        toggleTagFieldTableVisibility,
    };

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                tooltipDelay={1000}
                defaultOpen={currentTab.getConfig().isDrawerOpen ?? true}
                onOpenChange={(e) => currentTab.updateConfig({isDrawerOpen: !!e}, false)}
                buttonSize="sm"
            >

                <SBOEditorSidebar
                    onSave={onSave}
                    selectedSboId={sboId}
                    recentSBO={recentUsedSBOs}
                    onChange={handleSidebarChange}
                />
                <>
                    <FitHeight className="h-[94%] w-full overflow-x-auto bg-neutral-50 p-3 pt-0 border-l border-neutral-200">
                        <F4Form
                            ref={sboFormRef}
                            revalidateFieldOnChange="all"
                            className="h-full px-16 py-2 pt-0"
                            formKey="sboEditor"
                            initialValue={currentSBO || {}}
                            onSetValue={(key, value) => {
                                if (key == 'name') {
                                    if (!currentTab.getTabState().sboId) {
                                        currentTab.updateConfig({title: `[New] ${value as string}`}, false);
                                    }
                                }
                            }}
                            onEditStatusChange={(status) => {
                                if (currentTab.getConfig().hasUnsavedChanges != status) {
                                    currentTab.updateConfig({hasUnsavedChanges: status}, false);
                                }
                            }}>
                            <SBOEditorMain sboId={currentSBO._id} />
                            <SBOEditorBody sboActions={sboActions}/>
                        </F4Form>
                    </FitHeight>
                    <div className="w-full bg-neutral-50">
                        <div className="absolute z-0 bottom-0 py-4 pr-4 right-0 flex gap-2 bg-neutral-50 w-full justify-end">
                            <Button isGhost label={strings('cancel')} textColor="neutral-600" onClick={() => currentTab.close()} />
                            <Button label={strings('save')} onClick={handleSave} />
                        </div>

                    </div>
                </>
            </Drawer>
        </div>
    );
}
