import {
    faBuilding,
    faGears,
    faPlus,
    faVials,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faBarChart,
    faDashboard,
    faDatabase,
    faDiagramProject,
    faDisplayChartUp,
    faGear,
    faHome,
    faLock,
    faNetworkWired,
    faSatellite,
    faTable,
    faUser,
} from '@fortawesome/pro-regular-svg-icons';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import aktekIQ from '@/app//iQ/iQ';
import UsersView from '@/app/Admin/AccessControl/Users/Users.View';
import admin from '@/app/Admin/Admin';
import agency from '@/app/Admin/Agency/Agency';
import playgroundIOSelect from '@/app/Admin/Playground/Playground.ioselect';
import usageMetrics from '@/app/Admin/UsageMetrics/UsageMetrics';
import analysis from '@/app/Analysis/Analysis';
import dashboards from '@/app/Analysis/pages/Dashboards';
import networks from '@/app/Analysis/pages/Networks';
import dataTable from '@/app/DataTable/DataTable.View';
import home from '@/app/Explorer/Explorer';
import navigationPagesViewer from '@/components/Navigation/Navigation.PagesViewer';
import navigationViewer from '@/components/Navigation/Navigation.Viewer';
import {strings} from '@/localization/i18n';

import groups from '../Admin/AccessControl/Groups/Groups.View';
import rolesAndPermissions from '../Admin/AccessControl/RolesAndPermissions/RolesAndPermissions.View';
import tac from '../Admin/AccessControl/TAC/TAC';
import deleteAllData from '../Admin/Advanced/pages/DeleteAllData/DeleteAllData';
import restoreFields from '../Admin/Advanced/pages/RestoreFields/RestoreFields';
import appearance from '../Admin/CompanySettings/pages/Appearance/Appearance';
import settings from '../Admin/CompanySettings/pages/Settings/Settings';
import tags from '../Admin/CompanySettings/pages/Tags/Tags.View';
import SBOEditor from '../Admin/Data/SBO/components/SBO.Editor';
import dataStructure from '../Admin/Data/SBO/SBO.View';
import geoRepository from '../Admin/GeoRepository/GeoRepository';
import iOJobs from '../Admin/iOJobs/iOJobs';
import licensing from '../Admin/Licensing/Licensing';
import recordEditor from '../RecordEditor/Record.Editor';

// Define the type for a tab
export type TTabDefinition = {
    component: React.FC,
    /**
     * This is a fallback title, if the title is not found, it will use the title from the tab key
     */
    title: string;
    /**
     * This is a fallback icon, if the icon is not found, it will use the icon from the tab key
     */
    icon: string | IconDefinition;
    isLazyLoaded?: boolean;
};

// Create the tabs object with lazy-loaded components defined inline
export const Tabs: Record<string, TTabDefinition> = {
    playgroundIOSelect: {

        component: playgroundIOSelect,
        title: 'Playground - IOSelect',
        icon: faVials,
    },
    home: {

        component: home,
        title: strings('home'),
        icon: faHome,
        isLazyLoaded: true,
    },
    admin: {

        component: admin,
        title: strings('admin'),
        icon: faUser,
        isLazyLoaded: true,
    },
    deleteAllData: {

        component: deleteAllData,
        title: 'Nav',
        icon: faUser,
    },
    restoreFields: {

        component: restoreFields,
        title: 'Nav',
        icon: faUser,
    },
    navigationPagesViewer: {

        component: navigationPagesViewer,
        title: 'Nav',
        icon: faUser,
        isLazyLoaded: true,
    },
    navigationViewer: {

        component: navigationViewer,
        title: 'Nav',
        icon: faUser,
    },
    rolesAndPermissions: {

        component: rolesAndPermissions,
        title: strings('access_control'),
        icon: faLock,
    },
    users: {
        component: UsersView,

        // component: component,
        title: strings('users'),
        icon: faUser,
    },
    groups: {

        component: groups,
        title: strings('groups'),
        icon: faLock,
    },
    tac: {

        component: tac,
        title: strings('TAC'),
        icon: faLock,
    },
    dataStructure: {

        component: dataStructure,
        title: strings('data_structures'),
        icon: faDatabase,
    },
    analysis: {

        component: analysis,
        title: strings('analysis'),
        icon: faDisplayChartUp,
    },
    aktekIQ: {

        component: aktekIQ,
        title: strings('aktek_iq'),
        icon: faSatellite,
    },
    settings: {

        component: settings,
        title: strings('settings'),
        icon: faGear,
    },
    appearance: {

        component: appearance,
        title: strings('appearance'),
        icon: faGear,
    },
    tags: {

        component: tags,
        title: strings('tags'),
        icon: faGear,
    },
    usageMetrics: {

        component: usageMetrics,
        title: strings('usage_metric'),
        icon: faBarChart,
    },
    agency: {

        component: agency,
        title: strings('agency'),
        icon: faBuilding,
    },
    licensing: {

        component: licensing,
        title: strings('licensing'),
        icon: faGears,
    },
    dashboards: {

        component: dashboards,
        title: strings('dashboards'),
        icon: faDashboard,
    },
    networks: {

        component: networks,
        title: strings('networks'),
        icon: faNetworkWired,
    },
    iOJobs: {

        component: iOJobs,
        title: strings('io_jobs'),
        icon: faNetworkWired,
    },
    geoRepository: {

        component: geoRepository,
        title: strings('geo_repository'),
        icon: faDiagramProject,
    },
    sboEditor: {

        component: SBOEditor,
        title: strings('data_structure_editor'),
        icon: faDatabase,
    },
    dataTable: {

        component: dataTable,
        title: strings('data_table'),
        icon: faTable,
    },
    recordEditor: {
        component: recordEditor,
        title: 'single record',
        icon: faPlus,
    },
} as const;

export type TIOTab = keyof typeof Tabs;

