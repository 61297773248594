import {SBOService} from '@aktek/morph-frontend-sdk';
import {XFieldId, XSBO, XSDKResponsePromise} from '@aktek/types';

type ViewingResponse = {
    editableFieldsIDs: XFieldId[];
    sbo: XSBO
}
type FetchSBOResponse = {
    editableFieldsIDs: XFieldId[];
    fieldsMap: XSBO['fieldsMap'];
    sections: XSBO['sections'];
    sectionsMap: XSBO['sectionsMap'];
    viewingHasRecordTags: XSBO['hasRecordTags'];
    creationHasRecordTags: XSBO['hasRecordTags'];
}

export const FetchSBOData = async (sboId: string): Promise<FetchSBOResponse | null> => {
    const [viewingResponse, creationResponse] = await Promise.all([
        SBOService.getViewingSBOById(sboId) as XSDKResponsePromise<ViewingResponse>,
        SBOService.getCreationSBOById(sboId) as XSDKResponsePromise<XSBO>,
    ]);

    if (!viewingResponse.isSuccessful() || !creationResponse.isSuccessful()) {
        return null;
    }

    const viewingData = viewingResponse.getData();
    const creationData = creationResponse.getData();

    const viewingSBO = viewingData.sbo;
    const creationSBO = creationData;

    return {
        editableFieldsIDs: viewingData.editableFieldsIDs,
        fieldsMap: viewingSBO.fieldsMap,
        sections: viewingSBO.sections,
        sectionsMap: viewingSBO.sectionsMap,
        viewingHasRecordTags: viewingSBO.hasRecordTags,
        creationHasRecordTags: creationSBO.hasRecordTags,
    };
};
