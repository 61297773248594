import {TContextMenuEntry} from '@aktek/f4kit';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {faLink, faPen, faTurnRight} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

export const GetFolderContextMenu = ({
    copyLinkToFolder,
    openEditModal,
    openDeleteModal,
    foldersPermissions,
}) =>[
    {
        label: strings('copy_link_to_folder'),
        icon: faLink,
        iconProps: {color: 'neutral-500'},
        onClick: copyLinkToFolder,
    },
    {
        label: strings('edit_folder'),
        icon: faPen,
        iconProps: {color: 'neutral-500'},
        onClick: openEditModal,
        isVisible: foldersPermissions?.can_edit,
    },
    {
        label: strings('delete_folder'),
        icon: faTrash,
        iconProps: {color: 'error-600'},
        labelProps: {color: 'error-600', className: 'pl-0.2'},
        onClick: openDeleteModal,
        isVisible: foldersPermissions?.can_delete,
    },
] as TContextMenuEntry[];

export const GetReportsContextMenu = ({openReport, moveDataTable, editDataStructure, folders, reportStructure}) => [
    {
        label: strings('open'),
        onClick: openReport,
    },
    {
        label: strings('move_to'),
        onClick: moveDataTable,
        icon: faTurnRight,
        iconProps: {color: 'neutral-500'},
        isVisible: reportStructure?.can_edit,
    },
    {
        label: strings('edit_data_structure'),
        onClick: editDataStructure,
        icon: faPen,
        iconProps: {color: 'neutral-500'},
        isVisible: reportStructure?.can_edit,
    },
];

export const GetRecentlyUsedContextMenu = (openRecentlyUsed) => [
    {
        label: strings('open'),
        onClick: openRecentlyUsed,
    },
    // {
    //     label: strings('remove'),
    //     icon: faPen,
    //     iconProps: {color: 'neutral-500'},
    // },
];

export const GetShortcutContextMenu = ({openDeleteModal, openShortcut, folders}) => [
    {
        label: strings('open'),
        onClick: openShortcut,
    },
    {
        label: strings('delete_shortcut'),
        icon: faTrash,
        iconProps: {color: 'error-600'},
        labelProps: {color: 'error-600'},
        onClick: openDeleteModal,
        isVisible: folders?.can_delete,
    },
];

