import {FilesSwiper} from '@aktek/f4kit';

import {getResourceFileFromMediaFile} from '@/helpers/File';
import {TMediaFile} from '@/types/Media';

type MediaAudioCellViewProps = {
    files: TMediaFile[];
}

const MediaAudioCellView = ({files}: MediaAudioCellViewProps) => {
    return <div className="w-64 h-16">
        <FilesSwiper
            defaultFileCategory="audio"
            itemSideLength={50}
            files={files?.map(getResourceFileFromMediaFile)}
        />
    </div>;
};

export default MediaAudioCellView;
