import i18next from 'i18next';

import enFallback from './locales/en/fallback.json';
import enSpreadsheet from './locales/en/spreadsheet.json';

export const defaultNS = 'spreadsheet';
export const fallbackNS = 'fallback';

i18next.init({
    debug: false,
    fallbackLng: 'en',
    defaultNS,
    fallbackNS,
    interpolation: {
        // This enables the $1, $2, etc format
        prefix: '\\$',
        suffix: '',
        skipOnVariables: false,
    },
    resources: {
        en: {
            fallback: enFallback,
            spreadsheet: enSpreadsheet,
        },
    },
});

export type TStringKey = keyof typeof enFallback; // Keep this existing type

export const strings = (key: TStringKey, ...args: string[]) => {
    let translation = i18next.t(key);

    args.forEach((arg, index) => {
        const placeholder = `$${index + 1}`;
        translation = translation.replace(placeholder, String(arg));
    });

    return translation;
};

export type TStringValue = typeof enFallback[TStringKey];

export default i18next;
