import {TUpdateObject, UpdateActions} from '@aktek/f4form';
import {XSBO} from '@aktek/types';

export default function GetToggleTagFieldTableVisibilityUpdateObject(data: XSBO) {
    const updateObject : TUpdateObject<XSBO> = {};
    const fieldIndex = data.tableFields.indexOf('tags');

    if (fieldIndex !== -1) {
        updateObject[`tableFields.${fieldIndex}`] = UpdateActions.DELETE;
    } else {
        updateObject[`tableFields`] = {[UpdateActions.APPEND]: 'tags'};
    }

    return updateObject;
}

