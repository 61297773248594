import {FileSelectInput, FilesSwiper, TFile, TFileResource, toast, useFileSelectRef} from '@aktek/f4kit';
import React, {useCallback, useState} from 'react';

import {getMediaFileFromResourceFile, getResourceFileFromMediaFile} from '@/helpers/File';
import {uploadMultipleFilesToNewURLs} from '@/helpers/FileUpload';
import {strings} from '@/localization/i18n';

import {HandleFileContextMenu, HandleSwiperContextMenus} from './Helpers/HandleContextMenu';
import {HandleFileDoubleClick} from './Helpers/HandleFileDoubleClick';
import {FilesSwiperUploadProps} from './Types/FilesSwiperUploadProps.t';

const FilesSwiperUpload: React.FC<FilesSwiperUploadProps> = ({
    onNewFiles,
    onChange,
    extensions,
    mediaFiles,
    minimim,
    maximum,
    fileCategory,
    isEditMode,
}) => {
    const [loading, setLoading] = useState(false);
    const fileSelectRef = useFileSelectRef();

    const uploadSelectedFiles = useCallback(async (selectedFiles: TFile[]) => {
        if (loading || !Array.isArray(selectedFiles)) return;

        setLoading(true);

        const filesUploadResponse = await uploadMultipleFilesToNewURLs(selectedFiles);

        const newResourceFiles: TFileResource[] = filesUploadResponse
            .filter((fileResponse) => !fileResponse.error)
            .map((fileResponse) => fileResponse.resourceFile);

        const failedFilesCount = filesUploadResponse?.length - newResourceFiles?.length;

        if (failedFilesCount) {
            toast.error({
                subject: strings('files_upload_failed')?.replace?.('##count', failedFilesCount),
            });
        }

        if (newResourceFiles.length) {
            const newMediaFiles = newResourceFiles.map(getMediaFileFromResourceFile);
            onNewFiles(newMediaFiles);
        }

        setLoading(false);
    }, [loading, onNewFiles]);

    const removeFile = useCallback<(index: number) => void>((index) => {
        const filteredMediaFiles = mediaFiles.filter((_, _index) => _index !== index);
        onChange(filteredMediaFiles);
    }, [mediaFiles]);

    return (
        <div
            className="h-full w-64 min-h-full min-w-full"
            onContextMenu={(event) => {
                HandleSwiperContextMenus(event, fileSelectRef.current?.clickToSelectFiles);
            }}>
            <FileSelectInput
                isMulti={true}
                ref={fileSelectRef}
                extensions={extensions}
                onSelectFiles={async (files) => {
                    uploadSelectedFiles(files);
                }}
            />

            <FilesSwiper
                defaultFileCategory={fileCategory}
                itemSideLength={50}
                onFileClick={(file) => console.log('File clicked:', file)}
                onFileDoubleClick={HandleFileDoubleClick}
                onFileContextMenu={(event, file) => {
                    HandleFileContextMenu(event, () => removeFile(file));
                }}
                onFileRemove={async (event, file, index) => {
                    removeFile(index);

                    return true;
                }}
                files={mediaFiles.map(getResourceFileFromMediaFile)}
                onFileAdd={isEditMode && (() => fileSelectRef.current?.clickToSelectFiles())}
            />
        </div>
    );
};

export default FilesSwiperUpload;
